/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useContext, useState } from "react";
import AppContext from "../../../context/store";

import styled from "styled-components";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import { Alert } from "@material-ui/lab";
import Button from "../../../theme/Button";
import Axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FeedbackIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [staffId, setStaffId] = useState(context.state.user.id);
  const [message, setMessage] = useState("");

  const insertFeedback = () => {
    `${message.replace(/\s/g, "")}`.length >= 2
      ? Axios.post(`${context.api_endpoint}/company/feedback/insert`, {
          staffId,
          message,
        })
          .then((response) => {
            if (response.status === 201) {
              toast.success(
                context.t(`feedback.insertFeedbackSuccessToast`)
              );
              history.push("/dashboard");
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.warning(e.response.data.message);
            } else {
              toast.error(e);
            }
          })
      : toast.warning(context.t(`feedback.insertFeedbackWarnToast`));
  };

  return (
    <AuthContainer>
      <Grid>
        <Column className="xs-12 sm-12">
          <SectionTitle className="mb-1">{context.t(`feedback.feedbackTitle`)}</SectionTitle>
          <Alert severity="info" className="mb-3">
            <b>{context.t(`feedback.feedbackLabel1`)}</b>
            <br />
            <b>{context.t(`feedback.feedbackLabel2`)}</b> {context.t(`feedback.feedbackLabel3`)}{" "}
            <b>{context.t(`feedback.feedbackLabel4`)}</b> {context.t(`feedback.feedbackLabel5`)}{" "}
            <b>{context.t(`feedback.feedbackLabel6`)}</b>
            <br />
            <br />
            <b>
            {context.t(`feedback.feedbackLabel7`)}
            </b>
          </Alert>

          <Input
            label={context.t(`feedback.feedbackInputLabel`)}
            multiline={true}
            rows={8}
            onChange={(e) => setMessage(e.target.value)}
          />

          <Button
            icon="send"
            title={context.t(`component.sms_review.sendButtonTitle`)}
            textColor="white"
            backgroundColor="primary"
            fullWidth
            onClick={() => insertFeedback()}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default FeedbackIndex;

const SectionTitle = styled.h3`
  font-size: 19px;
  padding: 0;
  margin: 0;
  color: var(--dark-primary);
`;
