import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../context/store";
import styled from "styled-components";

import { Column, Grid } from "../../../theme/Grid";
import AuthContainer from "../../../components/AuthContainer";

import {
  getCities,
  getDistrictsByCityID,
} from "../../../functions/CityDistrict";
import Button from "../../../theme/Button";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import moment from "moment";
import TimeSelect from "../../../theme/TimeSelect";
import QRCode from "react-qr-code";
import { Alert } from "@material-ui/lab";
import currencyList from "../../../currencyList.json";
import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import BranchInPanelAdd from "../../../views/Authenticated/Branches/InPanelAdd";
import PlatformSettings from "../../../views/Authenticated/Settings/PlatformSettings";
import EmployeesIndex from "../../../views/Authenticated/Employees/Index";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getCountryCode } from "../../../functions/CountryCode";
function TabPanel(props) {
  const { children, value, index } = props;
  return value === index ? <Box p={3}>{children}</Box> : null;
}

const SettingsIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(0);
  const [business, setBusiness] = useState({
    id: null,
    name: null,
    mail: null,
    phone: null,
    dialCode: "+90",
    province: null,
    district: null,
    address: null,
    website: null,
    map: null,
    instagram: null,
    facebook: null,
    active_sms_package: null,
    password: null,
    gallery: null,
    staffs: null,
    customers: null,
    services: null,
    receipts: null,
    message_settings: null,
    company_settings: null,
    hours: null,
    currency: null,
    currencyCode: null,
    about: null,
    shortly: null,
  });

  const [hours, setHours] = useState({
    start_hour: null,
    end_hour: null,
    slice: null,
  });
  const [hoursForSend, setHoursForSend] = useState({
    start_hour: null,
    end_hour: null,
    slice: null,
  });

  const [hoursChangeApprovalModal, setHoursChangeApprovalModal] =
    useState(false);
  const [isChange, setIsChange] = useState(false);

  const [companyCategories, setCompanyCategories] = useState([]);
  const [selectedCompanyCategory, setSelectedCompanyCategory] = useState(null);

  const [activeSteps, setActiveSteps] = useState(0);
  const [showHowToDialog, setShowHowToDialog] = useState(false);

  const [logoSrc, setLogoSrc] = useState(null);

  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  const [shortlyQrCodeDialog, setShortlyQrCodeDialog] = useState(false);

  const [companySettings, setCompanySettings] = useState({
    company_id: null,
  });
  const [dialCode, setDialCode] = useState(context?.state?.dialCode);
  const [countryCode, setCountryCode] = useState(context?.state?.countryCode);
  console.log(getCountryCode());
  useEffect(() => {
    getCompanyDetails();
    getCompanyCategories();
    getLogoSrc();
    setSelectedTab(location.data ? location.data : 0);
  }, []);

  const getCompanyDetails = () => {
    Axios.get(`${context.api_endpoint}/company/itself`)
      .then((response) => {
        const {
          data: { data },
        } = response;
        setBusiness({
          ...business,
          ...data,
          company_settings: {
            ...companySettings,
            category_id: data.category_id,
          },
        });
        setHours({ ...data.hours });
        setHoursForSend({ ...data.hours });
      })
      .catch((e) => {
        if (e.response.data.code === 401) {
          history.push("/wallet");
        }
      });
  };

  const updateCompanyDetails = () => {
    if (!validateHours()) {
      return toast.warning(context.t(`['settings/hours'].compareErrorToast`));
    }
    validateCompany() &&
      Axios.put(`${context.api_endpoint}/company/update`, {
        ...business,
        dialCode: dialCode,
        province: parseInt(business.province),
        category_id: parseInt(
          selectedCompanyCategory
            ? selectedCompanyCategory
            : business.company_settings.category_id
        ),
        hour: hoursForSend,
        any_hour_changed: isChange,
        currency: business.currency,
        currencyCode: business.currencyCode,
      })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data, response.data.data.currency);
            toast.success(context.t(`settings.updateToast`));
            context.dispatch({
              type: "SET_COMPANY_SHIFT",
              payload: { ...hours },
            });
            context.dispatch({
              type: "CURRENCY",
              payload: { currency: response.data.data?.currency },
            });
            context.dispatch({
              type: "CURRENCY_CODE",
              payload: { currencyCode: response.data.data.currencyCode },
            });
            setHours({ ...hoursForSend });
            setIsChange(false);
          }
        })
        .catch((e) => {
          if (e.response.status === 401 || e.response.status === 400) {
            toast.error(e.response.data.message);
          }
        });
  };

  const checkForHours = () => {
    setHoursChangeApprovalModal(true);
  };

  const getCompanyCategories = () => {
    Axios.get(`${context.api_endpoint}/company/category/all`).then(
      ({ data }) => {
        setCompanyCategories([...data.data]);
      }
    );
  };

  const updateWorkHours = () => {
    const { start_hour, end_hour } = hours;

    if (validateHours())
      if (
        moment(`1970-01-01 ${end_hour}:00`).isAfter(
          moment(`1970-01-01 ${start_hour}:00`)
        )
      )
        Axios.put(`${context.api_endpoint}/company/update/hours`, {
          ...hours,
        })
          .then((response) => {
            if (response.status === 201) {
              context.dispatch({
                type: "SET_COMPANY_SHIFT",
                payload: { ...hours },
              });
              toast.success(context.t(`['settings/hours'].updateToast`));
              getCompanyDetails();
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.error(e.response.data.message);
            }
          });
      else toast.warning(context.t(`['settings/hours'].compareErrorToast`));
  };

  const uploadPhoto = async (file) => {
    const Form = new FormData();
    toast.warning(context.t(`settings.uploadPhotoWarnToast`));
    await Form.append("files", file.target.files[0]);
    Axios.post(
      `${context.api_endpoint}/company/service/gallery/logo/insert`,
      Form,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
      .then((data) => {
        if (data.status === 201) {
          toast.success(context.t(`settings.uploadPhotoSuccessToast`));
          setLogoSrc(data.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getLogoSrc = () => {
    Axios.get(`${context.api_endpoint}/company/message/credit`)
      .then(({ data }) => {
        setLogoSrc(data.data.settings.logo_src);
      })
      .catch((e) => console.log(e));
  };

  const validateHours = () => {
    return Validate([
      {
        value: hours.start_hour,
        condition: /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
        field: context.t(`['settings/hours'].startInputLabel`),
      },
      {
        value: hours.end_hour,
        condition: /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
        field: context.t(`['settings/hours'].endInputLabel`),
      },
      {
        value: hours.slice,
        condition: /^.+$/,
        field: context.t(`['settings/hours'].periodBetweenInputLabel`),
      },
    ]);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const HowToDialog = () => {
    const steps = [context.t(`settings.steps[0]`), context.t(`settings.steps[1]`), context.t(`settings.steps[2]`), context.t(`settings.steps[3]`)];
    return (
      <AlertDialog
        title={""}
        open={showHowToDialog}
        fullWidth
        textType
        disableBackdropClick={false}
        closeHandler={() => setShowHowToDialog(false)}
        buttons={[
          {
            //icon: "okay",
            title: context.t(`appointments.detail.closeButtonTitle`),
            textColor: "white",
            backgroundColor: "primary",
          },
        ]}
      >
        <Grid>
          <Grid>
            <Stepper
              activeStep={activeSteps}
              alternativeLabel
              style={{ width: "100%" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <div style={{ width: "100%", height: "400px" }}>
            <img
              src={require(`../../../assets/images/step_${
                activeSteps + 1
              }.png`)}
              alt=""
              style={{ width: "100%", height: "400px", objectFit: "fill" }}
            />
          </div>
          <Grid>
            <Button
              disabled={activeSteps === 0}
              title={context.t(`settings.stepBack`)}
              backgroundColor="blue"
              textColor="white"
              onClick={() => {
                setActiveSteps(activeSteps - 1);
              }}
            />
            <Column className="xs-auto sm-auto md-auto lg-auto xl-auto"></Column>
            <Button
              disabled={activeSteps === steps.length - 1}
              title={context.t(`settings.stepNext`)}
              backgroundColor="blue"
              textColor="white"
              onClick={() => {
                setActiveSteps(activeSteps + 1);
              }}
            />
          </Grid>
        </Grid>
      </AlertDialog>
    );
  };

  const QRCodeDialog = () => {
    return (
      <AlertDialog
        open={qrCodeDialog}
        disableBackdropClick={false}
        closeHandler={() => setQrCodeDialog(false)}
        buttons={[
          {
            icon: "close",
            title: context.t(`appointments.detail.closeButtonTitle`),
            textColor: "black",
            outlined: true,
          },
        ]}
      >
        <div
          style={{
            height: "auto",
            margin: "50px 40px",
            maxWidth: 256,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${context.app.WEB}/isletme/${business.id}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </AlertDialog>
    );
  };
  const ShortlyQRCodeDialog = () => {
    return (
      <AlertDialog
        open={shortlyQrCodeDialog}
        disableBackdropClick={false}
        closeHandler={() => setShortlyQrCodeDialog(false)}
        buttons={[
          {
            icon: "close",
            title: context.t(`appointments.detail.closeButtonTitle`),
            textColor: "black",
            outlined: true,
          },
        ]}
      >
        <div
          style={{
            height: "auto",
            margin: "50px 40px",
            maxWidth: 256,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`https://randevu.tc/${business.shortly}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </AlertDialog>
    );
  };

  const validateCompany = () => {
    return Validate([
      {
        field: "İşletme Adı",
        value: business.name,
        condition: /[a-zA-Z0-9]+/,
      },
      {
        field: "E-Posta Adresi",
        value: business.mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
      {
        field: "Telefon Numarası",
        value: business.phone,
        condition: /[0-9]{10}/,
      },
      {
        field: "Şehir",
        value: business.phone,
        condition: /\d*/,
      },
      {
        field: "İlçe",
        value: business.district,
        condition: /\w*/,
      },
      {
        field: "İşletme Adresi",
        value: business.address,
        condition: /[a-zA-Z0-9]+/,
      },
    ]);
  };

  return (
    <AuthContainer authorities={[1]} limited_permission="system">
      <AppBar
        style={{ marginBottom: "30px" }}
        position="sticky"
        color="default"
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab key={0} label={context.t(`menuItems.settings.children.index`)} />
          <Tab
            key={1}
            label={context.t(`menuItems.platform.children.settings`)}
          />
          <Tab
            key={2}
            label={context.t(`menuItems.employees.children.index`)}
          />
          <Tab
            key={3}
            label={context.t(`menuItems.settings.children.branchAdd`)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
            <Input
              label={context.t(`settings.urlInputLabel`)}
              readOnly={true}
              disabled={true}
              value={
                business.id !== null
                  ? `${context.app.WEB}/isletme/${business.id}`
                  : ""
              }
              helperText={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    icon="public"
                    title={context.t(`settings.openOnBrowserButtonTitle`)}
                    onClick={() =>
                      window.open(
                        `${context.app.WEB}/isletme/${business.id}`,
                        "_blank"
                      )
                    }
                  />
                  <CopyToClipboard
                    text={`${context.app.WEB}/isletme/${business.id}`}
                    onCopy={(copiedValue) => {
                      toast.success(context.t(`settings.copyLinkToast`));
                    }}
                  >
                    <Button
                      icon="file_copy"
                      title={context.t(`settings.copyLinkButtonTitle`)}
                      textColor="grey"
                    />
                  </CopyToClipboard>
                  <div style={{ marginLeft: "auto" }}>
                    <Button
                      icon="crop_free"
                      title={context.t(`dashboard.PlatformLink.Qr`)}
                      onClick={() => setQrCodeDialog(true)}
                    />
                  </div>
                </div>
              }
            />
            {process.env.APP_NAME === "management" ? null : (
              <Input
                label={context.t(`settings.shortURL`)}
                readOnly={true}
                disabled={true}
                value={
                  business.id !== null
                    ? `https://randevu.tc/${business.shortly}`
                    : ""
                }
                helperText={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      icon="public"
                      title={context.t(`settings.openOnBrowserButtonTitle`)}
                      onClick={() =>
                        business.shortly && business.shortly !== ""
                          ? window.open(
                              `${context.app.WEB}/isletme/${business.id}`,
                              "_blank"
                            )
                          : toast.warn(
                              context.t(`settings.shortURLWarnToast`)
                            )
                      }
                    />
                    <CopyToClipboard
                      text={`https://randevu.tc/${business.shortly}`}
                      onCopy={(copiedValue) => {
                        business.shortly && business.shortly !== ""
                          ? toast.success(context.t(`settings.copyLinkToast`))
                          : toast.warn(
                              context.t(`settings.shortURLWarnToast`)
                            );
                      }}
                    >
                      <Button
                        icon="file_copy"
                        title={context.t(`settings.copyLinkButtonTitle`)}
                        textColor="grey"
                      />
                    </CopyToClipboard>
                    <div style={{ marginLeft: "auto" }}>
                      <Button
                        icon="crop_free"
                        title={context.t(`dashboard.PlatformLink.Qr`)}
                        onClick={() =>
                          business.shortly && business.shortly !== ""
                            ? setShortlyQrCodeDialog(true)
                            : toast.warn(
                                context.t(`settings.shortURLWarnToast`)
                              )
                        }
                      />
                    </div>
                  </div>
                }
              />
            )}
            <Input
              label={context.t(`settings.nameInputLabel`)}
              value={business.name !== null ? business.name : ""}
              onChange={(e) => {
                setBusiness({ ...business, name: e.target.value });
              }}
            />
            <MaskedInput
              required
              mask="999 999 9999"
              maskChar=""
              label={context.t(`settings.phoneInputLabel`)}
              startAdornment="+90"
              value={business.phone !== null ? business.phone : ""}
              placeholder="500 000 0000"
              onChange={(e) => {
                setBusiness({
                  ...business,
                  phone: e.target.value.replace(/\s/g, ""),
                });
              }}
            />
            <Input
              label={context.t(`settings.mailInputLabel`)}
              value={business.mail !== null ? business.mail : ""}
              onChange={(e) => {
                setBusiness({
                  ...business,
                  mail: e.target.value,
                });
              }}
            />
            <Grid className="no-gutters-all">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabel`)}
                  type="all"
                  value={
                    hoursForSend.start_hour !== null
                      ? hoursForSend.start_hour
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  minuteStep={5}
                  handler={(time) => {
                    setHoursForSend({
                      ...hoursForSend,
                      start_hour: time,
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
                <TimeSelect
                  label={context.t(`['settings/hours'].endInputLabel`)}
                  type="all"
                  value={
                    hoursForSend.end_hour !== null ? hoursForSend.end_hour : ""
                  }
                  fullWidth={true}
                  size="medium"
                  minuteStep={5}
                  handler={(time) => {
                    setHoursForSend({
                      ...hoursForSend,
                      end_hour: time,
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
                <Select
                  label={context.t(
                    `['settings/hours'].periodBetweenInputLabel`
                  )}
                  items={[...new Array(47).keys()].map((i) => ({
                    value: (i + 2) * 5,
                  }))}
                  selected={
                    hoursForSend.slice !== null ? hoursForSend.slice : 10
                  }
                  labelKey="value"
                  valueKey="value"
                  handler={(e) => {
                    setHoursForSend({
                      ...hoursForSend,
                      slice: e.substring(0, 1) !== "0" ? e : null,
                    });
                  }}
                />
              </Column>
            </Grid>

            <Select
              label={context.t(`[settings/hours].businessCategory`)}
              items={companyCategories}
              selected={
                selectedCompanyCategory
                  ? selectedCompanyCategory
                  : business.company_settings
                  ? business.company_settings.category_id
                  : ""
              }
              labelKey="name"
              valueKey="id"
              handler={(category) => {
                setSelectedCompanyCategory(category);
              }}
            />
            <Input
              label={context.t(`settings.aboutCompanyLabel`)}
              value={business.about !== null ? business.about : ""}
              rows={6}
              multiline={true}
              onChange={(e) => {
                setBusiness({
                  ...business,
                  about: e.target.value ?? null,
                });
              }}
            />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
            {process.env.APP_NAME === "management" ? (
              <>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  <Select
                    disabled
                    label={context.t(`settings.countryCode`)}
                    items={getCountryCode()}
                    selected={
                      getCountryCode().filter(
                        (curr) => curr?.code === countryCode
                      )[0]?.code
                    }
                    labelKey="name"
                    valueKey="code"
                    handler={(item) => {}}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  <Select
                    dialCode={true}
                    label={context.t(`settings.dialCode`)}
                    items={getCountryCode()}
                    selected={
                      getCountryCode().filter(
                        (curr) => curr?.dial_code === dialCode
                      )[0]?.dial_code
                    }
                    labelKey="dial_code"
                    valueKey="dial_code"
                    handler={(item) => {
                      setDialCode(
                        getCountryCode().filter(
                          (curr) => curr.dial_code === item
                        )[0]?.dial_code
                      );
                      context.dispatch({
                        type: "SET_DIAL_CODE",
                        payload: {
                          dialCode: getCountryCode().filter(
                            (curr) => curr.dial_code === item
                          )[0]?.dial_code,
                        },
                      });
                    }}
                  />
                </Column>
              </>
            ) : null}
            <Select
              label={context.t(`settings.currencyType`)}
              items={currencyList}
              selected={
                business.currencyCode !== null ? business.currencyCode : ""
              }
              labelKey="code"
              valueKey="code"
              handler={(e) => {
                setBusiness({
                  ...business,
                  currencyCode: e,
                  currency: currencyList.filter((m) => m.code === e)[0].symbol,
                });
              }}
            />
            <Select
              label={context.t(`settings.cityInputLabel`)}
              items={getCities()}
              selected={business.province !== null ? business.province : ""}
              labelKey="name"
              valueKey="id"
              handler={(province) => {
                setBusiness({
                  ...business,
                  province: province,
                  district: null,
                });
              }}
            />

            <Select
              label={context.t(`settings.districtInputLabel`)}
              items={getDistrictsByCityID(business.province)}
              selected={business.district !== null ? business.district : ""}
              labelKey="name"
              valueKey="name"
              handler={(district) => {
                setBusiness({
                  ...business,
                  district: district,
                });
              }}
            />

            <Input
              required
              label={context.t(`settings.addressInputLabel`)}
              value={business.address !== null ? business.address : ""}
              rows={6}
              multiline={true}
              onChange={(e) => {
                setBusiness({
                  ...business,
                  address: e.target.value ?? null,
                });
              }}
            />

            <Input
              label={context.t(`settings.mapsInputLabel`)}
              placeholder={context.t(`settings.mapsInputHint`)}
              value={business.map !== null ? business.map : ""}
              helperText={
                <div>
                  <Button
                    icon="my_location"
                    title={context.t(`settings.currentLocationButtonTitle`)}
                    textColor="blue"
                    onClick={async () => {
                      if (!navigator.geolocation) {
                        toast.warning(
                          context.t(`settings.geolocationErrorToast`)
                        );
                      } else {
                        navigator.geolocation.getCurrentPosition(
                          (position) => {
                            const lat = position.coords.latitude;
                            const lng = position.coords.longitude;
                            setBusiness({
                              ...business,
                              map: `${lat}, ${lng}`,
                            });
                          },
                          () =>
                            toast.warning(
                              context.t(`settings.locationErrorToast`)
                            ),
                          { enableHighAccuracy: true, maximumAge: 10000 }
                        );
                      }
                    }}
                  />
                  <Button
                    icon="help"
                    title={context.t(`[platform/categories].howto.title`)}
                    textColor="blue"
                    onClick={() => {
                      setShowHowToDialog(true);
                    }}
                  />
                </div>
              }
              onChange={(e) => {
                setBusiness({
                  ...business,
                  map: e.target.value,
                });
              }}
            />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 xl-4">
            <Input
              label={context.t(`settings.instagramInputLabel`)}
              helperText={context.t(`settings.withoutAtHint`)}
              value={business.instagram !== null ? business.instagram : ""}
              onChange={(e) => {
                setBusiness({
                  ...business,
                  instagram: e.target.value,
                });
              }}
            />
            <Input
              label={context.t(`settings.facebookInputLabel`)}
              helperText={context.t(`settings.withoutAtHint`)}
              value={business.facebook !== null ? business.facebook : ""}
              onChange={(e) => {
                setBusiness({
                  ...business,
                  facebook: e.target.value,
                });
              }}
            />
            <Input
              label={context.t(`settings.websiteInputLabel`)}
              value={business.website !== null ? business.website : ""}
              onChange={(e) => {
                setBusiness({
                  ...business,
                  website: e.target.value,
                });
              }}
            />

            <Column className="sx-12 sm-12 md-12 lg-12 mb-5">
              {logoSrc ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80%",
                      height: "15vh",
                      overflow: "hidden",
                      marginBottom: "20px",
                    }}
                  >
                    <img
                      style={{ width: "70%", objectFit: "cover" }}
                      src={`${context.api_endpoint}/${context.state.company_id}/${logoSrc}`}
                    />
                  </div>
                </div>
              ) : (
                <p style={{ textAlign: "center" }} className="mb-2">
                  {context.t(`settings.noLogoTitle`)}
                </p>
              )}

              <input
                id="LogoUploadInput"
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                onChange={(e) => {
                  uploadPhoto(e);
                }}
              />
              <ImageUploadBox htmlFor="LogoUploadInput">
                <i className="material-icons">cloud_upload</i>{" "}
                {`${
                  logoSrc
                    ? context.t(`settings.updateLogo`)
                    : context.t(`settings.setLogo`)
                }`}
              </ImageUploadBox>
            </Column>
          </Column>
        </Grid>
        <Grid>
          <Column className="xs-12 sm-12">
            <Button
              icon="update"
              title={context.t(`settings.updateButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              fullWidth={true}
              onClick={() => {
                if (
                  !(
                    hoursForSend.end_hour !== hours.end_hour ||
                    hoursForSend.start_hour !== hours.start_hour ||
                    hoursForSend.slice !== hours.slice
                  )
                ) {
                  updateCompanyDetails();
                } else {
                  checkForHours();
                }
              }}
            />
          </Column>
          <HowToDialog />
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <PlatformSettings />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <EmployeesIndex />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <BranchInPanelAdd />
      </TabPanel>
      <AlertDialog
        title="Çalışan Saat Değişikliği"
        open={hoursChangeApprovalModal}
        disableBackdropClick={false}
        closeHandler={() => {
          setHoursChangeApprovalModal(false);
        }}
        buttons={[
          {
            icon: "check",
            title: context.t(`dashboard.onlineAppointments.approveButtonTitle`),
            textColor: "white",
            backgroundColor: "primary",
            timer: 5,
            onClick: async () => {
              setIsChange(true);
              await updateCompanyDetails();
            },
          },
          {
            icon: "clear",
            title: context.t(`branches.discardEditingButtonTitle`),
            textColor: "black",
            outlined: true,
          },
        ]}
      >
        <Alert severity="warning" className="mb-3">
          {context.t(`settings.settingsAlertText`)}
        </Alert>
      </AlertDialog>
      <QRCodeDialog />
      <ShortlyQRCodeDialog />
    </AuthContainer>
  );
};

const ImageUploadBox = styled.label`
        display: flex;
        width: -webkit-fill-available;
        padding: 32px;
        text-align: center;
        align-items: center;
        justify-content: center;
        background: #fefefe;
        border: 2px dotted #eaeaea;
        border-radius: 5px;
        cursor: pointer;
        color: #808080;
        font-size: 15px;
        font-weight: 500;
        transition: all ease 0.35s;

        &:hover {
          border: 2px dotted #dadada;
        color: #000;
  }

        i.material-icons {
          font - size: 21px;
        margin-right: 8px;
  }
        `;

export default SettingsIndex;
