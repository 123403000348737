import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import moment from "moment";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import FCLabel from "../../../theme/FCLabel";
import { FormControlLabel, Switch } from "@material-ui/core";
import RoundedRow from "../../../components/Table/RoundedRow";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import Select from "../../../theme/CustomMUI/Select";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ReceiptTypes } from "./store/types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DateRange } from "@material-ui/icons";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";

const ReceiptIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const receipts = useSelector((state) => state.receiptSlice);
  const receiptsAll = useSelector((state) => state.receiptSlice.receiptsAll);
  const records = useSelector((state) => state.receiptSlice.receipts_records);
  const dispatch = useDispatch();

  const [filter_expanded, setFilterExpanded] = useState(false);
  const [deleteDialogModal, setDeleteDialogModal] = useState(false);
  const [selectedIdForDelete, setSelectedIdForDelete] = useState(null);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [filterModal, setFilterModal] = useState(false);

  const [modalEnd, setModalEnd] = useState();
  const [modalStart, setModalStart] = useState();

  const [readyFilter, setReadyFilter] = useState();

  const [filter_picker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);
  const [selected_filter_picker, setSelectedFilterPicker] = useState(
    filter_picker[0]
  );

  const [sales_types] = useState([
    {
      id: 1,
      name: `${context.t(`receipts.replacements.allSales`)}`,
      is_sales: null,
    },
    {
      id: 2,
      name: `${context.t(`receipts.replacements.packageSale`)}`,
      is_sales: 1,
    },
    {
      id: 3,
      name: `${context.t(`receipts.replacements.standartSale`)}`,
      is_sales: 2,
    },
  ]);
  const [selected_sales, setSelectedSales] = useState(null);

  const [sales_open_close] = useState([
    {
      id: 1,
      name: `${context.t(`receipts.filter.openTitle`)}`,
      is_open: 2,
    },
    {
      id: 2,
      name: `${context.t(`receipts.filter.open`)}`,
      is_open: 0,
    },
    {
      id: 3,
      name: `${context.t(`receipts.filter.close`)}`,
      is_open: 1,
    },
  ]);
  const [selected_open_close, setSelectedOpenClose] = useState(null);

  const [customers, setCustomers] = useState([]);
  const [selected_customer, setSelectedCustomer] = useState(null);

  const [loaded, setLoaded] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    total_page: null,
    onChange: (page) => setPage(page),
  });
  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);

  //TODO Opeened Receipts
  const getOpenedReceipts = async () => {
    const urlPage = parseInt(new URLSearchParams(location.search).get("page"));
    dispatch({
      type: ReceiptTypes.SHOW_RECEIPT,
      payload:
        filter_expanded === true
          ? selected_filter_picker === null
            ? {
                params: {
                  page: page ? page : 1,
                  ...{
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                    isbetween: true,
                    customerid: selected_customer ? selected_customer.id : null,
                    ispaid: selected_open_close
                      ? selected_open_close.is_open
                      : 2,
                    isPackage: selected_sales ? selected_sales.is_sales : null,
                  },
                },
              }
            : {
                params: {
                  page: page ? page : 1,
                  ...{
                    offset: selected_filter_picker.offset,
                    isbetween: false,
                    customerid: selected_customer ? selected_customer.id : null,
                    ispaid: selected_open_close
                      ? selected_open_close.is_open
                      : 2,
                    isPackage: selected_sales ? selected_sales.is_sales : null,
                  },
                },
              }
          : {
              params: {
                page: page ? page : urlPage ? urlPage : 1,
                ispaid: 2,
              },
            },
    });
  };

  //TODO Filter Customer
  const getCustomers = (key) => {
    Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(async ({ data }) => {
      setCustomers([...data.data.records]);
    });

    return customers;
  };
  console.log("end " + context.api_endpoint);
  const deleteSingleReceipt = (id) => {
    Axios.delete(`${context.api_endpoint}/company/receipt/${id}/delete`)
      .then((response) => {
        response.status === 200 &&
          toast.success(context.t(`receipts.deleteReceiptSuccessToast`));
        history.push("/receipts");
        setSelectedIdForDelete(null);
        getOpenedReceipts();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        }
      });
  };

  // delete receipt dialog
  const DeleteDialog = () => {
    return (
      <AlertDialog
        open={deleteDialogModal}
        closeHandler={() => setDeleteDialogModal(false)}
        title={context.t(`['receipt/detail/id'].delete.title`)}
        buttons={[
          {
            title: context.t(`['receipt/detail/id'].delete.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => deleteSingleReceipt(selectedIdForDelete),
          },
          {
            title: context.t(`['receipt/detail/id'].delete.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          {context.t(`['receipt/detail/id'].delete.alertText`)}
        </Alert>
      </AlertDialog>
    );
  };

  useEffect(() => {
    setStartTime(moment(receiptsAll.start_time).format("DD/MM/YYYY"));
    setEndTime(moment(receiptsAll.end_time).format("DD/MM/YYYY"));
  }, [receiptsAll]);

  useEffect(() => {
    getOpenedReceipts();
  }, [
    dispatch,
    filter_expanded,
    selected_filter_picker,
    start,
    end,
    selected_customer,
    selected_open_close,
    selected_sales,
    page,
    startTime,
    endTime,
  ]);

  useEffect(() => {
    setLoaded(true);
    setData([
      ...records?.map((record) => {
        return {
          ...record,
          all_amount: (
            <RoundedRow color="orange">
              {record.all_amount
                ? `${parseFloat(record.all_amount.toFixed(0))}`
                : "0.00"}
              {context.state.currency ? context.state.currency : "₺"}
            </RoundedRow>
          ),
          paid: (
            <RoundedRow color="green">
              {record.paid > 0
                ? `${parseFloat(record.paid.toFixed(0))}`
                : "0.00"}
              {context.state.currency ? context.state.currency : "₺"}
            </RoundedRow>
          ),
          debt_total: (
            <RoundedRow color="red">
              {(record.all_amount ?? 0) - (record.paid ?? 0) < 1 &&
              (record.all_amount ?? 0) - (record.paid ?? 0) > 0
                ? 0
                : (record.all_amount ?? 0) - (record.paid ?? 0) > 0
                ? `${parseFloat(
                    ((record.all_amount ?? 0) - (record.paid ?? 0)).toFixed(0)
                  )}`
                : "0.00"}
              {context.state.currency ? context.state.currency : "₺"}
            </RoundedRow>
          ),
          created_at: moment(record.created_at)
            .local()
            .format("DD/MM/YYYY HH:mm"),
        };
      }),
    ]);

    setPagination({
      ...pagination,
      page: receipts?.receipts?.page,
      total_page: records?.length,
    });
  }, [receipts.receipts]);
  useEffect(() => {
    setPage(1);
  }, [selected_filter_picker, readyFilter]);
  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="sales"
    >
      <Grid className="mb-2">
        <Column className={`xs-12 sm-12`}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={filter_expanded}
                onChange={async (e) => {
                  setLoaded(false);
                  setFilterExpanded(e.target.checked);
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{context.t(`receipts.filter.title`)}</span>
              </FCLabel>
            }
          />
        </Column>
        {filter_expanded && (
          <>
            <Column
              className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
              style={{ borderBottom: "1px solid #b2b2b2" }}
            >
              {" "}
              <Column
                className="xs-12 sm-12 md-12 lg-6"
                style={{
                  height: "63px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ShortButtonContainer>
                  <ShortButton
                    style={{
                      backgroundColor: `${
                        selected_filter_picker?.id === 1
                          ? "rgb(49, 169, 243, 0.14)"
                          : ""
                      }`,
                      border: "1px solid rgb(91, 187, 245, 0.41)",
                      color: "rgb(49, 169, 243)",
                    }}
                    value={filter_picker}
                    onClick={() => {
                      setSelectedFilterPicker(filter_picker[0]);
                    }}
                  >
                    Bugün
                  </ShortButton>
                  <ShortButton
                    style={{
                      backgroundColor: `${
                        selected_filter_picker?.id === 2
                          ? "rgb(29, 139, 62, 0.21)"
                          : ""
                      }`,
                      border: "1px solid rgb(91, 187, 245, 0.41)",
                      color: "rgb(29, 139, 62)",
                    }}
                    value={filter_picker[1]}
                    onClick={() => {
                      setSelectedFilterPicker(filter_picker[1]);
                    }}
                  >
                    Bu Hafta
                  </ShortButton>
                  <ShortButton
                    style={{
                      backgroundColor: `${
                        selected_filter_picker?.id === 3
                          ? "rgb(254, 151, 151, 0.19)"
                          : ""
                      }`,
                      border: "1px solid rgb(91, 187, 245, 0.41)",
                      color: "rgb(254, 111, 111)",
                    }}
                    value={filter_picker[2]}
                    onClick={() => {
                      setSelectedFilterPicker(filter_picker[2]);
                    }}
                  >
                    Bu Ay
                  </ShortButton>
                </ShortButtonContainer>
              </Column>
              <Column
                style={{ borderLeft: "1px solid #b2b2b2" }}
                className="xs-12 sm-12 md-12 lg-4"
              >
                <FilterContainer>
                  <FilterButton
                    onClick={() => {
                      setFilterModal(true);
                    }}
                  >
                    <DateRange />
                    <span
                      style={{
                        fontWeight: "500",
                        fontSize: "1.2em",
                        paddingLeft: "1vw",
                      }}
                    >
                      {startTime} - {endTime}
                    </span>
                  </FilterButton>
                </FilterContainer>
              </Column>
            </Column>
            <Grid>
              {" "}
              <Column className="xs-12 sm-12 md-12 lg-4">
                <AutocompleteInput
                  label={context.t(`[receipts/add].searchCustomerInputLabel`)}
                  labelKey="full_name"
                  valueKey="id"
                  returnFullObject
                  onRemove={async () => {
                    setSelectedCustomer(null);
                  }}
                  selected={selected_customer ? selected_customer : ""}
                  selectedHandler={async (selected_customer) => {
                    setSelectedCustomer(selected_customer);
                  }}
                  asyncDataService={async (keyword) =>
                    keyword.length > 2 ? await getCustomers(keyword) : []
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-4">
                <Select
                  label={context.t(`receipts.filter.typeOfSales`)}
                  items={sales_types}
                  labelKey="name"
                  valueKey="id"
                  selected={selected_sales ? selected_sales.id : null}
                  returnFullObject
                  handler={async (filterPicker) => {
                    setLoaded(false);
                    setSelectedSales(filterPicker);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-4">
                <Select
                  label={context.t(`receipts.filter.allSales`)}
                  items={sales_open_close}
                  labelKey="name"
                  valueKey="id"
                  selected={selected_open_close ? selected_open_close.id : null}
                  returnFullObject
                  handler={async (filterPicker) => {
                    setLoaded(false);
                    setSelectedOpenClose(filterPicker);
                  }}
                />
              </Column>
            </Grid>
          </>
        )}
      </Grid>

      <Grid>
        <Column className="xs-12 sm-12">
          {loaded ? (
            <Table
              refreshAction={() => {
                setLoaded(false);
                getOpenedReceipts();
              }}
              headings={{
                created_at: {
                  label: context.t(`receipts.headings.createdAt`),
                  style: { width: 200 },
                  sortable: {
                    0: [{ field: "`receipts`.`created_at`", order: "ASC" }],
                    1: [{ field: "`receipts`.`created_at`", order: "DESC" }],
                  },
                },
                is_package: {
                  label: context.t(`receipts.headings.type`),
                  style: { width: 150 },
                },
                "customer.full_name": {
                  label: context.t(`receipts.headings.customerFullName`),
                  // with_photo: "customer.profile_photo",
                  style: { width: 300 },
                  limited_line: 1,
                  sortable: {
                    0: [
                      { field: "`customers`.`name`", order: "ASC" },
                      { field: "`customers`.`surname`", order: "ASC" },
                    ],
                    1: [
                      { field: "`customers`.`name`", order: "DESC" },
                      { field: "`customers`.`surname`", order: "DESC" },
                    ],
                  },
                },
                info: {
                  label: context.t(`receipts.headings.info`),
                  style: { width: 400 },
                  limited_line: 2,
                },
                all_amount: {
                  label: context.t(`receipts.headings.allAmount`),
                  style: { width: 100 },
                  sortable: {
                    0: [{ field: "`receipts`.`all_amount`", order: "ASC" }],
                    1: [{ field: "`receipts`.`all_amount`", order: "DESC" }],
                  },
                },
                paid: {
                  label: context.t(`receipts.headings.paid`),
                  sortable: {
                    0: [{ field: "`receipts`.`paid`", order: "ASC" }],
                    1: [{ field: "`receipts`.`paid`", order: "DESC" }],
                  },
                  style: { width: 120 },
                },
                debt_total: {
                  label: context.t(`receipts.headings.debtTotal`),
                  sortable: {
                    0: [
                      { field: "`receipts`.`all_amount`", order: "ASC" },
                      { field: "`receipts`.`debt`", order: "DESC" },
                    ],
                    1: [
                      { field: "`receipts`.`all_amount`", order: "DESC" },
                      { field: "`receipts`.`debt`", order: "ASC" },
                    ],
                  },
                  style: { width: 120 },
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              rows={data}
              loaded={loaded}
              replacements={{
                "customer.full_name": {
                  null: context.t(`receipts.replacements.undefinedCustomer`),
                  "": context.t(`receipts.replacements.undefinedCustomer`),
                },
                is_package: {
                  true: context.t(`receipts.replacements.packageSale`),
                  false: context.t(`receipts.replacements.standartSale`),
                },
              }}
              pagination={pagination}
              buttons={[
                {
                  title: context.t(`receipts.showButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  onClick: (row) => history.push(`/receipts/detail/${row.id}`),
                },
                {
                  icon: "delete_forever",
                  backgroundColor: "red-opacity",
                  textColor: "red",
                  onClick: (row) => {
                    setSelectedIdForDelete(row.id);
                    setDeleteDialogModal(true);
                  },
                },
              ]}
            />
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={
                  process.env.APP_NAME === "salon"
                    ? Loader
                    : process.env.APP_NAME === "en"
                    ? LoaderEN
                    : LoaderSM
                }
                width="80"
                height="80"
                alt="loading"
              />
            </div>
          )}
        </Column>
      </Grid>
      <DeleteDialog />
      <AlertDialog
        open={filterModal}
        closeHandler={() => setFilterModal(false)}
        buttons={[
          {
            title: context.t(`receipts.detailButtons.confirmButton`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: async () => {
              setLoaded(false);
              setStart(modalStart);
              setEnd(modalEnd);
              setSelectedFilterPicker(null);
            },
          },
          {
            title: context.t(`["receipt/detail/id"].sms.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setSelectedFilterPicker(filter_picker[0]);
            },
          },
        ]}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-6">
            <ShortButtonContainer
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <ShortButton
                style={{
                  border: "1px solid rgba(249,186,132, 0.51)",
                  color: "rgb(219,146,92)",
                  backgroundColor: `${
                    readyFilter === 1 ? "rgb(254, 151, 151, 0.19)" : ""
                  }`,
                }}
                onClick={() => {
                  setReadyFilter(1);
                  setModalStart(
                    moment().local().subtract(1, "days").toISOString()
                  );
                  setModalEnd(moment().local().toISOString());
                }}
              >
                Dün
              </ShortButton>
              <ShortButton
                style={{
                  border: "1px solid rgb(91, 187, 245, 0.41)",
                  color: "rgb(49, 169, 243)",
                  backgroundColor: `${
                    readyFilter === 2 ? "rgb(49, 169, 243, 0.14)" : ""
                  }`,
                }}
                onClick={() => {
                  setReadyFilter(2);
                  setModalStart(
                    moment().local().subtract(7, "days").toISOString()
                  );
                  setModalEnd(moment().local().toISOString());
                }}
              >
                Son 7 gün
              </ShortButton>
              <ShortButton
                style={{
                  border: "1px solid rgb(58, 154, 87, 0.35)",
                  color: "rgb(29, 139, 62)",
                  backgroundColor: `${
                    readyFilter === 3 ? "rgb(29, 139, 62, 0.21)" : ""
                  }`,
                }}
                onClick={() => {
                  setReadyFilter(3);
                  setModalStart(
                    moment().local().subtract(15, "days").toISOString()
                  );
                  setModalEnd(moment().local().toISOString());
                }}
              >
                Son 15 gün
              </ShortButton>
              <ShortButton
                style={{
                  border: "1px solid rgb(185, 178, 178, 0.65)",
                  color: "rgb(254, 111, 111)",
                  backgroundColor: `${
                    readyFilter === 4 ? "rgb(254, 151, 151, 0.19)" : ""
                  }`,
                }}
                onClick={() => {
                  setReadyFilter(4);
                  setModalStart(
                    moment().local().subtract(30, "days").toISOString()
                  );
                  setModalEnd(moment().local().toISOString());
                }}
              >
                Son 30 gün
              </ShortButton>
            </ShortButtonContainer>
          </Column>
          <Column
            style={{
              display: "flex",
              justifycontent: "center",
              alignItems: "center",
            }}
            className="xs-12 sm-12 md-12 lg-6"
          >
            <div>
              <DatePickerInput
                className="mb-0"
                label={context.t(`receipts.filter.startInputLabel`)}
                value={new Date(moment(modalStart).toISOString())}
                maxDate={end}
                onChange={(start) => {
                  setModalStart(start);
                }}
              />

              <DatePickerInput
                className="mb-0"
                label={context.t(`receipts.filter.endInputLabel`)}
                value={modalEnd}
                minDate={new Date(moment(start).toISOString())}
                onChange={(end) => {
                  setModalEnd(end);
                }}
              />
            </div>
          </Column>
        </Grid>
      </AlertDialog>
    </AuthContainer>
  );
};

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
`;
const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-bottom: 10px;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export default ReceiptIndex;
