import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import { Column, Grid } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import { Alert } from "@material-ui/lab";

const Bounty = () => {
  const context = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    total_page: null,
    page: 1,
    onChange: (page) => getBounties(page),
  });
  const [bounties, setBounties] = useState([]);

  const [staffs, setStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  const [bounty, setBounty] = useState(null);

  const [deleteId, setDeleteId] = useState(null);

  const [isAddBountyButtonClicked, setIsAddBountyButtonClicked] =
    useState(false);

  const [openBountyDeleteDialog, setOpenBountyDeleteDialog] = useState(false);

  const getBounties = (page = 1) => {
    Axios.get(
      `${context.api_endpoint}/company/services/bounty/list?page=${page}`
    ).then((response) => {
      setLoaded(true);
      setBounties([...response.data.data.records]);
    });
  };

  const addBounty = () => {
    Axios.post(`${context.api_endpoint}/company/services/bounty/insert`, {
      bounty: bounty,
      service_id: selectedService.id,
      staff_id: selectedStaff.id,
    })
      .then((response) => {
        if (response.status === 201) {
          setIsAddBountyButtonClicked(false);
          toast.success(context.t(`['services/bounty'].addBountySuccessToast`));
          getBounties();

          setSelectedStaff(null);
          setSelectedCategory(null);
          setSelectedService(null);
          setBounty(null);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
        setIsAddBountyButtonClicked(false);
      });
  };

  const deleteBounty = (id) => {
    Axios.delete(`${context.api_endpoint}/company/services/bounty/delete/${id}`)
      .then((response) => {
        toast.success(
          context.t(`['services/bounty'].deleteBountySuccessToast`)
        );
        getBounties();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getStaffs = () => {
    Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      ({ data }) => {
        setStaffs([
          ...data.data.map((staff) => ({
            id: staff.id,
            full_name: `${staff.name} ${staff.surname}`,
          })),
        ]);
      }
    );
  };

  const getServiceNames = async (key) => {
    selectedCategory &&
      (await Axios.get(
        `${context.api_endpoint}/company/platform/services/list/all`,
        {
          params: {
            categoryid: selectedCategory.id,
            name: key,
          },
        }
      )
        .then(async (response) => {
          const { data } = response;
          await setServices([...data.data]);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        }));

    return services;
  };

  const getServices = () => {
    Axios.get(
      `${context.api_endpoint}/company/services/with/category/all`
    ).then(async ({ data }) => {
      setCategories([...data.data.filter((m) => m.services.length !== 0)]);
    });
  };

  useEffect(() => {
    getBounties();
    getStaffs();
    getServices();
  }, []);

  const SelectionBounty = (index) => {
    return (
      <React.Fragment>
        <Column className="xs-12 sm-12 md-6">
          <Select
            label={context.t(`['services/bounty'].staffInputLabel`)}
            items={staffs}
            selected={selectedStaff ? selectedStaff.id : null}
            labelKey="full_name"
            valueKey="id"
            returnFullObject
            handler={(selected_staff) => setSelectedStaff(selected_staff)}
          />
        </Column>
        <Column className="xs-12 sm-12 md-6">
          <Select
            label={context.t(`['services/bounty'].categoryInputLabel`)}
            items={categories}
            labelKey="name"
            valueKey="id"
            selected={selectedCategory !== null ? `${selectedCategory.id}` : ""}
            returnFullObject
            handler={(selected_category) =>
              setSelectedCategory(selected_category)
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-6">
          <Select
            label={context.t(`['services/bounty'].serviceInputLabel`)}
            items={selectedCategory ? selectedCategory.services : []}
            labelKey="name"
            valueKey="id"
            selected={selectedService !== null ? `${selectedService.id}` : ""}
            returnFullObject
            disabled={selectedCategory === null}
            handler={(selected_service) => setSelectedService(selected_service)}
          />
        </Column>
        <Column className="xs-12 sm-12 md-3 xl-6">
          <Input
            required
            label={`${context.t(
              `['services/bounty'].bountyAmountInputLabel`
            )}(%)`}
            value={bounty ?? ""}
            //helperText={context.t(`['services/detail'].amountInputHint`)}
            hoverHelperText={context.t(`['services/detail'].amountInputHint`)}
            type="number"
            InputProps={{
              min: 0,
              step: 1,
            }}
            onChange={(e) =>
              setBounty(
                e.target.value.length > 0 && parseInt(e.target.value) >= 0
                  ? parseInt(e.target.value)
                  : null
              )
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 xl-12">
          <Button
            title={context.t(`['services/bounty'].addBountyTitle`)}
            backgroundColor="primary-opacity"
            textColor="primary"
            size="lg"
            fullWidth={true}
            disabled={
              isAddBountyButtonClicked ||
              selectedStaff === null ||
              selectedCategory === null ||
              selectedService === null ||
              bounty === null
            }
            onClick={() => {
              setIsAddBountyButtonClicked(true);
              addBounty();
            }}
          />
        </Column>
      </React.Fragment>
    );
  };

  return (
    <AuthContainer limited_permission="services">
      <Grid>
        <Column className="xs-12 sm-12 md-12">
          <Alert severity="info">
            <b>{context.t(`['services/bounty'].bountyDescription`)}</b>
          </Alert>
        </Column>
        <Column className="xs-12 sm-12 md-12">
          <h2>{context.t(`['services/bounty'].addBountyTitle`)}</h2>
        </Column>
      </Grid>
      <Grid className="no-gutters-all">
        <Column className="xs-12 sm-12 md-12 xl-6">
          <SelectionBounty />
        </Column>
        <Column className="xs-12 sm-12 md-12 xl-6">
          <Table
            refreshAction={() => getBounties(pagination.page)}
            loaded={loaded}
            headings={{
              bounty: {
                label: context.t(`['services/bounty'].headings.bounty`),
                style: { width: 150 },
              },
              "service.name": {
                label: context.t(`['services/bounty'].headings.serviceName`),
                limited_line: 3,
                style: { width: "200px" },
              },
              "staff.full_name": {
                label: context.t(`['services/bounty'].headings.staffName`),
              },
              _: { label: context.t(`component.actionHeadingText`) },
            }}
            rows={bounties}
            pagination={pagination}
            buttons={[
              {
                title: context.t(
                  `['services/bounty'].headings.deleteBountyButtonTitle`
                ),
                icon: "delete",
                textColor: "red",
                transitionEffect: true,
                pushEffect: true,
                disabled: context.state.user.permission === 0,
                onClick: (row) => {
                  setDeleteId(row.id);
                  setOpenBountyDeleteDialog(true);
                },
              },
            ]}
          />
        </Column>
      </Grid>
      <AlertDialog
        title={context.t(`['services/bounty'].deleteBountyDialog.title`)}
        open={openBountyDeleteDialog}
        closeHandler={() => setOpenBountyDeleteDialog(false)}
        buttons={[
          {
            title: context.t(
              `['services/bounty'].deleteBountyDialog.confirmButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => deleteBounty(deleteId),
          },
          {
            title: context.t(
              `['services/bounty'].deleteBountyDialog.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>
            {context.t(`['services/bounty'].deleteBountyDialog.alertBoldText`)}
          </b>
          <br />
          {context.t(`['services/bounty'].deleteBountyDialog.alertText`)}
        </Alert>
      </AlertDialog>
    </AuthContainer>
  );
};

export default Bounty;
