// ** Redux Imports
import reducers from "./rootReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import { configureStore } from "@reduxjs/toolkit";

const saga = createSagaMiddleware();
export const store = configureStore({
  reducer: reducers,
  middleware: [saga],
});

saga.run(rootSaga);

export const api_endpoint = process.env.IS_TEST
  ? process.env.APP_NAME === "salon"
    ? "http://api2.salonrandevu.com/api/v1"
    : process.env.APP_NAME === "en"
    ? "http://api2.enrandevu.com/api/v1"
    : "http://api2.salonmanagementapp.com/api/v1"
  : process.env.APP_NAME === "salon"
  ? "https://api.salonrandevu.com/api/v1"
  : process.env.APP_NAME === "en"
  ? "https://api.enrandevu.com/api/v1"
  : "https://api.salonmanagementapp.com/api/v1";
