import React, { useEffect, useState, useContext } from "react";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Input from "../../../theme/CustomMUI/Input";
import { Column, Grid } from "../../../theme/Grid";
import styled from "styled-components";
import Axios from "axios";
import moment from "moment";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import { Alert } from "@material-ui/lab";
import Table from "../../../theme/Table";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LiveSupport = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getLiveSupports(page),
  });
  const [supportAbout, setSupportAbout] = useState(null);
  const [supportDetail, setSupportDetail] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [phone, setPhone] = useState(null);
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState(null);

  const [liveSupports, setLiveSupports] = useState([]);
  const [availableHours, SetAvailableHours] = useState([]);

  const [checkPhone, setCheckPhone] = useState(false);

  const getAvailableHours = () => {
    Axios.post(`${context.api_endpoint}/company/live/support/available/hours`, {
      date: moment(date).format("YYYY-MM-DD"),
    }).then((response) => {
      SetAvailableHours([...response.data.data]);
    });
  };

  const createLiveSupport = () => {
    validateLiveSupport() &&
      Axios.post(`${context.api_endpoint}/company/live/support/create`, {
        title: supportAbout,
        description: supportDetail,
        name: `${name} ${surname}`,
        phone: phone,
        appointment_date: `${moment(date).format("YYYY-MM-DD")}T${hour}:00Z`,
      }).then((response) => {
        toast.success(
          context.t(`[support/live_support].createLiveSupportRequestToast`)
        );
        setSupportAbout(null);
        setSupportDetail(null);
        setName(null);
        setSurname(null);
        setPhone(null);
        setDate(new Date());
        setHour(new Date());
        getLiveSupports();
      });
  };

  const getLiveSupports = (page = 1) => {
    Axios.get(`${context.api_endpoint}/company/live/support/list?page=${page}`)
      .then((response) => {
        setLoaded(true);
        setPagination({
          ...pagination,
          page: response.data.data.page,
          total_page: response.data.data.total_page,
        });
        setLiveSupports([
          ...response.data.data.records.map((request) => {
            return {
              ...request,
              appointment_date: moment(request.appointment_date).format(
                "DD/MM/YYYY HH:mm"
              ),
            };
          }),
        ]);
      })
      .catch((e) => {
        if (e.response.data.code === 401) {
          history.push("/wallet");
        }
      });
  };

  const cancelLiveSupportRequest = (id) => {
    Axios.post(`${context.api_endpoint}/company/live/support/cancel`, {
      id,
    }).then((response) => {
      toast.success(
        context.t(`[support/live_support].cancelLiveSupportRequestToast`)
      );
      getLiveSupports();
    });
  };

  const validateLiveSupport = () => {
    return Validate([
      {
        field: "İsim",
        value: name,
        condition: /.+/,
      },
      {
        field: "Soyisim",
        value: surname,
        condition: /.+/,
      },
      /* {
        field: "Telefon Numarası",
        value: phone,
        condition: /\S{10}/,
      }, */
      {
        field: "Destek Talebi Konusu",
        value: supportAbout,
        condition: /.+/,
      },
      {
        field: "Destek Talebi Ayrıntısı",
        value: supportDetail,
        condition: /.+/,
      },
      {
        field: "Saat",
        value: hour,
        condition: /.+/,
      },
    ]);
  };

  useEffect(() => {
    getAvailableHours();
    getLiveSupports();
  }, []);

  return (
    <AuthContainer>
      <Grid>
        <Column className="xs-12 sm-12 md-12">
          <h2>{context.t(`[support/live_support].liveSupportRequestTitle`)}</h2>
        </Column>
        <Column className="xs-12 sm-12 md-6">
          <Input
            required
            label={context.t(`[support/live_support].nameInputLabel`)}
            value={name ?? ""}
            placeholder={context.t(`[support/live_support].nameInputHint`)}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            required
            label={context.t(`[support/live_support].surnameInputLabel`)}
            value={surname ?? ""}
            placeholder={context.t(`[support/live_support].surnameInputHint`)}
            onChange={(e) => setSurname(e.target.value)}
          />
          <MaskedInput
            required
            label={context.t(`[support/live_support].phoneNumberInputLabel`)}
            maskChar={""}
            mask="999 999 9999"
            startAdornment="0"
            value={phone ?? ""}
            placeholder="500 000 0000"
            onChange={(e) => {
              phone === null || e.target.value.length < 12
                ? setCheckPhone(false)
                : setCheckPhone(true);
              setPhone(e.target.value);
            }}
            helperText={!checkPhone ? context.t(`["support/live_support"].enterPhoneLabel`) : ""}
          />
          <Input
            required
            label={context.t(`[support/live_support].supportRequestAboutLabel`)}
            value={supportAbout ?? ""}
            placeholder={context.t(
              `[support/live_support].supportRequestAboutHint`
            )}
            onChange={(e) => setSupportAbout(e.target.value)}
          />
          <Input
            required
            label={context.t(
              `[support/live_support].supportRequestDetailLabel`
            )}
            value={supportDetail ?? ""}
            placeholder={context.t(
              `[support/live_support].supportRequestDetailHint`
            )}
            hoverHelperText={context.t(
              `[support/live_support].supportRequestDetailHelperText`
            )}
            multiline={true}
            rows={7}
            onChange={(e) => setSupportDetail(e.target.value)}
          />
        </Column>
        <Column className="xs-12 sm-12 md-6">
          <h3>{context.t(`[support/live_support].appointmentDate`)}</h3>
          <DatePickerInput
            className="mb-2"
            label={context.t(`[support/live_support].dateInputLabel`)}
            value={date}
            format="DD MMMM dddd YYYY"
            onChange={async (selected_date) => {
              await setDate(selected_date);
              getAvailableHours();
              setHour(null);
            }}
          />
          <HoursContainer className="mb-2">
            {availableHours.length > 0 ? (
              availableHours.map((aHour) => (
                <HourButton
                  key={aHour}
                  className={`${
                    hour !== aHour
                      ? "bg-blue-opacity fc-blue"
                      : "bg-primary fc-white"
                  }`}
                  style={{ margin: "5px" }}
                  onClick={() => {
                    setHour(aHour);
                  }}
                >
                  {aHour}
                </HourButton>
              ))
            ) : (
              <p>{context.t(`[support/live_support].noAvailableHours`)}</p>
            )}
          </HoursContainer>
          <Alert severity="info" className="mb-2">
            <b>
              {context.t(`[support/live_support].alertInfo.text1`)}{" "}
              <a href="https://www.alpemix.com/site/alpemix.exe">
                {" "}
                {context.t(`[support/live_support].alertInfo.text2`)}
              </a>{" "}
              {context.t(`[support/live_support].alertInfo.text3`)}{" "}
              <b>{context.t(`[support/live_support].alertInfo.text4`)}</b>{" "}
              {context.t(`[support/live_support].alertInfo.text5`)}
            </b>
          </Alert>
          <Alert severity="warning" className="mb-2">
            <b>
              {context.t(`[support/live_support].appointmentCancelDescription`)}
            </b>
          </Alert>
          <Button
            disabled={!checkPhone}
            title={context.t(
              `[support/live_support].createAppointmentButtonTitle`
            )}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            onClick={() => {
              createLiveSupport();
            }}
          />
        </Column>
      </Grid>
      <Grid>
        <Column className="xs-12 sm-12 md-12">
          <h2>{context.t(`[support/live_support].appointmentRequestTitle`)}</h2>
        </Column>
        <Column className="xs-12 sm-12 md-12">
          <Table
            refreshAction={() => getLiveSupports()}
            loaded={loaded}
            headings={{
              appointment_date: {
                label: context.t(
                  `[support/live_support].headings.appointmentDate`
                ),
                style: { width: 170 },
              },
              title: {
                label: context.t(
                  `[support/live_support].headings.appointmentAbout`
                ),
              },
              description: {
                label: context.t(
                  `[support/live_support].headings.appointmentDetail`
                ),
                limited_line: 1,
                style: { width: 300, maxWidth: 1500 },
              },
              name: {
                label: context.t(`[support/live_support].headings.name`),
              },
              phone: {
                label: context.t(`[support/live_support].headings.phoneNumber`),
              },
              status: {
                label: context.t(
                  `[support/live_support].headings.appointmentStatus`
                ),
              },
              _: { label: context.t(`component.actionHeadingText`) },
            }}
            rows={liveSupports}
            pagination={pagination}
            replacements={{
              status: {
                0: context.t(
                  `[support/live_support].headings.replacements.status.waiting`
                ),
                1: context.t(
                  `[support/live_support].headings.replacements.status.done`
                ),
                2: context.t(
                  `[support/live_support].headings.replacements.status.cancel`
                ),
              },
            }}
            buttons={[
              {
                title: context.t(
                  `[support/live_support].headings.cancelButtonTitle`
                ),
                icon: "close",
                textColor: "red",
                disabled: (row) => !!row.status,
                onClick: (row) => cancelLiveSupportRequest(row.id),
              },
            ]}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default LiveSupport;

const HoursContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items-center;
  gap: 0.3rem;
`;

const HourButton = styled.button`
  border: none;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0px 0px 2px grey;
`;
