import { initialState } from "./store";

const AppReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_DEFAULT_LANGUAGE":
      return {
        ...state,
        default_locale: action.payload.default_locale,
      };

    case "LOGIN_USER":
      return {
        ...state,
        ...action.payload,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };

    case "LOGOUT_USER":
      return {
        ...initialState,
        is_authenticated: false,
        token_expire_date: null,
      };

    case "SET_COMPANY_SHIFT":
      return {
        ...state,
        company_shift: {
          ...action.payload,
        },
      };

    case "SET_COMPANY_SERVICES":
      return {
        ...state,
        company_services: [...action.payload],
      };

    case "SET_COMPANY_ID":
      return {
        ...state,
        ...action.payload,
      };

    case "SET_COMPANY_SMS_SETTINGS":
      return {
        ...state,
        company_sms_settings: action.payload.company_sms_settings,
        company_sms_settings_loaded: action.payload.company_sms_settings_loaded,
      };

    case "SET_COMPANY_LICENSE":
      return {
        ...state,
        company_license: action.payload.company_license,
      };

    case "SET_COMPANY_FIRST_BRANCH":
      return {
        ...state,
        company_branch_first: {
          ...action.payload,
        },
      };

    case "SET_COMPANY_BRANCH_SETTINGS":
      return {
        ...state,
        company_is_branch:
          action.payload.company_is_branch ?? state.company_is_branch,
        company_branches:
          action.payload.company_branches ?? state.company_branches,
        company_branch_selected:
          action.payload.company_branch_selected ??
          state.company_branch_selected,
      };

    case "SET_APPOINTMENT_NOTIFICATION_DIALOG":
      return {
        ...state,
        appointment_notification:
          action.payload.open_appointment_notificaiton_dialog,
      };

    case "SET_COMPANY_TUTORIAL":
      return {
        ...state,
        company_tutorial:
          action.payload.company_tutorial ?? state.company_tutorial,
      };

    case "SET_SMS_CREDIT":
      return {
        ...state,
        sms_credit: action.payload.sms_credit ?? 0,
      };

    case "SET_REMAINING_APPOINTMENT":
      return {
        ...state,
        remainingAppointment: action.payload.remaining_appointment ?? 0,
      };

    case "SET_LIMITED_PERMISSION":
      return {
        ...state,
        limited_permission: { ...action.payload.limited_permission } ?? null,
      };

    case "SET_WHATSAPP_PERMISSON":
      return {
        ...state,
        whatsapp_permission: action.payload.whatsapp_permission,
      };

    case "SET_ONLINE_LINK":
      return {
        ...state,
        online_link: action.payload.online_link,
      };

    case "SET_LICENSE_CHOOSEN":
      return {
        ...state,
        license_choosen: action.payload.license_choosen,
      };

    case "SET_LICENSE_CHOOSEN_TRUE":
      return {
        ...state,
        license_choosen: false,
      };

    case "SET_DIAL_CODE":
      return {
        ...state,
        dialCode: action.payload.dialCode,
      };

    case "SET_COUNTRY_CODE":
      return {
        ...state,
        countryCode: action.payload.countryCode,
      };

    case "SET_BALANCE":
      return {
        ...state,
        balance: action.payload.balance,
      };
    case "SET_FIELDCARDTWOTITLE":
      return {
        ...state,
        fieldCardTwoTitle: action.payload.fieldCardTwoTitle,
      };
    case "SET_API_KEY":
      return {
        ...state,
        api_key: action.payload.api_key,
      };
    case "SET_WP_STATE":
      return {
        ...state,
        wp_state: action.payload.wp_state,
      };
    case "SET_WP_PHONE":
      return {
        ...state,
        wp_phone: action.payload.wp_phone,
      };
    case "SET_CHECKEDWP":
      return {
        ...state,
        checkedWP: action.payload.checkedWP,
      };
    case "SET_SENDER":
      return {
        ...state,
        sender: action.payload.sender,
      };
    case "EMPLOYEE_PACKAGES":
      return {
        ...state,
        employeePackages: action.payload.employeePackages,
      };
    case "IS_DEALER":
      return {
        ...state,
        is_dealer: action.payload.is_dealer,
      };
    case "LOG_TYPE":
      return {
        ...state,
        log_type: action.payload.log_type,
      };
    case "LOG_ID":
      return {
        ...state,
        log_id: action.payload.log_id,
      };
    case "CURRENCY":
      return {
        ...state,
        currency: action.payload.currency,
      };
    case "CURRENCY_CODE":
      return {
        ...state,
        currencyCode: action.payload.currencyCode,
      };
    default:
      return { ...state };
  }
};

export default AppReducer;
