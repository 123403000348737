import React, { useEffect, useState, useContext } from "react";
import AuthContainer from "../../../components/AuthContainer";
import { Column, Grid } from "../../../theme/Grid";
import styled from "styled-components";
import SemiCirclePie from "../../../components/Charts/SemiCirclePie";
import CustomMUIDatePicker from "../../../theme/CustomMUI/DatePicker";
import HorizontalBar from "../../../components/Charts/HorizontalBar";
import AppContext from "../../../context/store";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Axios from "axios";
import { favoritePage } from "../../../functions/FavoritePages";
import moment from "moment";
import Table from "../../../theme/Table";
import { toast } from "react-toastify";
import RoundedRow from "../../../components/Table/RoundedRow";
import { EventNote } from "@material-ui/icons";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Alert from "@material-ui/lab/Alert";
import StatsCard from "../../../components/Cards/StatsCard";
import CustomDateRangeColumn from "../../../components/Cards/CustomDateRangeColumn";
import FieldCard from "../../../components/Cards/FieldCard";
import FieldCardTwoTitle from "../../../components/Cards/FieldCardTwoTitle";
import Button from "../../../theme/Button";
import CDoughnut from "../../../components/Charts/CDoughnut";
import packageJson from "../../../../package.json";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DashboardIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [favoritePages, setFavoritePages] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getAppointmentRequests(page),
  });

  const [paginationBirthDay, setPaginationBirthDay] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getBirthDayRequests(page),
  });

  const [paginationOpenAppointments, setPaginationOpenAppointments] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getOpenApoointmentRequests(page),
  });

  const [paginationCredits, setPaginationCredits] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getCreditsRequests(page),
  });

  const [offsets, setOffsets] = useState({
    appointmentoffset: 1,
    receiptincomeoffset: 1,
    // appointment_offset: 1,
    // customer_offset: 1,
    // accounting_offset: 1,
    // appoinment_zone_offset: 1,
    // receipt_income_offset: 1,
  });

  const [onlineAppointments, setOnlineAppointments] = useState({
    loaded: false,
    data: [],
  });

  const [openAppointments, setOpenAppointments] = useState({
    loaded: false,
    data: [],
  });

  const [creditors, setCreditors] = useState({
    loaded: false,
    data: [],
  });

  const [birthDay, setBirthDay] = useState({
    loaded: false,
    data: [],
  });

  const [stats, setStats] = useState({
    loaded: false,
    appointments: {
      succeed_appointments: null,
      closed_appointments: null,
      created_appointments: null,
    },
    receipt_income: {
      0: 0,
      1: 0,
      2: 0,
    },
  });

  const [appointmentDatasets, setAppointmentDatasets] = useState({
    datasets: [
      {
        label: "# of Votes",
        data: [],
        backgroundColor: [
          "rgba(29, 139, 62, 0.4)",
          "rgba(49, 137, 236, 0.6)",
          "rgba(140, 15, 180, 0.4)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [appointmentZone, setAppointmentZone] = useState({
    from_company: 0,
    from_platform: 0,
    from_staff: 0,
    totalPercent: 0,
  });

  const [appStatistics, setAppStatistics] = useState({
    from_company: 0,
    from_platform: 0,
    from_staff: 0,
  });

  // Open hidden field of FieldCards
  const [appointmentTypeOpenField, setAppointmentTypeOpenField] =
    useState(false);
  const [receiptIncomeOpenField, setReceiptIncomeOpenField] = useState(false);

  const [pageScrolled, setPageScrolled] = useState(false);

  const [openTutorial, setOpenTutorial] = useState(false);

  const [packageNo, setPackageNo] = useState(0);

  const [qrCodeDialog, setQrCodeDialog] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [replacements, setReplacements] = useState();

  const [birthdayLoaded, setBirthdayLoaded] = useState(false);

  const [selectedRangeReceiptIncome, setSelectedRangeReceiptIncome] =
    useState(0);
  const [selectedRangeAppointments, setSelectedRangeAppointments] = useState(0);
  const [selectedRangeAppointmentStats, setSelectedRangeAppointmentStats] =
    useState(0);

  const [isSelectedRangeReceiptIncome, setIsSelectedRangeReceiptIncome] =
    useState(false);
  const [isSelectedRangeAppointments, setIsSelectedRangeAppointments] =
    useState(false);
  const [isSelectedRangeAppointmentStats, setIsSelectedRangeAppointmentStats] =
    useState(false);

  const QRCodeDialog = () => {
    return (
      <AlertDialog
        open={qrCodeDialog}
        disableBackdropClick={false}
        closeHandler={() => setQrCodeDialog(false)}
        buttons={[
          {
            icon: "close",
            title: context.t(`appointments.detail.closeButtonTitle`),
            textColor: "black",
            outlined: true,
          },
        ]}
      >
        <div
          style={{
            height: "auto",
            margin: "50px 40px",
            maxWidth: 256,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${context.app.WEB}/isletme/${context.state?.company_id}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </AlertDialog>
    );
  };
  const getFavoritePages = () => {
    Axios.get(`${context.api_endpoint}/staff/favorites`)
      .then((response) => {
        if (response.status === 200) {
          setFavoritePages([
            ...response.data.data.data.split(
              ",",
              response.data.data.data.split(",").length - 1
            ),
          ]);
        } else {
          setFavoritePages([]);
        }
      })
      .catch((e) => {
        setFavoritePages([]);
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  //get opened appointments
  const getOpenApoointmentRequests = (page) => {
    Axios.get(`${context.api_endpoint}/company/receipts/opened`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        if (data.data.receipts.records) {
          setOpenAppointments({
            ...openAppointments,
            loaded: true,
            data: [
              ...data.data.receipts.records.map((item) => ({
                id: item.id,
                date: moment(item.created_at).format("DD.MM.YYYY"),
                customerFullName: item.customer?.full_name,
                totalPayment:
                  item.all_amount +
                  (context.state.currency ? context.state.currency : " ₺"),
                serviceName: item.service_count,
                products: item.stock_item_count,
                type:
                  item.is_paid === false
                    ? `${
                        process.env.APP_NAME === "management"
                          ? "Not Paid"
                          : "Ödenmedi"
                      }`
                    : `${
                        process.env.APP_NAME === "management"
                          ? "Paid"
                          : "Ödendi"
                      }`,
                // paymentDate: moment(item.payment_date).format("DD.MM.YYYY"),
              })),
            ].filter((item) => item !== undefined),
          });
          setPaginationOpenAppointments({
            ...paginationOpenAppointments,
            page: data.data.receipts.page,
            total_page: data.data.receipts.records.length,
          });
        } else {
          setOpenAppointments({
            ...openAppointments,
            loaded: true,
            data: [],
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          //toast.error(e.response.data.message);
        } else {
          //toast.warning(e.response.data.message);
        }
      });
  };
  //get credits
  const getCreditsRequests = (page) => {
    Axios.get(`${context.api_endpoint}/company/current/incomes`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        if (data.data.records) {
          setCreditors({
            ...creditors,
            loaded: true,
            data: [
              ...data.data.records.map((item) => ({
                customerFullName:
                  item.customer.name + " " + item.customer.surname,
                totalPayment:
                  item.all_amount +
                  (context.state.currency ? context.state.currency : " ₺"),
                type:
                  item.is_paid === false
                    ? `${
                        process.env.APP_NAME === "management"
                          ? "Not Paid"
                          : "Ödenmedi"
                      }`
                    : `${
                        process.env.APP_NAME === "management"
                          ? "Paid"
                          : "Ödendi"
                      }`,
                paymentDate: moment(item.payment_date).format("DD.MM.YYYY"),
              })),
            ].filter((item) => item !== undefined),
          });
          setPaginationCredits({
            ...paginationCredits,
            page: data.data.page,
            total_page: data.data.records.length,
          });
        } else {
          setCreditors({
            ...creditors,
            loaded: true,
            data: [],
          });
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          toast.error(e.response?.data?.message);
        } else {
          toast.warning(e.response?.data?.message);
        }
      });
  };

  //get birthday
  const getBirthDayRequests = (page) => {
    Axios.get(`${context.api_endpoint}/company/customer/birthdays`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        setBirthDay({
          ...birthDay,
          loaded: true,
          data: [
            ...data.data.records.map((item) => ({
              customerFullName: item.full_name,
              birthDate: new Date(item.birthday).toLocaleString("tr-TR", {
                day: "numeric",
                month: "long",
              }),
              customer_phone: item.phone,
            })),
          ].filter((item) => item !== undefined),
        });
        setPaginationBirthDay({
          ...paginationBirthDay,
          page: data.data.page,
          total_page: data.data.records.length,
        });
        setBirthdayLoaded(true);
      })
      .catch(() => {
        //toast.error(e.response.data.message);
      });
  };
  const getAppointmentRequests = (page) => {
    Axios.get(`${context.api_endpoint}/company/appointments/requests`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        setOnlineAppointments({
          ...onlineAppointments,
          loaded: true,
          data: [
            ...data.data.records.map((item) => ({
              ...item,
              customer_phone:
                context.state.user.permission === 2 &&
                context.state.limited_permission.customer_info === 0
                  ? "----------"
                  : item.customer_phone,
              customer_statics: {
                ...item.customer_statics,
                sumCol: (
                  <>
                    <RoundedRow color="blue">
                      <EventNote /> {item.customer_statics.success}/
                      {item.customer_statics.success +
                        item.customer_statics.wait +
                        item.customer_statics.fail}
                    </RoundedRow>
                    {/* <RoundedRow color="green">
                    <AlarmOn /> {item.customer_statics.success}
                  </RoundedRow>
                  <RoundedRow color="orange">
                    <Alarm /> {item.customer_statics.wait}
                  </RoundedRow>
                  <RoundedRow color="red">
                    <AlarmOff /> {item.customer_statics.fail}
                  </RoundedRow> */}
                  </>
                ),
              },
              appointment_start_date: moment(item.appointment_start_date)
                .tz("Europe/Istanbul")
                .format("DD/MM/YYYY HH:mm"),
            })),
          ].filter((item) => item !== undefined),
        });
        setPagination({
          ...pagination,
          page: data.data.page,
          total_page: data.data.records.length,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          history.push("/wallet");
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const approveSingleAppointmentRequest = (id) => {
    Axios.post(`${context.api_endpoint}/company/appointment/request/approve`, {
      request_id: id,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`dashboard.onlineAppointments.approveToast`));
          getAppointmentRequests();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const rejectSingleAppointmentRequest = (id) => {
    Axios.post(
      `${context.api_endpoint}/company/appointment/request/${id}/cancel`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`dashboard.onlineAppointments.rejectToast`));
          getAppointmentRequests();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getAnalysis = () => {
    Axios.get(`${context.api_endpoint}/company/analytics`, {
      params: {
        ...offsets,
      },
    })
      .then(({ data }) => {
        const totalAppointmentZone = Object.keys(data.data.appointments)
          .filter((item) => item.split("_")[0] === "from")
          .reduce((value, key) => (value += data.data.appointments[key]), 0);
        const appointmentZones = {};
        for (let key in data.data.appointments) {
          if (key.split("_")[0] === "from") {
            appointmentZones[key] =
              data.data.appointments[key] !== 0
                ? (
                    (data.data.appointments[key] / totalAppointmentZone) *
                    100
                  ).toFixed(0)
                : 0;
          }
        }
        // Same with receipt_income
        let receipt_income = { 0: 0, 1: 0, 2: 0 };
        data.data.receipt_income.map(
          (p) => (receipt_income[`${p.payment_type}`] = p.amount)
        );
        setAppStatistics({
          ...appStatistics,
          from_company: data.data.appointments.from_company,
          from_platform: data.data.appointments.from_platform,
          from_staff: data.data.appointments.from_staff,
        });
        setStats({
          ...data.data,
          loaded: true,
          receipt_income: {
            ...stats.receipt_income,
            ...receipt_income,
          },
        });
        setAppointmentDatasets({
          datasets: [
            {
              ...appointmentDatasets.datasets[0],
              data: [
                ...[...Object.keys(data.data.appointments)]
                  .filter((key) => key.split("_")[0] === "from")
                  .map((key) => data.data.appointments[key]),
              ],
            },
          ],
        });
        setAppointmentZone({
          ...Object.assign(
            {},
            ...Object.keys(appointmentZones).map((key) => ({
              [key]: appointmentZones[key],
            }))
          ),
          totalPercent: totalAppointmentZone,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getAnalysisRI = () => {
    Axios.get(`${context.api_endpoint}/company/analytics`, {
      params: {
        ...offsets,
      },
    })
      .then(({ data }) => {
        const totalAppointmentZone = Object.keys(data.data.appointments)
          .filter((item) => item.split("_")[0] === "from")
          .reduce((value, key) => (value += data.data.appointments[key]), 0);
        const appointmentZones = {};
        for (let key in data.data.appointments) {
          if (key.split("_")[0] === "from") {
            appointmentZones[key] =
              data.data.appointments[key] !== 0
                ? (
                    (data.data.appointments[key] / totalAppointmentZone) *
                    100
                  ).toFixed(0)
                : 0;
          }
        }

        let receipt_income = { 0: 0, 1: 0, 2: 0 };
        data.data.receipt_income.map(
          (p) => (receipt_income[`${p.payment_type}`] = p.amount)
        );

        setAppStatistics({
          ...appStatistics,
          from_company: data.data.appointments.from_company,
          from_platform: data.data.appointments.from_platform,
          from_staff: data.data.appointments.from_staff,
        });
        setStats({
          ...stats,
          receipt_income: {
            ...stats.receipt_income,
            ...receipt_income,
          },
        });
        setAppointmentDatasets({
          datasets: [
            {
              ...appointmentDatasets.datasets[0],
              data: [
                ...[...Object.keys(data.data.appointments)]
                  .filter((key) => key.split("_")[0] === "from")
                  .map((key) => data.data.appointments[key]),
              ],
            },
          ],
        });
        setAppointmentZone({
          ...Object.assign(
            {},
            ...Object.keys(appointmentZones).map((key) => ({
              [key]: appointmentZones[key],
            }))
          ),
          totalPercent: totalAppointmentZone,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getAnalysisRA = () => {
    Axios.get(`${context.api_endpoint}/company/analytics`, {
      params: {
        ...offsets,
      },
    })
      .then(({ data }) => {
        const totalAppointmentZone = Object.keys(data.data.appointments)
          .filter((item) => item.split("_")[0] === "from")
          .reduce((value, key) => (value += data.data.appointments[key]), 0);
        const appointmentZones = {};
        for (let key in data.data.appointments) {
          if (key.split("_")[0] === "from") {
            appointmentZones[key] =
              data.data.appointments[key] !== 0
                ? (
                    (data.data.appointments[key] / totalAppointmentZone) *
                    100
                  ).toFixed(0)
                : 0;
          }
        }
        // Same with receipt_income
        let receipt_income = { 0: 0, 1: 0, 2: 0 };
        data.data.receipt_income.map(
          (p) => (receipt_income[`${p.payment_type}`] = p.amount)
        );
        setStats({
          ...data.data,
          loaded: true,
          receipt_income: {
            ...stats.receipt_income,
            ...receipt_income,
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const changeAppointmentZoneOffset = (offset, field) => {
    setOffsets({
      ...offsets,
      [field]: offset,
    });
    setAppointmentTypeOpenField(false);
    setReceiptIncomeOpenField(false);
    getAnalysis();
  };

  const caching = () => {
    let version = localStorage.getItem("version");
    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.setItem("version", packageJson.version);
    }
  };

  const getPackageNo = () => {
    Axios.get(`${context.api_endpoint}/company/license/packetno`)
      .then((data) => setPackageNo(data.data.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPackageNo();

    getAppointmentRequests();
    getBirthDayRequests();
    getCreditsRequests();
    getOpenApoointmentRequests();

    getFavoritePages();
    getAnalysis();
    getAnalysisRI();
    getAnalysisRA();
  }, []);

  useEffect(() => {
    getAnalysis();
    getAnalysisRI();
    getAnalysisRA();
  }, [
    isSelectedRangeReceiptIncome,
    isSelectedRangeAppointments,
    isSelectedRangeAppointmentStats,
  ]);

  const DashboardFields = () => {
    const TabPanel = (props) => {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    };

    /*TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };*/
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    React.useEffect(() => {
      if (
        value === 3 &&
        birthDay.data.length === 0 &&
        birthdayLoaded !== true
      ) {
        getBirthDayRequests();
      } else if (value === 2 && creditors.data.length === 0) {
        getCreditsRequests();
      } else if (value === 1 && openAppointments.data.length === 0) {
        getOpenApoointmentRequests();
      }
    }, [value]);
    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }
    if (packageNo === 1 || packageNo === 4) {
      return (
        <>
          <Grid>
            <QRCodeDialog />
            {/* Kasa Istatikleri */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <FieldCard
                id="card-receipt_income"
                cardTitle={context.t(
                  `dashboard.cardTitle.receipt_income_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.receipt_income_card_description`
                )}
                style={{
                  height: "330px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evently",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <CustomDateRangeColumn
                  style={{ width: "100%" }}
                  setSelectedRange={setSelectedRangeReceiptIncome}
                  selectedRange={selectedRangeReceiptIncome}
                  onClickHandler={async (receiptincomeoffset) => {
                    await setOffsets({
                      ...offsets,
                      receiptincomeoffset,
                    });
                    await getAnalysisRI();
                    setIsSelectedRangeReceiptIncome(true);
                    setIsSelectedRangeAppointments(false);
                    setIsSelectedRangeAppointmentStats(false);
                  }}
                >
                  <Grid style={{ width: "100%", height: "100%" }}>
                    {stats.loaded &&
                    Object.keys(stats.receipt_income).filter(
                      (key) => stats.receipt_income[key] > 0
                    ).length > 0 ? (
                      <>
                        <SemiCirclePie
                          data={[
                            {
                              label: context.t(
                                `dashboard.report.company.typeCASH`
                              ),
                              count: stats.receipt_income["0"],
                            },
                            {
                              label: context.t(
                                `dashboard.report.company.typeCARD`
                              ),
                              count: stats.receipt_income["1"],
                            },
                            {
                              label: context.t(
                                `dashboard.report.company.typeEFT`
                              ),
                              count: stats.receipt_income["2"],
                            },
                          ]}
                          colors={["#C6D3C2", "#A4B99A", "#6D8563"]}
                          labelKey="label"
                          valueKey="count"
                          labelText={`{category}\n{value.percent.formatNumber('#.0')}% ({value}${
                            context.state.currency
                              ? context.state.currency
                              : " ₺"
                          })`}
                          containerStyle={{ width: "100%", height: "55%" }}
                          innerRadius={45}
                          showOnlyValue={true}
                        />
                      </>
                    ) : (
                      <p style={{ textAlign: "center", marginTop: "50px" }}>
                        {context.t(`dashboard.report.company.noDataText`)}
                      </p>
                    )}
                    <Report>
                      <div>
                        <h4 style={{ color: "#a0a0a0" }}>
                          {context.t(`dashboard.report.company.totalIncome`)}
                        </h4>
                        <h2>
                          {stats.receipt_income["0"] +
                            stats.receipt_income["1"] +
                            stats.receipt_income["2"]}
                          {context.state.currency
                            ? context.state.currency
                            : " ₺"}
                        </h2>
                      </div>
                    </Report>
                  </Grid>
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
            {/* Randevu Takvimi */}
            <Column className="xs-12 sm-12 md-12 lg-4 mb-1">
              <FieldCard
                id="card-appointments"
                cardTitle={context.t(
                  `dashboard.cardTitle.appointment_calender_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointment_calender_card_description`
                )}
                style={{
                  height: "330px",
                  overflowX: "auto",
                  background: "#FEF4EA",
                  position: "relative",
                }}
              >
                <CustomMUIDatePicker
                  autoOk
                  openTo="date"
                  variant="static"
                  orientation="landspace"
                  disableToolbar={true}
                  showTodayButton={true}
                  value={new Date()}
                  expanded={true}
                  background="#FEF4EA"
                  onChange={(selected_date) => {
                    const formattedDate =
                      selected_date._d instanceof Date
                        ? selected_date._d.toISOString()
                        : selected_date;

                    history.push({
                      pathname: "/appointments",
                      state: {
                        date: formattedDate,
                      },
                    });
                  }}
                />
              </FieldCard>
            </Column>

            {/* Randevu Ayrıntıları */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <FieldCardTwoTitle
                id="card-appointment_details"
                style={{ height: "330px", overflow: "scroll" }}
                cardTitle={context.t(
                  `dashboard.cardTitle.appointments_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointments_card_description`
                )}
              >
                {context.state.fieldCardTwoTitle === 0 ? (
                  <CustomDateRangeColumn
                    style={{ width: "100%" }}
                    /* sectionTitle={context.t(
                  `dashboard.stats.appointment.heading`
                )} */
                    setSelectedRange={setSelectedRangeAppointments}
                    selectedRange={selectedRangeAppointments}
                    onClickHandler={async (appointmentoffset) => {
                      await setOffsets({
                        ...offsets,
                        appointmentoffset,
                      });
                      await getAnalysisRA();
                      setIsSelectedRangeReceiptIncome(false);
                      setIsSelectedRangeAppointments(true);
                      setIsSelectedRangeAppointmentStats(false);
                    }}
                  >
                    <StatsCard
                      className="mb-2"
                      icon={{ label: "alarm", color: "purple" }}
                      title={context.t(
                        `dashboard.stats.appointment.createdAppointments`
                      )}
                      value={stats.appointments.created_appointments}
                    />
                    <StatsCard
                      className="mb-2"
                      icon={{ label: "alarm_on", color: "green" }}
                      title={context.t(
                        `dashboard.stats.appointment.successAppointments`
                      )}
                      value={stats.appointments.succeed_appointments}
                    />
                    <StatsCard
                      className="mb-2"
                      icon={{ label: "alarm_off", color: "red" }}
                      title={context.t(
                        `dashboard.stats.appointment.closedAppointments`
                      )}
                      value={stats.appointments.closed_appointments}
                    />
                  </CustomDateRangeColumn>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",

                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          icon="crop_free"
                          title={context.t(`dashboard.PlatformLink.Qr`)}
                          onClick={() => setQrCodeDialog(true)}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div style={{ maxWidth: "330px" }}>
                            <h4 style={{ fontWeight: "bold" }}>
                              {context.t(`dashboard.PlatformLink.title`)}
                            </h4>
                            <p style={{ wordWrap: "break-word" }}>
                              {context.t(`dashboard.PlatformLink.description`)}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "15px" }}>
                            <a
                              href={`${context.app.WEB}/isletme/${context.state?.company_id}`}
                              target="_blank"
                              style={{
                                marginTop: "5px",
                                width: "150px",
                                height: "35px",
                                padding: "5px",
                                backgroundColor: "#3189ec",
                                color: "white",
                                borderRadius: "10px",
                                cursor: "pointer",
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                textDecoration: "none",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.opacity = "0.7";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.opacity = "1";
                              }}
                            >
                              <CallMissedOutgoingIcon />{" "}
                              {context.t(`dashboard.PlatformLink.goToLink`)}
                            </a>
                            <CopyToClipboard
                              text={
                                context.state?.company_id !== null
                                  ? `${context.app.WEB}/isletme/${context.state?.company_id}`
                                  : "Url Not Found"
                              }
                            >
                              <a
                                onClick={() => {
                                  context.state?.company_id !== null
                                    ? toast.success(
                                        context.t(
                                          `dashboard.PlatformLink.linkCopied`
                                        )
                                      )
                                    : toast.error("Url Not Found");
                                }}
                                style={{
                                  marginTop: "5px",
                                  width: "150px",
                                  height: "35px",
                                  padding: "5px",
                                  backgroundColor: "#4CAF50",
                                  color: "white",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  border: "none",
                                  boxShadow: " 0 4px 6px rgba(0, 0, 0, 0.1)",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "center",
                                  textDecoration: "none",
                                }}
                                onMouseEnter={(e) => {
                                  e.target.style.opacity = "0.7";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.opacity = "1";
                                }}
                              >
                                <FileCopyIcon />{" "}
                                {context.t(`dashboard.PlatformLink.copyLink`)}
                              </a>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </FieldCardTwoTitle>
            </Column>
          </Grid>

          {/* Last Row */}
          <Grid className="mb-2">
            {/* Online Randevu Talepleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-8 mb-1">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  textColor="inherit"
                >
                  <Tab
                    style={{
                      backgroundColor: "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                    }}
                    label={context.t(
                      `dashboard.waitingAppointments.sectionHeading`
                    )}
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{
                      backgroundColor: "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                    }}
                    label={context.t(`dashboard.openAppointments.title`)}
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{
                      backgroundColor: "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                    }}
                    label={context.t(`dashboard.creditors.title`)}
                    {...a11yProps(2)}
                  />
                  <Tab
                    style={{
                      backgroundColor: "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                    }}
                    label={context.t(`dashboard.birthDay.title`)}
                    {...a11yProps(3)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                }}
                value={value}
                index={0}
              >
                <Table
                  isExist={false}
                  isDashboard={true}
                  loaded={onlineAppointments.loaded}
                  headings={{
                    appointment_start_date: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.appointmentStartDate`
                      ),
                      style: { width: 150 },
                    },
                    customer_full_name: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerFullName`
                      ),
                      //with_photo: "customer.photo",
                      limited_line: 3,
                      style: { width: "200px" },
                    },
                    customer_phone: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerPhone`
                      ),
                    },
                    // "customer_type": {
                    //   label: context.t(`['appointments/online'].headings.customerType`),
                    //  },
                    "staff.full_name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.staffFullName`
                      ),
                      with_photo: "staff.detail.profile_photo",
                      limited_line: 1,
                    },
                    "service.name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.serviceName`
                      ),
                      limited_line: 1,
                    },
                    note: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.note`
                      ),
                      limited_line: 1,
                    },
                    "customer_statics.sumCol": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.statistics`
                      ),
                    },
                    _: { label: context.t(`component.actionHeadingText`) },
                  }}
                  rows={onlineAppointments.data}
                  replacements={replacements}
                  pagination={pagination}
                  noDataText={"Bekleyen randevu talebiniz bulunmamakta."}
                  buttons={[
                    {
                      // title: context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => approveSingleAppointmentRequest(row.id),
                    },
                    {
                      // title: context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => {
                        setDeleteId(row.id);
                        setDeleteConfirm(true);
                      },
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                }}
                value={value}
                index={1}
              >
                <Table
                  isExist={false}
                  loaded={openAppointments.loaded}
                  headings={{
                    date: {
                      label: context.t(
                        `dashboard.openAppointments.headings.date`
                      ),
                    },
                    customerFullName: {
                      label: context.t(
                        `dashboard.openAppointments.headings.customerFullName`
                      ),
                    },
                    serviceName: {
                      label: context.t(
                        `dashboard.openAppointments.headings.serviceName`
                      ),
                    },
                    products: {
                      label: context.t(
                        `dashboard.openAppointments.headings.products`
                      ),
                    },
                    totalPayment: {
                      label: context.t(
                        `dashboard.openAppointments.headings.totalPayment`
                      ),
                    },
                    _: { label: context.t(`component.actionHeadingText`) },
                  }}
                  rows={openAppointments.data}
                  replacements={replacements}
                  pagination={paginationOpenAppointments}
                  noDataText={context.t(`dashboard.appointmentAnalys.noDataText`)}
                  buttons={[
                    {
                      icon: "visibility",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) =>
                        (window.location.href = `/receipts/detail/${row.id}`),
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                }}
                value={value}
                index={2}
              >
                <Table
                  isExist={false}
                  loaded={creditors.loaded}
                  headings={{
                    customerFullName: {
                      label: context.t(
                        `dashboard.creditors.headings.customerFullName`
                      ),
                    },
                    type: {
                      label: context.t(`dashboard.creditors.headings.type`),
                    },
                    paymentDate: {
                      label: context.t(
                        `dashboard.creditors.headings.paymentDate`
                      ),
                    },
                    totalPayment: {
                      label: context.t(
                        `dashboard.creditors.headings.totalPayment`
                      ),
                    },
                  }}
                  rows={creditors.data}
                  replacements={replacements}
                  pagination={paginationCredits}
                  noDataText={context.t(`dashboard.creditors.noDataText`)}
                  buttons={[
                    {
                      // title: context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => approveSingleAppointmentRequest(row.id),
                    },
                    {
                      // title: context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => {
                        setDeleteConfirm(true);
                        setDeleteId(row.id);
                      },
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                }}
                value={value}
                index={3}
              >
                <Table
                  loaded={birthDay.loaded}
                  headings={{
                    customerFullName: {
                      label: context.t(
                        `dashboard.birthDay.headings.customerFullName`
                      ),
                    },
                    customer_phone: {
                      label: context.t(
                        `dashboard.birthDay.headings.customerPhone`
                      ),
                    },
                    birthDate: {
                      label: context.t(`dashboard.birthDay.headings.birthDate`),
                    },
                  }}
                  rows={birthDay.data}
                  replacements={replacements}
                  pagination={paginationBirthDay}
                  noDataText={context.t(`dashboard.birthDay.noDataText`)}
                />
              </TabPanel>
              {/*<FieldCard
                id="card-online_appointment_requests"
                style={{
                  height: "400px",
                  overflow: "auto",
                  padding: "0px 5px",
                }}
                cardTitle={context.t(
                  `dashboard.waitingAppointments.sectionHeading`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.online_appointment_request_card_description`
                )}
              >
                <Table
                  loaded={onlineAppointments.loaded}
                  headings={{
                    appointment_start_date: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.appointmentStartDate`
                      ),
                      style: { width: 150 },
                    },
                    customer_full_name: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerFullName`
                      ),
                      //with_photo: "customer.photo",
                      limited_line: 3,
                      style: { width: "200px" },
                    },
                    customer_phone: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerPhone`
                      ),
                    },
                    // "customer_type": {
                    //   label: context.t(`['appointments/online'].headings.customerType`),
                    //  },
                    "staff.full_name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.staffFullName`
                      ),
                      with_photo: "staff.detail.profile_photo",
                      limited_line: 1,
                    },
                    "service.name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.serviceName`
                      ),
                      limited_line: 1,
                    },
                    note: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.note`
                      ),
                      limited_line: 1,
                    },
                    "customer_statics.sumCol": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.statistics`
                      ),
                    },
                    _: { label: context.t(`component.actionHeadingText`) },
                  }}
                  rows={onlineAppointments.data}
                  replacements={replacements}
                  pagination={pagination}
                  noDataText={"Bekleyen randevu talebiniz bulunmamakta."}
                  buttons={[
                    {
                      // title: context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) =>
                        approveSingleAppointmentRequest(row.id),
                    },
                    {
                      // title: context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => {
                        setDeleteId(row.id);
                        setDeleteConfirm(true);  
                      }
                    },
                  ]}
                />
                </FieldCard>*/}
            </Column>
            {/* Randevu Istatikleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-4 mb-1">
              <FieldCard
                id="card-appointment_statistics"
                cardTitle={context.t(`dashboard.stats.appointment.heading`)}
                helperText={context.t(
                  `dashboard.cardDescription.appointment_statistic_card_description`
                )}
                style={{
                  height: "400px",
                  maxHeight: "500px",
                  overflow: "hidden",
                }}
              >
                <CustomDateRangeColumn
                  style={{ width: "100%" }}
                  setSelectedRange={setSelectedRangeAppointmentStats}
                  selectedRange={selectedRangeAppointmentStats}
                  onClickHandler={async (appointmentoffset) => {
                    await setOffsets({
                      ...offsets,
                      appointmentoffset,
                    });

                    await getAnalysisRI();
                    setIsSelectedRangeReceiptIncome(false);
                    setIsSelectedRangeAppointments(false);
                    setIsSelectedRangeAppointmentStats(true);
                  }}
                >
                  <Grid style={{ width: "100%", height: "100%" }}>
                    {appointmentZone.totalPercent !== 0 && (
                      <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CDoughnut
                            data={appointmentDatasets}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          ></CDoughnut>
                        </div>
                      </Column>
                    )}
                    <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <HorizontalBar
                          width={`${appointmentZone.from_staff}%`}
                          background="rgba(140, 15, 180, 0.5)"
                          label={`${appStatistics.from_staff}`}
                          title={context.t(`dashboard.appointmentAnalys.panel`)}
                        />
                        <HorizontalBar
                          width={`${appointmentZone.from_company}%`}
                          background="rgba(29, 139, 62, 0.5)"
                          label={`${appStatistics.from_company}`}
                          title={context.t(
                            `dashboard.appointmentAnalys.company`
                          )}
                        />
                        <HorizontalBar
                          width={`${appointmentZone.from_platform}%`}
                          background="#3189ec"
                          label={`${appStatistics.from_platform}`}
                          title={
                            <div>
                              {context.t(
                                `dashboard.appointmentAnalys.platform`
                              )}
                            </div>
                          }
                        />
                      </div>
                    </Column>
                  </Grid>
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>

            <AlertDialog
              title={context.t(`['appointments/online'].rejectConfirm.title`)}
              open={deleteConfirm}
              closeHandler={() => setDeleteConfirm(false)}
              buttons={[
                {
                  title: context.t(
                    `dashboard.onlineAppointments.rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => rejectSingleAppointmentRequest(deleteId),
                },
                {
                  title: context.t(
                    `dashboard.onlineAppointments.rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>
                  {context.t(
                    `dashboard.onlineAppointments.rejectConfirm.alertBoldText`
                  )}
                </b>
                <br />
                {context.t(
                  `dashboard.onlineAppointments.rejectConfirm.alertText`
                )}
              </Alert>
            </AlertDialog>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid>
            {/* Randevu Takvimi */}

            <Column className="xs-12 sm-12 md-12 lg-4 mb-1">
              <FieldCard
                id="card-appointments"
                cardTitle={context.t(
                  `dashboard.cardTitle.appointment_calender_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointment_calender_card_description`
                )}
                style={{
                  height: "330px",
                  overflowX: "auto",
                  background: "#FEF4EA",
                  position: "relative",
                }}
              >
                <CustomMUIDatePicker
                  autoOk
                  openTo="date"
                  variant="static"
                  orientation="landspace"
                  disableToolbar={true}
                  showTodayButton={true}
                  value={new Date()}
                  expanded={true}
                  background="#FEF4EA"
                  onChange={(selected_date) => {
                    const formattedDate =
                      selected_date._d instanceof Date
                        ? selected_date._d.toISOString()
                        : selected_date;

                    history.push({
                      pathname: "/appointments",
                      state: {
                        date: formattedDate,
                      },
                    });
                  }}
                />
              </FieldCard>
            </Column>
            {/* Randevu Ayrıntıları */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <FieldCard
                id="card-appointment_details"
                style={{ height: "330px", overflow: "scroll" }}
                cardTitle={context.t(
                  `dashboard.cardTitle.appointments_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointments_card_description`
                )}
              >
                <CustomDateRangeColumn
                  style={{ width: "100%" }}
                  /* sectionTitle={context.t(
                    `dashboard.stats.appointment.heading`
                  )} */
                  setSelectedRange={setSelectedRangeAppointments}
                  selectedRange={selectedRangeAppointments}
                  onClickHandler={async (appointmentoffset) => {
                    await setOffsets({
                      ...offsets,
                      appointmentoffset,
                    });

                    await getAnalysisRA();
                    setIsSelectedRangeReceiptIncome(false);
                    setIsSelectedRangeAppointments(true);
                    setIsSelectedRangeAppointmentStats(false);
                  }}
                >
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm", color: "purple" }}
                    title={context.t(
                      `dashboard.stats.appointment.createdAppointments`
                    )}
                    value={stats.appointments.created_appointments}
                  />
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm_on", color: "green" }}
                    title={context.t(
                      `dashboard.stats.appointment.successAppointments`
                    )}
                    value={stats.appointments.succeed_appointments}
                  />
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm_off", color: "red" }}
                    title={context.t(
                      `dashboard.stats.appointment.closedAppointments`
                    )}
                    value={stats.appointments.closed_appointments}
                  />
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
            {/* Randevu Istatikleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-4 mb-1">
              <FieldCard
                id="card-appointment_statistics"
                cardTitle={context.t(`dashboard.stats.appointment.heading`)}
                helperText={context.t(
                  `dashboard.cardDescription.appointment_statistic_card_description`
                )}
                style={{
                  height: "400px",
                  maxHeight: "500px",
                  overflow: "hidden",
                }}
              >
                <CustomDateRangeColumn
                  style={{ width: "100%" }}
                  setSelectedRange={setSelectedRangeAppointmentStats}
                  selectedRange={selectedRangeAppointmentStats}
                  onClickHandler={async (appointmentoffset) => {
                    await setOffsets({
                      ...offsets,
                      appointmentoffset,
                    });

                    await getAnalysisRI();
                    setIsSelectedRangeReceiptIncome(false);
                    setIsSelectedRangeAppointments(false);
                    setIsSelectedRangeAppointmentStats(true);
                  }}
                >
                  <Grid style={{ width: "100%", height: "100%" }}>
                    {appointmentZone.totalPercent !== 0 && (
                      <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CDoughnut
                            data={appointmentDatasets}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          ></CDoughnut>
                        </div>
                      </Column>
                    )}
                    <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <HorizontalBar
                          width={`${appointmentZone.from_staff}%`}
                          background="rgba(140, 15, 180, 0.4)"
                          label={`${appStatistics.from_staff}`}
                          title={context.t(`dashboard.appointmentAnalys.panel`)}
                        />
                        <HorizontalBar
                          width={`${appointmentZone.from_company}%`}
                          background="rgba(29, 139, 62, 0.4)"
                          label={`${appStatistics.from_company}`}
                          title={context.t(
                            `dashboard.appointmentAnalys.company`
                          )}
                        />
                        <HorizontalBar
                          width={`${appointmentZone.from_platform}%`}
                          background="rgba(49, 137, 236, 0.6)"
                          label={`${appStatistics.from_platform}`}
                          title={
                            <div>
                              {context.t(
                                `dashboard.appointmentAnalys.platform`
                              )}
                            </div>
                          }
                        />
                      </div>
                    </Column>
                  </Grid>
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
          </Grid>

          {/* Last Row */}
          <Grid className="mb-2">
            {/* Online Randevu Talepleri */}
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mb-1">
              <FieldCard
                id="card-online_appointment_requests"
                style={{
                  height: "400px",
                  overflow: "auto",
                  padding: "0px 5px",
                }}
                cardTitle={context.t(
                  `dashboard.waitingAppointments.sectionHeading`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.online_appointment_request_card_description`
                )}
              >
                <Table
                  isExist={false}
                  loaded={onlineAppointments.loaded}
                  headings={{
                    appointment_start_date: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.appointmentStartDate`
                      ),
                      style: { width: 150 },
                    },
                    customer_full_name: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerFullName`
                      ),
                      //with_photo: "customer.photo",
                      limited_line: 3,
                      style: { width: "200px" },
                    },
                    customer_phone: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerPhone`
                      ),
                    },
                    // "customer_type": {
                    //   label: context.t(`['appointments/online'].headings.customerType`),
                    //  },
                    "staff.full_name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.staffFullName`
                      ),
                      with_photo: "staff.detail.profile_photo",
                      limited_line: 1,
                    },
                    "service.name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.serviceName`
                      ),
                      limited_line: 1,
                    },
                    note: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.note`
                      ),
                      limited_line: 1,
                    },
                    "customer_statics.sumCol": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.statistics`
                      ),
                    },
                    _: { label: context.t(`component.actionHeadingText`) },
                  }}
                  rows={onlineAppointments.data}
                  replacements={replacements}
                  pagination={pagination}
                  noDataText={context.t(`dashboard.waitingAppointments.noDataText`)}
                  buttons={[
                    {
                      // title: context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => approveSingleAppointmentRequest(row.id),
                    },
                    {
                      // title: context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => {
                        setDeleteConfirm(true);
                        setDeleteId(row.id);
                      },
                    },
                  ]}
                />
              </FieldCard>
            </Column>

            <AlertDialog
              title={context.t(`['appointments/online'].rejectConfirm.title`)}
              open={deleteConfirm}
              closeHandler={() => setDeleteConfirm(false)}
              buttons={[
                {
                  title: context.t(
                    `dashboard.onlineAppointments.rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => rejectSingleAppointmentRequest(deleteId),
                },
                {
                  title: context.t(
                    `dashboard.onlineAppointments.rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>
                  {context.t(
                    `dashboard.onlineAppointments.rejectConfirm.alertBoldText`
                  )}
                </b>
                <br />
                {context.t(
                  `dashboard.onlineAppointments.rejectConfirm.alertText`
                )}
              </Alert>
            </AlertDialog>
          </Grid>
        </>
      );
    }
  };

  const Favorites = (props) => {
    const handleOnDragEnd = (result) => {
      if (result.destination !== null) {
        const items = Array.from(favoritePages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFavoritePages([...items]);
        updateFavoritePages();
      }
    };

    const updateFavoritePages = () => {
      Axios.put(`${context.api_endpoint}/staff/favorite/save`, {
        data: favoritePages.toString().concat(","),
      })
        .then((response) => {})
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    };

    const boxColors = [
      "#ECFDED",
      "#E9F8FD",
      "#F0EEFB",
      "#FAEFED",
      "#FEF4EA",
      "#EDF1FC",
      "#FDF3FC",
    ];
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters" direction="horizontal">
          {(provided) => (
            <FavoritesContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {favoritePages.map((value, index) => {
                const tab = favoritePage(value);
                if (tab) {
                  return (
                    <Draggable key={value} draggableId={value} index={index}>
                      {(provided) => (
                        <FavoriteItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          favoritePagesCount={favoritePages.length}
                        >
                          <FavoriteBox
                            backgroundColor={boxColors[index]}
                            onClick={() => {
                              history.push(tab.path);
                            }}
                          >
                            {<tab.icon fontSize="large" />}
                            {tab.name}
                          </FavoriteBox>
                        </FavoriteItem>
                      )}
                    </Draggable>
                  );
                }
                return null;
              })}
              {provided.placeholder}
            </FavoritesContainer>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const FavoriteList = Favorites;

  return (
    <AuthContainer>
      <Grid id="favorites">
        <FavoriteList />
      </Grid>
      <DashboardFields />
    </AuthContainer>
  );
};

export default DashboardIndex;

const FavoritesContainer = styled.ul`
  width: 100%;
  padding: 15px;
  display: flex;
  gap: 0.5rem;
  align-items: stretch;
  list-style-type: none;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 576px) {
    /* width */
    ::-webkit-scrollbar {
      display: block;
      height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

const FavoriteItem = styled.li`
  min-width: 150px;

  @media only screen and (min-width: 1190px) {
    flex: ${(props) => (props.favoritePagesCount > 3 ? 1 : 0)};
  }
`;

const FavoriteBox = styled.div`
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${(props) => props.backgroundColor};
  box-shadow: 0 5px 15px #a5a5a5ad;

  @media only screen and (max-width: 1190px) {
    margin: 5px;
  }
`;

const Report = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const DashboardBox = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 100px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  padding: 16px;
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin: 0 0 4px;
  color: var(--dark-primary);
`;
