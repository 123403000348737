import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import moment from "moment";
import { Grid, Column } from "../../theme/Grid";
import { toast } from "react-toastify";
import Button from "../../theme/Button";
import Select from "../../theme/CustomMUI/Select";
import Input from "../../theme/CustomMUI/Input";
import AppContext from "../../context/store";
import CSwitch from "../../theme/CustomMUI/CSwitch";
import TimeSelect from "../../theme/TimeSelect";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import Switch from "@material-ui/core/Switch";
import AutocompleteInput from "../../theme/CustomMUI/AutoComplete";
import CustomerAddDialog from "./CustomerAddDialog";
import styled from "styled-components";

const repetition = [
  {
    id: 1,
    name: "Her gün",
    count: 1,
  },
  {
    id: 2,
    name: "2 günde bir",
    count: 2,
  },
  {
    id: 3,
    name: "3 günde bir",
    count: 3,
  },
  {
    id: 4,
    name: "4 günde bir",
    count: 4,
  },
  {
    id: 5,
    name: "5 günde bir",
    count: 5,
  },
  {
    id: 6,
    name: "Haftada bir",
    count: 7,
  },
  {
    id: 7,
    name: "2 haftada bir",
    count: 14,
  },
  {
    id: 8,
    name: "3 haftada bir",
    count: 21,
  },
  {
    id: 9,
    name: "4 haftada bir",
    count: 28,
  },
  {
    id: 10,
    name: "Her ay",
    count: 30,
  },
  {
    id: 11,
    name: "45 günde bir",
    count: 45,
  },
  {
    id: 12,
    name: "2 ayda bir",
    count: 60,
  },
  {
    id: 13,
    name: "3 ayda bir",
    count: 90,
  },
  {
    id: 14,
    name: "6 ayda bir",
    count: 180,
  },
];

function AddAppointmentDialog({
  selectedDate,
  selectedTimeClockOffEnd,
  weeklyAppointments,
  weeklyAppointmentsSelectedDate,
  getAppointmentsForSelectBox,
  getAppointmentClockTime,
  setCreateAppointmentModal,
  staffs,
  selectedStaff,
  setSelectedStaff,
  createAppointmentModal,
  selectedTime,
  setSelectedTime,
  hours,
  setSelectedTimeClockOffEnd,
  selectedTimeClockOffStart,
  setSelectedTimeClockOffStart,
}) {
  const [turnOffAllDay, setTurnOffAllDay] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [newAppointment, setNewAppointment] = useState({ services: {} });
  const [onlineAppointment, setOnlineAppointment] = useState(true);
  const [
    createAppointmentModalCollective,
    setCreateAppointmentModalCollective,
  ] = useState(false);
  const [createAppointmentModalClock, setCreateAppointmentModalClock] =
    useState(false);
  const [repetitionFrequencyModal, setRepetitionFrequencyModal] =
    useState(false);
  const [customerAddModal, setCustomerAddModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [sendSms, setSendSms] = useState(true);
  const [serviceOrPackage, setServiceOrPackage] = useState(1);

  const [selectedService, setSelectedService] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [note, setNote] = useState(null);
  const [repetitionCount, setRepetitionCount] = useState(0);
  const [selectedRepetition, setSelectedRepetition] = useState(null);

  const context = useContext(AppContext);

  // TODO: Close modal when user clicks outside of it
  const closeAppointmentClockTime = async () => {
    try {
      const appointmentDateClockOffStart =
        moment(selectedDate).format("YYYY-MM-DD") +
        "T" +
        selectedTimeClockOffStart +
        ":00Z";
      const appointmentDateClockOffEnd =
        moment(selectedDate).format("YYYY-MM-DD") +
        "T" +
        selectedTimeClockOffEnd +
        ":00Z";

      const { data } = await Axios.post(
        `${context.api_endpoint}/company/appointment/staff/active/date`,
        {
          staff_id: selectedStaff.id,
          start_hour: appointmentDateClockOffStart,
          end_hour: appointmentDateClockOffEnd,
          all_hours_closed: turnOffAllDay,
          note: note || "",
          repetition_frequency: selectedRepetition
            ? selectedRepetition.count
            : repetition[0].count,
          repetition_count: repetitionCount ? parseInt(repetitionCount) : 0,
          is_platform_included: onlineAppointment,
        }
      );
      if (data.status === 200) {
        toast.success("Başarıyla eklendi.");
        setCreateAppointmentModalClock(false);
        setTurnOffAllDay(false);
        setRepetitionFrequencyModal(false);
        getAppointmentClockTime();
        setSelectedRepetition(null);
      } else {
        toast.error("Bir hata oluştu.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
      } else {
        toast.warning(error.response.data.message);
      }
    } finally {
      setNote("");
    }
  };

  //TODO: Service Modal
  const getReqArrays = async () => {
    try {
      const response = await Axios.get(
        `${context.api_endpoint}/company/receipt/index`
      );
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error.response.data.message);
      } else {
        toast.warning(error.response.data.message);
      }
    }
  };

  const insertAppointment = async () => {
    let appointment_date = new Date(
      weeklyAppointments ? weeklyAppointmentsSelectedDate : selectedDate
    );
    let appointment_time = selectedTime.split(":");
    if (appointment_time[0] > 6) {
      appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${
        appointment_time[0]
      }:${appointment_time[1]}:00Z`;
    } else {
      appointment_date = `${moment(appointment_date)
        .add(1, "day")
        .format("YYYY-MM-DD")}T${appointment_time[0]}:${
        appointment_time[1]
      }:00Z`;
    }

    await Axios.post(`${context.api_endpoint}/company/appointment/insert`, {
      staff_id: selectedStaff.id,
      customer_id: selectedCustomer.id,
      [serviceOrPackage === 1 ? "service_id" : "packet_id"]:
        serviceOrPackage === 1 ? selectedService.id : selectedPackage.id,
      send_sms: selectedCustomer
        ? selectedCustomer.send_sms === false
          ? false
          : sendSms
        : sendSms,
      appointment_date,
      note,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.insertToast`));
          getAppointmentsForSelectBox();
          getAppointmentClockTime();
          setCreateAppointmentModal(false);
          setCreateAppointmentModalClock(false);
          setCreateAppointmentModalCollective(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        getAppointmentsForSelectBox();
        getAppointmentClockTime();
        setCreateAppointmentModal(false);
        setCreateAppointmentModalClock(false);
        setCreateAppointmentModalCollective(false);
        setSelectedPackage(null);
        setSelectedService(null);
        setSelectedCustomer(null);
        setSendSms(true);
        setNote("");
      });
  };

  const bulkInsertAppointment = async () => {
    let appointment_date = new Date(
      weeklyAppointments ? weeklyAppointmentsSelectedDate : selectedDate
    );
    let appointment_time = selectedTime.split(":");
    appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${
      appointment_time[0]
    }:${appointment_time[1]}:00Z`;

    await Axios.post(
      `${context.api_endpoint}/company/appointment/bulk/insert`,
      {
        customer_id: selectedCustomer.id,
        appointment_date,
        services: [
          ...Object.keys(newAppointment.services)
            .filter((key) => newAppointment.services[key] !== undefined)
            .map((item) =>
              Object.assign({ ...newAppointment.services[item] }, {})
            ),
        ],
        note,
        send_sms: selectedCustomer
          ? selectedCustomer.send_sms === false
            ? false
            : sendSms
          : sendSms,
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.insertToast`));
          getAppointmentsForSelectBox();
          setCreateAppointmentModal(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        setSelectedCustomer(null);
        setNote("");
      });
  };

  const addServiceInPackageSale = () => {
    setNewAppointment((prevAppointment) => ({
      ...prevAppointment,
      services: {
        ...prevAppointment.services,
        [Object.keys(newAppointment.services).length + 1]: {
          service_id: null,
          staff_id: context.state.user.id,
        },
      },
    }));
  };

  const deleteServiceInPackageSale = (key) => {
    setNewAppointment((prevAppointment) => ({
      ...prevAppointment,
      services: {
        ...prevAppointment.services,
        [key]: undefined,
      },
    }));
  };

  const fieldChanger = (section, key, payload) => {
    setNewAppointment((prevAppointment) => ({
      ...prevAppointment,
      [section]: {
        ...prevAppointment[section],
        [key]: {
          ...prevAppointment[section][key],
          ...payload,
        },
      },
    }));
  };

  const searchServicesByKey = async (key, selectedStaffId) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/services/filter`, {
      params: {
        id: selectedStaffId
          ? selectedStaffId
          : selectedStaff
          ? selectedStaff.id
          : null,
        key: key === " " ? "" : key,
        paginate: 1,
      },
    })
      .then(({ data }) => {
        const services = data.data
          .filter(
            (m) => m && m.is_passive !== undefined && m.is_passive === false
          )
          .map((service) => {
            if (
              service.category_name !== null &&
              service.category_name.toLowerCase().includes("erkek")
            ) {
              service.name += " (E)";
            } else if (
              service.category_name !== null &&
              service.category_name.toLowerCase().includes("kadın")
            ) {
              service.name += " (K)";
            }
            return service;
          });
        arr = [...services];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr;
  };

  const searchPackagesById = async (key) => {
    let arr = [];
    await Axios.get(
      `${context.api_endpoint}/company/packet/byuser/${
        selectedStaff ? selectedStaff.id : null
      }`,
      {
        params: {
          page: 1,
        },
      }
    )
      .then(({ data }) => {
        const packages = data.data
          .filter((m) => m.packet !== null)
          .map((p) => {
            if (
              p.packet.name !== null &&
              p.packet.name.toLowerCase().includes("erkek")
            ) {
              p.packet.name += " (E)";
            } else if (
              p.packet.name !== null &&
              p.packet.name.toLowerCase().includes("kadın")
            ) {
              p.packet.name += " (K)";
            }
            return p.packet;
          });
        arr = [...packages];
      })
      .catch((e) => {
        console.log(e);
      });
    return arr;
  };

  const searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    })
      .then(({ data }) => {
        arr = [...data.data.records];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
        send_sms: item.send_sms,
        description: item.description,
      };
    });
  };
  useEffect(() => {
    if (selectedPackage && selectedPackage.packet_details) {
      selectedPackage.packet_details.forEach((m) => {
        if (m?.service?.is_passive === true) {
          toast.warn(context.t("appointments.create.select_package_alert"));
          setSelectedPackage(null);
        }
      });
    }
  }, [selectedPackage]);

  return (
    <>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="md"
        fullWidth={true}
        className="bringBack"
        buttonDisabledTimeout
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 className="MuiTypography-root MuiTypography-h6">
                {context.t(`appointments.create.title`)}
              </h2>
              <h5
                style={{
                  color: "#4293ed",
                  padding: "8px",
                }}
              >
                {selectedTime
                  ? selectedTime.split(":")[0] > 6
                    ? moment(selectedDate)
                        .set("hours", selectedTime.split(":")[0])
                        .set("minutes", selectedTime.split(":")[1])
                        .format("DD MMMM dddd YYYY - HH:mm")
                    : moment(selectedDate)
                        .add("1", "day")
                        .set("hours", selectedTime.split(":")[0])
                        .set("minutes", selectedTime.split(":")[1])
                        .format("DD MMMM dddd YYYY - HH:mm")
                  : null}
              </h5>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button
                  title={context.t(`appointments.create.groupAppointment`)}
                  className="bg-purple-opacity"
                  fullWidth
                  textColor="purple"
                  onClick={() => {
                    setCreateAppointmentModal(false);
                    setCreateAppointmentModalCollective(true);
                    setSelectedStaff(null);
                    getReqArrays();
                  }}
                />
              </div>
              <div>
                <Button
                  title={context.t(`appointments.create.clockOff`)}
                  className="bg-grey-opacity ml-1"
                  textColor="grey"
                  fullWidth
                  onClick={() => {
                    setCreateAppointmentModal(false);
                    setCreateAppointmentModalClock(true);
                  }}
                />
              </div>
              <div>
                <Button
                  icon="add"
                  title={context.t(
                    `appointments.create.createNewCustomerButtonTitle`
                  )}
                  backgroundColor="primary-opacity"
                  className="ml-2"
                  textColor="primary"
                  fullWidth
                  onClick={() => {
                    setCreateAppointmentModal(false);
                    setCustomerAddModal(true);
                  }}
                />
              </div>
            </div>
          </div>
        }
        open={createAppointmentModal}
        closeHandler={() => {
          setCreateAppointmentModal(false);
          setCreateAppointmentModalClock(false);
          setCreateAppointmentModalCollective(false);
          setServiceOrPackage(1);
          setSelectedCustomer(null);
          setSelectedService(null);
          setSelectedPackage(null);
          setSendSms(true);
          setNote("");
        }}
        buttons={[
          {
            title: context.t(`appointments.create.completeButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            disabledHandleClose: true,
            onClick: () => {
              (selectedService || selectedPackage) && selectedCustomer
                ? insertAppointment()
                : toast.warning(
                    context.t(`appointments.create.onWarningToast`)
                  );
            },
          },
          {
            backgroundColor: "grey-opacity",
            title: context.t(`appointments.create.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-6">
            <Grid className="no-gutters-column">
              {selectedStaff && (
                <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
                  <Select
                    label={context.t(
                      `appointments.create.selectedStaffInputLabel`
                    )}
                    items={staffs}
                    labelKey="full_name"
                    valueKey="id"
                    selected={
                      selectedStaff !== null ? `${selectedStaff.id}` : ""
                    }
                    returnFullObject
                    handler={(selectedStaffElement) => {
                      setSelectedStaff(selectedStaffElement);
                      setSelectedService(null);
                      setSelectedPackage(null);
                    }}
                  />
                </Column>
              )}
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    gap: "1px",
                    alignItems: "center",
                  }}
                >
                  {context.t(`appointments.create.selecting_service`)}
                  <Switch
                    checked={serviceOrPackage === 2 ? true : false}
                    onChange={(e) =>
                      setServiceOrPackage(e.target.checked ? 2 : 1)
                    }
                    color="default"
                    inputProps={{
                      "aria-label": "checkbox with default color",
                    }}
                  />
                  {context.t(`appointments.create.selecting_package`)}
                </div>
                {serviceOrPackage === 1 && (
                  <AutocompleteInput
                    label={context.t(
                      `appointments.create.selectedServiceInputLabel`
                    )}
                    labelKey="name"
                    valueKey="id"
                    selected={selectedService !== null ? selectedService : ""}
                    onRemove={() => {
                      setSelectedService(null);
                      searchServicesByKey("");
                    }}
                    returnFullObject
                    selectedHandler={(selectedServiceElement) => {
                      setSelectedService(selectedServiceElement);
                    }}
                    asyncDataService={(keyword) =>
                      keyword.length > 0 ? searchServicesByKey(keyword) : []
                    }
                  />
                )}
                {serviceOrPackage === 2 && (
                  <AutocompleteInput
                    label={context.t(
                      `appointments.create.selectedPackageInputLabel`
                    )}
                    labelKey="name"
                    valueKey="id"
                    selected={selectedPackage !== null ? selectedPackage : ""}
                    onRemove={() => {
                      setSelectedPackage(null);
                      searchPackagesById("");
                    }}
                    returnFullObject
                    selectedHandler={(selectedPackageElement) => {
                      setSelectedPackage(selectedPackageElement);
                    }}
                    asyncDataService={(keyword) =>
                      keyword.length > 0 ? searchPackagesById(keyword) : []
                    }
                  />
                )}
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
                <AutocompleteInput
                  autoHighlight
                  label={context.t(
                    `appointments.create.selectedCustomerInputLabel`
                  )}
                  placeholder={context.t(
                    `appointments.create.selectedCustomerInputPlaceholder`
                  )}
                  labelKey="full_name"
                  valueKey="id"
                  selected={selectedCustomer !== null ? selectedCustomer : ""}
                  onRemove={() => {
                    setSelectedCustomer(null);
                  }}
                  returnFullObject
                  selectedHandler={(selectedCustomerElement) => {
                    setSelectedCustomer(selectedCustomerElement);
                    setSendSms(selectedCustomerElement.send_sms);
                  }}
                  asyncDataService={(keyword) => searchCustomerByKey(keyword)}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12">
                <Input
                  label={context.t(`appointments.create.noteInputLabel`)}
                  multiline
                  rows={1}
                  onChange={(e) => setNote(e.target.value.trim())}
                />
              </Column>
            </Grid>
          </Column>
          <Column className="xs-12 sm-12 md-6">
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelHour`)}
                  type="hour"
                  value={
                    selectedTime !== null ? selectedTime.split(":")[0] : ""
                  }
                  fullWidth={true}
                  size="medium"
                  companyShiftTime={true}
                  startHour={context.state.company_shift.start_hour}
                  endHour={
                    context.state.company_shift.end_hour.split(":")[1] === "00"
                      ? context.state.company_shift.end_hour
                      : `${
                          parseInt(
                            context.state.company_shift.end_hour.split(":")[0]
                          ) + 1
                        }:00`
                  }
                  handler={(time) => {
                    // After 7 PM restrict Minute selection to 30 max
                    setSelectedTime(
                      moment(selectedDate)
                        .set("hours", time)
                        .set("minutes", selectedTime.split(":")[1])
                        .format("HH:mm")
                    );
                  }}
                />
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelMinute`)}
                  type="minute"
                  value={
                    selectedTime !== null ? selectedTime.split(":")[1] : ""
                  }
                  selectedHour={
                    selectedTime !== null ? selectedTime.split(":")[1] : ""
                  }
                  lastHour={hours[hours.length - 1]}
                  fullWidth={true}
                  size="medium"
                  handler={(time) => {
                    setSelectedTime(
                      moment(selectedDate)
                        .set("hours", selectedTime.split(":")[0])
                        .set("minutes", time)
                        .format("HH:mm")
                    );
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-2">
                <Input
                  disabled
                  multiline
                  rows={4}
                  label={context.t(`appointments.create.description`)}
                  value={
                    selectedCustomer && selectedDescription != null
                      ? selectedDescription
                      : "-"
                  }
                />
              </Column>

              <Column className="xs-12 sm-12 md-12 mt-1">
                <CSwitch
                  label={context.t(`appointments.create.sendSmsInputLabel`)}
                  checked={sendSms}
                  color="primary"
                  disabled={
                    selectedCustomer && selectedCustomer.send_sms === false
                  }
                  onChange={(checked) => setSendSms(checked.target.checked)}
                />
              </Column>
            </Grid>
          </Column>
        </Grid>
      </AlertDialog>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="sm"
        fullWidth={true}
        className="bringBack"
        buttonDisabledTimeout
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <h2 className="MuiTypography-root MuiTypography-h6">
                {context.t(`appointments.create.clockOff`)}
              </h2>
            </div>
            <div>
              {turnOffAllDay === false ? (
                <Button
                  title={"Tüm Günü Kapat"}
                  className="bg-blue-opacity"
                  fullWidth
                  onClick={() => setTurnOffAllDay(true)}
                />
              ) : (
                <Button
                  title={"İptal"}
                  className="bg-red-opacity"
                  textColor="red"
                  fullWidth
                  onClick={() => setTurnOffAllDay(false)}
                />
              )}
            </div>
            <div>
              {repetitionFrequencyModal === false ? (
                <Button
                  title={"Tekrarlayan"}
                  className="bg-blue-opacity"
                  fullWidth
                  onClick={() => setRepetitionFrequencyModal(true)}
                />
              ) : (
                <Button
                  title={"İptal"}
                  className="bg-red-opacity"
                  textColor="red"
                  fullWidth
                  onClick={() => setRepetitionFrequencyModal(false)}
                />
              )}
            </div>
          </div>
        }
        open={createAppointmentModalClock}
        closeHandler={() => {
          setCreateAppointmentModalClock(false);
          setTurnOffAllDay(false);
          setRepetitionFrequencyModal(false);
          setNote("");
        }}
        buttons={[
          {
            title: context.t(`appointments.create.completeButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            disabledHandleClose: true,
            onClick: () => {
              if (
                parseInt(
                  context.state.company_shift.end_hour.replace(":", "")
                ) <
                parseInt(
                  context.state.company_shift.start_hour.replace(":", "")
                )
              ) {
                if (
                  (parseInt(
                    context.state.company_shift.start_hour.replace(":", "")
                  ) <=
                    parseInt(selectedTimeClockOffStart.replace(":", ""))) &
                  (parseInt(
                    context.state.company_shift.end_hour.replace(":", "")
                  ) +
                    2400 >=
                    parseInt(selectedTimeClockOffEnd.replace(":", "")))
                ) {
                  selectedStaff.name
                    ? closeAppointmentClockTime()
                    : toast.warning("Personel Seçimi Yapınız.");
                } else {
                  toast.warning(
                    "Seçilen saatler işletme mesai saatleri içerisinde olmalıdır."
                  );
                }
              } else {
                if (
                  (parseInt(
                    context.state.company_shift.start_hour.replace(":", "")
                  ) <=
                    parseInt(selectedTimeClockOffStart.replace(":", ""))) &
                  (parseInt(
                    context.state.company_shift.end_hour.replace(":", "")
                  ) >=
                    parseInt(selectedTimeClockOffEnd.replace(":", "")))
                ) {
                  selectedStaff.name
                    ? closeAppointmentClockTime()
                    : toast.warning("Personel Seçimi Yapınız.");
                } else {
                  toast.warning(
                    "Seçilen saatler işletme mesai saatleri içerisinde olmalıdır."
                  );
                }
              }
            },
          },
          {
            title: context.t(`appointments.create.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setCreateAppointmentModalClock(false);
            },
          },
        ]}
      >
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
            <Select
              label={context.t(`appointments.create.selectedStaffInputLabel`)}
              items={staffs}
              labelKey="full_name"
              valueKey="id"
              selected={selectedStaff !== null ? `${selectedStaff.id}` : ""}
              returnFullObject
              handler={(selectedStaffElement) =>
                setSelectedStaff(selectedStaffElement)
              }
            />
          </Column>
        </Grid>
        {turnOffAllDay === false ? (
          <>
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelHour`)}
                  type="hour"
                  value={
                    selectedTimeClockOffStart !== null
                      ? selectedTimeClockOffStart.split(":")[0]
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  startHour={context.state.company_shift.start_hour}
                  endHour={context.state.company_shift.end_hour}
                  handler={(time) => {
                    // After 7 PM restrict Minute selection to 30 max

                    setSelectedTimeClockOffStart(
                      moment(selectedDate)
                        .set("hours", time)
                        .set("minutes", selectedTimeClockOffStart.split(":")[1])
                        .format("HH:mm")
                    );
                  }}
                />
                <TimeSelect
                  label={context.t(`['settings/hours'].startInputLabelMinute`)}
                  type="minute"
                  value={
                    selectedTimeClockOffStart !== null
                      ? selectedTimeClockOffStart.split(":")[1]
                      : ""
                  }
                  selectedHour={selectedTimeClockOffStart}
                  lastHour={hours[hours.length]}
                  fullWidth={true}
                  size="medium"
                  handler={(time) => {
                    setSelectedTimeClockOffStart(
                      moment(selectedDate)
                        .set("hours", selectedTimeClockOffStart.split(":")[0])
                        .set("minutes", time)
                        .format("HH:mm")
                    );
                  }}
                />
              </Column>
            </Grid>
            <Grid className="no-gutters-row mt-2">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-12 flex">
                <TimeSelect
                  label={context.t(`['settings/hours'].endInputLabelHour`)}
                  type="hour"
                  value={
                    selectedTimeClockOffEnd !== null
                      ? selectedTimeClockOffEnd.split(":")[0]
                      : ""
                  }
                  fullWidth={true}
                  size="medium"
                  startHour={context.state.company_shift.start_hour}
                  endHour={context.state.company_shift.end_hour}
                  handler={(time) => {
                    // After 7 PM restrict Minute selection to 30 max

                    setSelectedTimeClockOffEnd(
                      moment(selectedDate)
                        .set("hours", time)
                        .set("minutes", selectedTimeClockOffEnd.split(":")[1])
                        .format("HH:mm")
                    );
                  }}
                />
                <TimeSelect
                  label={context.t(`['settings/hours'].endInputLabelMinute`)}
                  type="minute"
                  value={
                    selectedTimeClockOffEnd !== null
                      ? selectedTimeClockOffEnd.split(":")[1]
                      : ""
                  }
                  selectedHour={selectedTimeClockOffEnd}
                  lastHour={hours[hours.length]}
                  fullWidth={true}
                  size="medium"
                  handler={(time) => {
                    setSelectedTimeClockOffEnd(
                      moment(selectedDate)
                        .set("hours", selectedTimeClockOffEnd.split(":")[0])
                        .set("minutes", time)
                        .format("HH:mm")
                    );
                  }}
                />
              </Column>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid className="no-gutters-row mt-2">
          <Column className="xs-12 sm-12 md-6 mb-2 mt-2">
            <CSwitch
              label={"Online Randevu Alımını Kapat"}
              checked={onlineAppointment}
              color="primary"
              onChange={(onlineAppointmentElement) => {
                setOnlineAppointment(onlineAppointmentElement.target.checked);
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-6">
            <Input
              label={context.t(`appointments.create.noteInputLabel`)}
              multiline
              rows={3}
              onChange={(e) => setNote(e.target.value.trim())}
            />
          </Column>
        </Grid>
        {repetitionFrequencyModal ? (
          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-6">
              <Select
                label={"Tekrar Sıklığı"}
                items={repetition}
                labelKey="name"
                valueKey="id"
                selected={
                  selectedRepetition !== null
                    ? `${selectedRepetition.id}`
                    : repetition[0].id
                }
                returnFullObject
                handler={(selectedRepetitionElement) =>
                  setSelectedRepetition(selectedRepetitionElement)
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-6">
              <Input
                label={"Tekrar Sayısı"}
                type="number"
                onChange={(e) => setRepetitionCount(e.target.value)}
              />
            </Column>
          </Grid>
        ) : (
          <></>
        )}
      </AlertDialog>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="md"
        fullWidth={true}
        className="bringBack"
        buttonDisabledTimeout
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2 className="MuiTypography-root MuiTypography-h6">
                {context.t(`appointments.create.groupAppointment`)}
              </h2>
              <h5
                style={{
                  color: "#4293ed",
                }}
              >
                {selectedTime
                  ? moment(selectedDate)
                      .set("hours", selectedTime.split(":")[0])
                      .set("minutes", selectedTime.split(":")[1])
                      .format("DD MMMM dddd YYYY - HH:mm")
                  : null}
              </h5>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  title={context.t(
                    `appointments.create.addEmployeeButtonTitle`
                  )}
                  className="bg-green-opacity"
                  textColor="green"
                  fullWidth
                  onClick={() => addServiceInPackageSale()}
                />
              </div>
              <div>
                <Button
                  icon="add"
                  title={context.t(
                    `appointments.create.createNewCustomerButtonTitle`
                  )}
                  backgroundColor="primary-opacity"
                  textColor="primary"
                  fullWidth
                  className="ml-1"
                  onClick={() => {
                    setCreateAppointmentModal(false);
                    setCustomerAddModal(true);
                  }}
                />
              </div>
            </div>
          </div>
        }
        open={createAppointmentModalCollective}
        closeHandler={() => {
          setCreateAppointmentModalCollective(false);
          setNote("");
        }}
        buttons={[
          {
            title: context.t(`appointments.create.completeButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            disabledHandleClose: true,
            onClick: () => {
              selectedCustomer &&
              Object.keys(newAppointment.services).filter(
                (key) => newAppointment.services[key] !== undefined
              ).length >= 2
                ? bulkInsertAppointment()
                : toast.warning(context.t(`appointments.create.noDataText`));
              setCreateAppointmentModalCollective(false);
            },
          },
          {
            title: context.t(`appointments.create.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
            backgroundColor: "grey-opacity",
            onClick: () => {
              setCreateAppointmentModalCollective(false);
            },
          },
        ]}
      >
        <>
          {Object.keys(newAppointment.services).filter(
            (key) => newAppointment.services[key] !== undefined
          ).length > 0 ? (
            Object.keys(newAppointment.services)
              .filter((key) => newAppointment.services[key] !== undefined)
              .map((item, index) => (
                <Grid key={index} className="no-gutters-row">
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-5">
                    <Select
                      label={context.t(
                        `['packages/sales/add'].staffInputLabel`
                      )}
                      items={staffs}
                      labelKey="full_name"
                      valueKey="id"
                      selected={newAppointment.services[item].staff_id ?? null}
                      handler={(staff_id) => {
                        fieldChanger("services", item, {
                          staff_id: parseInt(staff_id),
                        });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                    <AutocompleteInput
                      label={context.t(
                        `appointments.create.selectedServiceInputLabel`
                      )}
                      labelKey="name"
                      valueKey="id"
                      selected={selectedService !== null ? selectedService : ""}
                      onRemove={() => setSelectedService(null)}
                      returnFullObject
                      selectedHandler={(selected_service) => {
                        fieldChanger("services", item, {
                          service_id: parseInt(selected_service.id),
                        });
                      }}
                      asyncDataService={(keyword) =>
                        keyword.length > 0
                          ? searchServicesByKey(
                              keyword,
                              newAppointment.services[item].staff_id
                            )
                          : []
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-1">
                    <Button
                      icon="delete_forever"
                      iconSize={35}
                      backgroundColor="red-opacity"
                      textColor="red"
                      size="lg"
                      fullWidth
                      onClick={() => deleteServiceInPackageSale(item)}
                    />
                  </Column>
                </Grid>
              ))
          ) : (
            <NoDataContainer>
              <img
                src={require("../../assets/images/manypixels/click_action.svg")}
                alt="Malesef bulunamadı!"
              />
              <p>{context.t(`appointments.create.noDataText`)}</p>
            </NoDataContainer>
          )}
        </>

        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
            <AutocompleteInput
              label={context.t(
                `appointments.create.selectedCustomerInputLabel`
              )}
              placeholder={context.t(
                `appointments.create.selectedCustomerInputPlaceholder`
              )}
              labelKey="full_name"
              valueKey="id"
              selected={selectedCustomer !== null ? selectedCustomer : ""}
              returnFullObject
              selectedHandler={(selected_customer) => {
                setSelectedCustomer(selected_customer);
                setSendSms(selected_customer.send_sms);
                setSelectedDescription(selected_customer.description);
              }}
              asyncDataService={(keyword) =>
                keyword.length > 2 ? searchCustomerByKey(keyword) : []
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-6 flex">
            <TimeSelect
              label={context.t(`['settings/hours'].startInputLabelHour`)}
              type="hour"
              value={selectedTime !== null ? selectedTime.split(":")[0] : ""}
              fullWidth={true}
              size="medium"
              companyShiftTime={true}
              startHour={context.state.company_shift.start_hour}
              endHour={context.state.company_shift.end_hour}
              handler={(time) => {
                // After 7 PM restrict Minute selection to 30 max

                setSelectedTime(
                  moment(selectedDate)
                    .set("hours", time)
                    .set("minutes", selectedTime.split(":")[1])
                    .format("HH:mm")
                );
              }}
            />
            <TimeSelect
              label={context.t(`['settings/hours'].startInputLabelMinute`)}
              type="minute"
              value={selectedTime !== null ? selectedTime.split(":")[1] : ""}
              selectedHour={selectedTime}
              lastHour={hours[hours.length - 1]}
              fullWidth={true}
              size="medium"
              handler={(time) => {
                setSelectedTime(
                  moment(selectedDate)
                    .set("hours", selectedTime.split(":")[0])
                    .set("minutes", time)
                    .format("HH:mm")
                );
              }}
            />
          </Column>
        </Grid>
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-6">
            <Input
              label={context.t(`appointments.create.noteInputLabel`)}
              onChange={(e) => setNote(e.target.value)}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
            <Input
              disabled
              label={context.t(`appointments.create.description`)}
              value={
                selectedCustomer && selectedDescription != null
                  ? selectedDescription
                  : context.t(`appointments.create.defaultValue`)
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 mt-1">
            <CSwitch
              label={context.t(`appointments.create.sendSmsInputLabel`)}
              checked={sendSms}
              color="primary"
              disabled={selectedCustomer && selectedCustomer.send_sms === false}
              onChange={(checked) => setSendSms(checked.target.checked)}
            />
          </Column>
        </Grid>
      </AlertDialog>
      {/* <CustomerDialog /> */}
      <CustomerAddDialog
        open={customerAddModal}
        createdCustomerHandler={(data) => {
          setSelectedCustomer({
            ...data,
            full_name: `${data.name} ${data.surname}`,
          });
          setCreateAppointmentModal(true);
        }}
        closeHandler={() => {
          setCustomerAddModal(false);
          setNote("");
        }}
        setOpen={setCustomerAddModal}
      />
    </>
  );
}

export default AddAppointmentDialog;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 120px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;
