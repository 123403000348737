import React, { useEffect, useState, useContext } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import moment from "moment";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import Input from "../../../theme/CustomMUI/Input";
import CalculateMessageCount from "../../../functions/CalculateMessageCount";
import SMSReview from "../../../components/Dialogs/SMSReview";
import { toast } from "react-toastify";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CustomersRisky = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [offset, setOffset] = useState(30);

  const [pagination, setPagination] = useState({
    total_page: 1,
    page: 1,
    onChange: (_page) => {setPagination({...pagination, page: _page})}
  });

  const [data, setData] = useState([]);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [filterPicker, setFilterPicker] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    }
  ]);

  const [selectedFilterPicker, setSelectedFilterPicker] = useState(null);

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedAllCustomer, setSelectedAllCustomer] = useState(false);
  const [exceptedCustomers, setExceptedCustomers] = useState([]);

  const [sms, setSms] = useState({
    message: "",
    templates: [],
    selectedTemplate: null,
  });

  const [openSmsDialog, setOpenSmsDialog] = useState(false);
  
  const [openSmsReviewDialog, setOpenSmsReviewDialog] = useState(false);
  const [totalNumber, setTotalNumber] = useState(null);
  const [totalSmsCredit, setTotalSmsCredit] = useState(null);

  const getRiskyCustomers = (page = pagination.page ? pagination.page : 1) => {
    Axios.get(
      `${context.api_endpoint}/company/customers/risky`,
      filterExpanded
        ? selectedFilterPicker === null
          ? {
              params: {
                page: page ? page : 1,
                ...{
                  start: moment(start).format("YYYY-MM-DD"),
                  end: moment(end).format("YYYY-MM-DD"),
                  isbetween: true,
                },
              },
            }
          : {
              params: {
                page: page ? page : 1,
                ...{
                  offset: selectedFilterPicker.offset,
                  isbetween: false,
                },
              },
            }
        : {
            params: {
              page: page ? page : 1,
              ...{
                start: moment(start).format("YYYY-MM-DD"),
                end: moment(end).format("YYYY-MM-DD"),
                isbetween: true,
              },
            },
          }
    )
      .then(({ data }) => {
        setLoaded(true);
        setData([
          ...data.data.records.map((item) => {
            return {
              ...item,
              phone:
                context.state.user.permission === 2 &&
                context.state.limited_permission.customer_info === 0
                  ? phoneNumberDisplay(item.phone)
                  : `0${item.phone.substring(0, 3)} ${item.phone.substring(
                      3,
                      6
                    )} ${item.phone.substring(6, 10)}`,
              extra_detail: {
                ...item.extra_detail,
                last_transaction_date: item.extra_detail.last_transaction_date
                  ? moment(item.extra_detail.last_transaction_date)
                      .startOf()
                      .fromNow()
                  : null,
                sum_income: item.extra_detail.sum_income
                  ? item.extra_detail.sum_income.toFixed(2)
                  : "0.00",
                total_debt: item.extra_detail.total_debt
                  ? item.extra_detail.total_debt.toFixed(2)
                  : "0.00",
              },
            };
          }),
        ]);
        setPagination({
          ...pagination,
          total_page: data.data.records.length,
          page: data.data.page
        })
      })
      .catch((e) => {
        console.log(e);
        // if (e.response.status === 401) {
        //   toast.error(e.response.data.message);
        // } else {
        //   toast.warning(e.response.data.message);
        // }
      });
  };

  const getSMSReview = () => {
    Axios.post(
      `${context.api_endpoint}/company/customers/risky/sms/preview`,
      {
        all: selectedAllCustomer,
        customer_id: [...selectedCustomers],
        except: [...exceptedCustomers],
        message: sms.message,
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          await setTotalNumber(response.data.data.total_numbers);
          await setTotalSmsCredit(response.data.data.total_sms_credit);
          await setOpenSmsDialog(false);
          await setOpenSmsReviewDialog(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const sendSMS = () => {
    Axios.post(
      `${context.api_endpoint}/company/customers/risky/send/sms`,
      {
        all: selectedAllCustomer,
        customer_id: [...selectedCustomers],
        except: [...exceptedCustomers],
        message: sms.message,
        offset: parseInt(offset),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setSelectedCustomers([]);
          setSelectedAllCustomer(false);
          setSms({
            ...sms,
            message: "",
            selectedTemplate: null,
          })
          toast.success(context.t(`["customers/risky"].sendSMSSuccessToast`));
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getRiskyCustomers();
  }, [end, start, pagination.page, selectedFilterPicker]);

  useEffect(() => {
    getRiskyCustomers();
    getTemplates();
  }, []);

  const getTemplates = () => {
    Axios.get(`${context.api_endpoint}/company/sms/template/all`).then(
      ({ res }) => {
        setSms({
          ...sms,
          templates: [...data,res],
        });
      }
    );
  };
  
  const MessageDialog = () => {
    return (
      <AlertDialog
        open={openSmsDialog}
        title={context.t(`['customers/risky'].sendSMSButtonTitle`)}
        maxWidth="md"
        fullWidth={true}
        buttons={[
          {
            disabled:
              sms.message.length === 0 ||
              !CalculateMessageCount(sms.message),

            icon: "send",
            title: context.t(`sms.send.buttonTitle`),
            backgroundColor: "primary",
            textColor: "white",
            fullWidth: true,
            onClick: () => {
              getSMSReview();
            },
          },
          {
            icon: "close",
            title: context.t(`sms.send.discardButtonTitle`),
            backgroundColor: "red",
            textColor: "white",
            fullWidth: true,
            onClick: () => {
              setSelectedCustomers([]);
              setExceptedCustomers([]);
              setSelectedAllCustomer(false);
              setSms({
                ...sms,
                message: "",
                selectedTemplate: null,
              });
            },
          },
        ]}
        closeHandler={() => {
          setOpenSmsDialog(false);
        }}
      >
        <Select
          label={context.t(`sms.send.template`)}
          items={[...sms.templates]}
          selected={sms.selectedTemplate?.name ?? ""}
          labelKey="name"
          valueKey="name"
          returnFullObject
          handler={(template) => setSms({
              ...sms,
              selectedTemplate: template,
              message: template.content,
            })
          }
        />
        <Input
          label={context.t(`sms.send.messageInputLabel`)}
          multiline={true}
          rows={10}
          value={sms.message}
          onChange={(e) =>
            setSms({
              ...sms,
              message: e.target.value,
            })
          }
          helperText={CalculateMessageCharCount(sms.message, "1")}
        />
      </AlertDialog>
    );
  };

    return (
      <AuthContainer authorities={[1]} limited_permission="customer">
        <Grid className="mb-1">
          <Column className="xs-12 sm-12 md-12 lg-3">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={filterExpanded}
                  onChange={(e) => {
                    setLoaded(false);
                    setFilterExpanded(e.target.checked);
                    getRiskyCustomers();
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{context.t(`[packages/sales].filter.title`)}</span>
                </FCLabel>
              }
            />
          </Column>
          {filterExpanded && (
            <>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <DatePickerInput
                  className="mb-0"
                  label={context.t(`receipts.filter.startInputLabel`)}
                  value={new Date(moment(start).toISOString())}
                  maxDate={end}
                  onChange={async (start) => {
                    await setLoaded(false);
                    await setStart(start);
                    await setSelectedFilterPicker(null);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <DatePickerInput
                  className="mb-0"
                  label={context.t(`receipts.filter.endInputLabel`)}
                  value={end}
                  minDate={new Date(moment(start).toISOString())}
                  onChange={async (end) => {
                    await setLoaded(false);
                    await setEnd(end);
                    await setSelectedFilterPicker(null);
                    await getRiskyCustomers();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Select
                  label={context.t(`appointments.dayWeekSelectLabel`)}
                  items={filterPicker}
                  labelKey="label"
                  valueKey="id"
                  selected={
                    selectedFilterPicker
                      ? `${selectedFilterPicker.id}`
                      : null
                  }
                  returnFullObject
                  handler={async (filterPicker) => {
                    setSelectedFilterPicker(filterPicker);
                  }}
                />
              </Column>
            </>
          )}
          <Column className="xs-12 sm-12 md-12 lg-3 mb-1">
            <Button
              title={
                selectedAllCustomer
                  ? context.t(`['customers/risky'].cancelAllButtonTitle`)
                  : context.t(`['customers/risky'].selectedAllButtonTitle`)
              }
              icon="check"
              backgroundColor={
                selectedAllCustomer ? "red" : "primary"
              }
              textColor="white"
              fullWidth
              onClick={() => {
                setSelectedCustomers([]);
                setSelectedAllCustomer(!selectedAllCustomer);
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3 mb-1">
            <Button
              title={context.t(`['customers/risky'].sendSMSButtonTitle`)}
              icon="message"
              backgroundColor="blue-opacity"
              textColor="blue"
              fullWidth
              disabled={
                selectedAllCustomer
                  ? false
                  : selectedCustomers.length === 0
              }
              onClick={() => {
                setOpenSmsDialog(true);
              }}
            />
          </Column>
          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{ textAlign: "right" }}
          >
            <ExcelButton
              route="customers/risky"
              requestData={{ is_excel: true }}
              size="sm"
            />
          </Column>
        </Grid>
        {!loaded ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "5vh",
            }}
          >
            <img
              src={
                process.env.APP_NAME === "salon"
                  ? Loader
                  : process.env.APP_NAME === "en"
                  ? LoaderEN
                  : LoaderSM
              }
              height={70}
            />
          </div>
        ) : (
          <Grid>
            <Column className="xs-12 sm-12">
              <Table
                refreshAction={() =>
                  getRiskyCustomers(pagination.page)
                }
                loaded={loaded}
                headings={{
                  full_name: {
                    label: context.t(
                      `['customers/risky'].headings.fullName`
                    ),
                    sortable: {
                      0: [
                        { field: "`customers`.`name`", order: "ASC" },
                        { field: "`customers`.`surname`", order: "ASC" },
                      ],
                      1: [
                        { field: "`customers`.`name`", order: "DESC" },
                        { field: "`customers`.`surname`", order: "DESC" },
                      ],
                    },
                  },
                  phone: {
                    label: context.t(`['customers/risky'].headings.phone`),
                    style: { width: 150 },
                  },
                  sex: {
                    label: context.t(`['customers/risky'].headings.sex`),
                  },
                  "extra_detail.sum_income": {
                    label: context.t(
                      `['customers/risky'].headings.incomeAll`
                    ),
                    suffix: context.state.currency
                      ? context.state.currency
                      : "₺",
                    sortable: {
                      0: [{ field: "`rp`.`amount`", order: "ASC" }],
                      1: [{ field: "`rp`.`amount`", order: "DESC" }],
                    },
                  },
                  "extra_detail.total_debt": {
                    label: context.t(
                      `['customers/risky'].headings.debtAll`
                    ),
                    suffix: context.state.currency
                      ? context.state.currency
                      : "₺",
                  },
                  "extra_detail.last_transaction_date": {
                    label: (
                      <p style={{ maxWidth: "170px", fontSize: "13px" }}>
                        {context.t(
                          `['customers/risky'].headings.lastTransaction`
                        )}
                      </p>
                    ),
                  },
                  _: { label: context.t(`component.actionHeadingText`) },
                }}
                rows={data}
                replacements={{
                  sex: {
                    null: context.t(
                      `['customers/risky'].replacements.sex.undef`
                    ),
                    0: context.t(
                      `['customers/risky'].replacements.sex.female`
                    ),
                    1: context.t(
                      `['customers/risky'].replacements.sex.male`
                    ),
                    2: context.t(
                      `['customers/risky'].replacements.sex.undef`
                    ),
                  },
                }}
                pagination={pagination}
                selectedAll={selectedAllCustomer}
                excepteds={exceptedCustomers}
                checkedCheckboxes={selectedCustomers}
                checkboxOnChange={(row, state) => {
                  if (state) {
                    setSelectedCustomers([
                      ...selectedCustomers,
                      row.id,
                    ]);
                    setExceptedCustomers([
                      ...exceptedCustomers.filter((id) => id !== row.id)
                    ]);
                  } else {
                    setSelectedCustomers([
                      ...selectedCustomers.filter(
                        (id) => id !== row.id
                      ),
                    ]);
                    setExceptedCustomers([
                      ...exceptedCustomers,
                      row.id,
                    ]);
                  }
                }}
                buttons={[
                  {
                    title: context.t(`component.detailEditButtonTitle`),
                    icon: "launch",
                    textColor: "primary",
                    transitionEffect: true,
                    pushEffect: true,
                    onClick: (row) =>
                      history.push(`/customers/detail/${row.id}`),
                  },
                ]}
              />
            </Column>
          </Grid>
        )}
        {<MessageDialog />}
        <SMSReview
          open={openSmsReviewDialog}
          closeHandler={() => {
            setOpenSmsReviewDialog(false);
          }}
          sendSMS={sendSMS}
          sms_content={sms.message}
          total_number={totalNumber}
          total_sms_credit={totalSmsCredit}
        />
      </AuthContainer>
    );
}

export default CustomersRisky;
