import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";
import { toast } from "react-toastify";
import Axios from "axios";

import AuthContainer from "../../../components/AuthContainer";
import RoundedRow from "../../../components/Table/RoundedRow";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Button from "../../../theme/Button";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import moment from "moment";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import ExcelButton from "../../../components/Buttons/ExcelButton";

import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";

const Debit = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [filter_expanded, setFilterExpanded] = useState(false);
  const [start_amount, setStartAmount] = useState(0);
  const [end_amount, setEndAmount] = useState(0);
  const [paids] = useState([
    {
      label: context.t(`["accounting/debit"].payments.paid`),
      value: 1,
    },
    {
      label: context.t(`["accounting/debit"].payments.notPaid`),
      value: 2,
    },
  ]);
  const [selected_paid, setSelectedPaid] = useState(null);
  const [current_name, setCurrentName] = useState("");

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    total_page: 1,
    page: 1,
    onChange: (page) => getDebit(page),
  });

  const [start, setStart] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(moment().format("YYYY-MM-DD"));

  //TODO : get Debit
  const getDebit = (page) => {
    setLoaded(false);
    const urlPage = parseInt(
      new URLSearchParams(window.location.search).get("page")
    );
    Axios.get(
      `${context.api_endpoint}/company/current/expenses`,
      filter_expanded === false
        ? {
            params: {
              page: page ? page : urlPage ? urlPage : 1,
            },
          }
        : {
            params: {
              page: page ? page : urlPage ? urlPage : 1,
              start_date: moment(start).format("YYYY-MM-DD"),
              end_date: moment(end).format("YYYY-MM-DD"),
              current_name: current_name ? current_name : null,
              is_paid: selected_paid
                ? selected_paid.value === 1
                  ? 1
                  : 2
                : null,
              start_amount: start_amount ? start_amount : null,
              end_amount: end_amount ? end_amount : null,
            },
          }
    )
      .then(({ data }) => {
        setLoaded(true);
        if (data.data.records !== null) {
          setData([
            ...data.data.records.map((item) => {
              return {
                ...item,
                ID: item.id,
                created_at: moment(item.CreatedAt).format("LL"),
                payment_date: moment(item.payment_date).format("LL"),
                customer_name: item.current_name,
                all_amount: (
                  <RoundedRow color="orange">
                    {item.all_amount ? item.all_amount.toFixed(2) : "0.00"}
                    {context.state.currency ? context.state.currency : "₺"}
                  </RoundedRow>
                ),
                paid: (
                  <RoundedRow color="green">
                    {item.paid > 0 ? item.paid.toFixed(2) : "0.00"}
                    {context.state.currency ? context.state.currency : "₺"}
                  </RoundedRow>
                ),

                debt_total: (
                  <RoundedRow color="red">
                    {(item.all_amount ?? 0) - (item.paid ?? 0) > 0
                      ? ((item.all_amount ?? 0) - (item.paid ?? 0)).toFixed(2)
                      : "0.00"}
                    {context.state.currency ? context.state.currency : "₺"}
                  </RoundedRow>
                ),
                is_paid:
                  (item.all_amount ?? 0) - (item.paid ?? 0) <= 0 ? (
                    <span
                      className="text-success"
                      style={{
                        marginLeft: "35px",
                      }}
                    >
                      <i className="material-icons" style={{ color: "green" }}>
                        check_circle_outline
                      </i>
                    </span>
                  ) : (
                    <span
                      className="text-danger"
                      style={{
                        marginLeft: "35px",
                      }}
                    >
                      <i className="material-icons" style={{ color: "red" }}>
                        highlight_off
                      </i>
                    </span>
                  ),
              };
            }),
          ]);
        } else {
          setData([]);
        }
        // setPagination({
        //   ...pagination,
        //   page: data.data.page,
        //   total_page: data.data.records.length,
        // });
      })
      .catch((e) => {
        toast.error(e.response.message);
      });
  };

  //TODO : Expense Delete
  const deleteExpense = (id) => {
    Axios.delete(`${context.api_endpoint}/company/current/expense`, {
      params: {
        id: id,
      },
    })
      .then(({ data }) => {
        toast.success(data.data);
        getDebit();
      })
      .catch((e) => {
        toast.error(e.response.message);
      });
  };

  useEffect(() => {
    getDebit();
  }, [
    filter_expanded,
    start,
    end,
    selected_paid,
    start_amount,
    end_amount,
    current_name,
  ]);

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="sales"
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-8">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={filter_expanded}
                onChange={async (e) => {
                  setFilterExpanded(e.target.checked);
                }}
              />
            }
            label={
              <FCLabel>
                <i className="material-icons">filter_list</i>{" "}
                <span>{context.t(`["accounting/debit"].filter.title`)}</span>
              </FCLabel>
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-2">
          <ExcelButton
            route="current/expenses"
            fullWidth
            requestData={{
              start_date: moment(start).format("YYYY-MM-DD"),
              end_date: moment(end).format("YYYY-MM-DD"),
              current_name: current_name ? current_name : null,
              is_paid: selected_paid
                ? selected_paid.value === 1
                  ? 1
                  : 2
                : null,
              start_amount: start_amount ? start_amount : null,
              end_amount: end_amount ? end_amount : null,
              is_excel: true,
            }}
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-2">
          <Button
            icon="add"
            title={context.t(`["accounting/debit"].addCredit`)}
            textColor="white"
            backgroundColor="primary"
            onClick={() => history.push("/accounting/debit/add")}
          />
        </Column>
        {filter_expanded && (
          <Grid className="mt-2">
            <Column className="xs-12 sm-12 md-12 lg-2">
              <DatePickerInput
                className="mb-0"
                label={context.t(`["accounting/debit"].filter.satrtDate`)}
                value={new Date(moment(start).toISOString())}
                maxDate={end}
                onChange={async (start) => {
                  await setStart(start);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-2">
              <DatePickerInput
                className="mb-0"
                label={context.t(`["accounting/debit"].filter.endDate`)}
                value={end}
                minDate={new Date(moment(start).toISOString())}
                onChange={async (end) => {
                  await setEnd(end);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-2">
              <Input
                required
                type="number"
                label={
                  context.t(`["accounting/credit"].add.start_amount`) + " (₺)"
                }
                selected={start_amount !== null ? start_amount : ""}
                customInput={Input}
                decimalScale={2}
                suffix={" ₺"}
                onChange={async (e) => {
                  await setStartAmount(e.target.value);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-2">
              <Input
                required
                type="number"
                label={
                  context.t(`["accounting/credit"].add.end_amount`) + " (₺)"
                }
                selected={end_amount !== null ? end_amount : ""}
                customInput={Input}
                decimalScale={2}
                suffix={" ₺"}
                onChange={async (e) => {
                  await setEndAmount(e.target.value);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-2">
              <Input
                required
                label={context.t(`["accounting/credit"].add.customerSelection`)}
                value={current_name}
                onChange={(e) => setCurrentName(e.target.value)}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-2">
              <Select
                label={context.t(`['accounting/credit'].headings.is_paid`)}
                items={paids}
                selected={selected_paid ? `${selected_paid.value}` : null}
                labelKey="label"
                valueKey="value"
                returnFullObject
                handler={async (selected_paid) => {
                  await setSelectedPaid(selected_paid);
                }}
              />
            </Column>
          </Grid>
        )}
      </Grid>

      <Grid className="mt-2">
        <Column className="xs-12 sm-12">
          {loaded ? (
            <Table
              refreshAction={() => getDebit(pagination.page)}
              title="Debit"
              headings={{
                created_at: {
                  label: `${context.t(
                    `["accounting/debit"].headings.createdAt`
                  )}`,
                },
                payment_date: {
                  label: `${context.t(
                    `["accounting/debit"].headings.scheduledPaymentDate`
                  )}`,
                },
                customer_name: {
                  label: `${context.t(
                    `["accounting/debit"].headings.nameSurname`
                  )}`,
                },
                all_amount: {
                  label: `${context.t(
                    `["accounting/debit"].headings.all_amount`
                  )}`,
                },
                paid: {
                  label: `${context.t(`["accounting/debit"].headings.paid`)}`,
                },
                debt_total: {
                  label: `${context.t(
                    `["accounting/debit"].headings.debt_total`
                  )}`,
                },
                is_paid: {
                  label: `${context.t(
                    `["accounting/credit"].headings.is_paid`
                  )}`,
                },
                _: {
                  label: `${context.t(
                    `["accounting/debit"].headings.transactions`
                  )}`,
                },
              }}
              rows={data}
              loaded={loaded}
              pagination={pagination}
              buttons={[
                {
                  title: `${context.t(`["accounting/debit"].headings.view`)}`,
                  icon: "launch",
                  textColor: "primary",
                  onClick: (row) =>
                    history.push(`/accounting/debit/detail/${row.ID}`),
                },
                {
                  title: `${context.t(`["accounting/debit"].headings.delete`)}`,
                  icon: "delete",
                  textColor: "red",
                  onClick: (row) => deleteExpense(row.ID),
                },
              ]}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img
                src={
                  process.env.APP_NAME === "salon"
                    ? Loader
                    : process.env.APP_NAME === "en"
                    ? LoaderEN
                    : LoaderSM
                }
                width="100"
                height="100"
                alt="loading"
              />
            </div>
          )}
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default Debit;
