import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import RoundedRow from "../../../components/Table/RoundedRow";
import { Alarm, AlarmOff, AlarmOn, EventNote } from "@material-ui/icons";

const OnlineAppointments = () => {
  const context = useContext(AppContext);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [loaded, setLoaded] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getAppointmentRequests(page),
  });
  const [data, setData] = useState([]);
  const [checkConfirmData, setCheckConfirmData] = useState([]);

  const [dialCode, setDialCode] = useState(null);
  const [phone, setPhone] = useState(null);
  const [addBlockListPopup, setAddBlockListPopup] = useState(false);

  const [replacements, setReplacements] = useState();


  const getAppointmentRequests = (page) => {
    Axios.get(`${context.api_endpoint}/company/appointments/requests`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        setLoaded(true);
        setPagination({
          ...pagination,
          page: data.data.page,
          total_page: data.data.records.length,
        });
        setData([
          ...data.data.records.map((item) => ({
            ...item,
            customer_statics: {
              ...item.customer_statics,
              sumCol: (
                <>
                  <RoundedRow color="blue">
                    <EventNote />{" "}
                    {item.customer_statics.success +
                      item.customer_statics.wait +
                      item.customer_statics.fail}
                  </RoundedRow>
                  <RoundedRow color="green">
                    <AlarmOn /> {item.customer_statics.success}
                  </RoundedRow>
                  <RoundedRow color="orange">
                    <Alarm /> {item.customer_statics.wait}
                  </RoundedRow>
                  <RoundedRow color="red">
                    <AlarmOff /> {item.customer_statics.fail}
                  </RoundedRow>
                </>
              ),
            },
            appointment_start_date: moment(item.appointment_start_date)
              .tz("Europe/Istanbul")
              .format("DD/MM/YYYY HH:mm"),
          })),
        ].filter((item) => item !== undefined))
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const approveSingleAppointmentRequest = (id) => {
    Axios.post(
      `${context.api_endpoint}/company/appointment/request/approve`,
      {
        request_id: id,
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['appointments/online'].approveToast`));
          getAppointmentRequests();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const rejectSingleAppointmentRequest = (id) => {
    Axios.post(
      `${context.api_endpoint}/company/appointment/request/${id}/cancel`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['appointments/online'].rejectToast`));
          getAppointmentRequests();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const addBlacklist = () => {
    Axios.post(`${context.api_endpoint}/company/shortcode/add/blacklist`, {
      phone: phone ? phone : null,
      dialCode: dialCode ? dialCode : null,
    })
      .then(() => {})
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        setDialCode(null);
        setPhone(null);
      });
  };

  useEffect(() => {
    getAppointmentRequests();
  }, []);

    return (
      <AuthContainer limited_permission="appointment">
        <Grid>
          <Column className="xs-12 sm-12">
            {context.state.user.permission !== 1 && (
              <Alert className="mb-3" severity="info">
                {context.t(`['appointments/online'].alertText`)}
              </Alert>
            )}
            <Table
              refreshAction={() =>
                getAppointmentRequests(pagination.page)
              }
              loaded={loaded}
              headings={{
                appointment_start_date: {
                  label: context.t(
                    `['appointments/online'].headings.appointmentStartDate`
                  ),
                  style: { width: 150 },
                },
                customer_full_name: {
                  label: context.t(
                    `['appointments/online'].headings.customerFullName`
                  ),
                  //with_photo: "customer.photo",
                  limited_line: 3,
                  style: { width: "200px" },
                },
                customer_phone: {
                  label: context.t(
                    `['appointments/online'].headings.customerPhone`
                  ),
                },
                // "customer_type": {
                //   label: context.t(`['appointments/online'].headings.customerType`),
                //  },
                "staff.full_name": {
                  label: context.t(
                    `['appointments/online'].headings.staffFullName`
                  ),
                  with_photo: "staff.detail.profile_photo",
                  limited_line: 1,
                },
                "service.name": {
                  label: context.t(
                    `['appointments/online'].headings.serviceName`
                  ),
                  limited_line: 1,
                },
                note: {
                  label: context.t(
                    `['appointments/online'].headings.note`
                  ),
                  limited_line: 1,
                },
                "customer_statics.sumCol": {
                  label: context.t(`dashboard.stats.appointment.heading`),
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              rows={data}
              replacements={replacements}
              pagination={pagination}
              buttons={[
                {
                  /* title: context.t(
                    `['appointments/online'].approveButtonTitle`
                  ) */
                  icon: "check",
                  textColor: "green",
                  transitionEffect: true,
                  pushEffect: true,
                  disabled: context.state.user.permission === 0,

                  onClick: (row) => {
                    setCheckConfirmData([...checkConfirmData, row.id]);
                    if (!checkConfirmData.includes(row.id)) {
                      approveSingleAppointmentRequest(row.id);
                    }
                  },
                },
                {
                  /*  title: context.t(
                    `['appointments/online'].rejectButtonTitle`
                  ), */
                  icon: "close",
                  textColor: "red",
                  transitionEffect: true,
                  pushEffect: true,
                  disabled: context.state.user.permission === 0,
                  onClick: (row) => {
                    setDeleteId(row.id);
                    setDeleteConfirm(true);
                  }
                },
                {
                  icon: "block",
                  textColor: "black",
                  transitionEffect: true,
                  pushEffect: true,
                  disabled: context.state.user.permission === 0,
                  onClick: (row) => {
                    setDialCode(row?.dialCode);
                    setPhone(row?.customer_phone);
                    setAddBlockListPopup(true);
                    setDeleteId(row.id);
                  },
                },
              ]}
            />

            <AlertDialog
              title={context.t(
                `['appointments/online'].rejectConfirm.title`
              )}
              open={deleteConfirm}
              closeHandler={() => setDeleteConfirm(false)}
              buttons={[
                {
                  title: context.t(
                    `['appointments/online'].rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () =>
                    rejectSingleAppointmentRequest(deleteId),
                },
                {
                  title: context.t(
                    `['appointments/online'].rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>
                  {context.t(
                    `['appointments/online'].rejectConfirm.alertBoldText`
                  )}
                </b>
                <br />
                {context.t(
                  `['appointments/online'].rejectConfirm.alertText`
                )}
              </Alert>
            </AlertDialog>

            <AlertDialog
              title={
                <h5>
                  {context.t(
                    `['appointments/online'].addToNumberBlackList`
                  )}{" "}
                  ({phone})
                </h5>
              }
              open={addBlockListPopup}
              closeHandler={() => setAddBlockListPopup(false)}
              buttons={[
                {
                  title: context.t(
                    `['appointments/online'].rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => {
                    addBlacklist();
                    rejectSingleAppointmentRequest(deleteId);
                  },
                },
                {
                  title: context.t(
                    `['appointments/online'].rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                {context.t(`['appointments/online'].blackListWarning`)}
              </Alert>
            </AlertDialog>
          </Column>
        </Grid>
      </AuthContainer>
    );
  
}

export default OnlineAppointments;
