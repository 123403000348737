import React, { useContext, useEffect } from "react";
import logo from "../../../../assets/images/logo.png";
import logoEn from "../../../../assets/images/logo_en.png";
import logo1 from "../../../../assets/images/logo_management2.png";
import logo2 from "../../../../assets/images/logo_management3.png";
import { GiSandsOfTime } from "react-icons/gi";
import AppContext from "../../../../context/store";
function Index({ timeLeft, setTimeLeft }) {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (timeLeft > 0) {
  //       setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
  //     }
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, [timeLeft]);
  const context = useContext(AppContext);
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Pad numbers with leading zeros if necessary
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return (
      <div
        style={{
          width: "100%",
          fontFamily: "Arial",
          fontSize: "20px",
          textAlign: "center",
          backgroundColor: "#f0f0f0",
          borderRadius: "5px",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {" "}
        <GiSandsOfTime
          style={{ color: formattedHours < 10 ? "#d63447" : "black" }}
          size={35}
        />
        <div>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
              fontSize: "12px",
              color: "rgb(213, 10, 88)",
            }}
          >
            {process.env.APP_NAME === "management"
              ? "Don't Miss!"
              : context.t(`licenseDetails.renewalTitle`).replace("Lisans Yenileme Fırsatını", "Fırsatı ")}
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
              color: formattedHours < 10 ? "#d63447" : "black",
            }}
          >
            <div>
              <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:
              <span>{formattedSeconds}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        position: "fixed",
        top: "0px",
        zIndex: 999,
        backgroundColor: "white",
        padding: "10px",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {process.env.APP_NAME === "management" ? (
          <div>
            <img style={{ width: "42px" }} src={logo1} />
            <img style={{ width: "108px", marginLeft: "5px" }} src={logo2} />
          </div>
        ) : process.env.APP_NAME === "salon" ? (
          <div>
            <img style={{ width: "150px" }} src={logo} />
          </div>
        ) : (
          <div>
            <img style={{ width: "150px" }} src={logoEn} />
          </div>
        )}

        <a
          href="#test"
          style={{
            marginRight: "20px",
            textDecoration: "none",
            color: "black",
          }}
        >
          {formatTime(timeLeft)}
        </a>
      </div>
    </div>
  );
}

export default Index;
