import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Grid, Column } from "../../../theme/Grid";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import { toast } from "react-toastify";
import Button from "../../../theme/Button";
import styled, { keyframes, css } from "styled-components";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import { Cast, Update, WhatsApp } from "@material-ui/icons";
import QRCode from "react-qr-code";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import { BsQrCode } from "react-icons/bs";
import { TbDeviceMobileCheck, TbHistory } from "react-icons/tb";
import ReplayIcon from "@material-ui/icons/Replay";
import Loader from "../../../assets/images/loader.gif";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import wp_tutorial_1 from "../../../assets/images/wp_tutorial_image_1.png";
import wp_tutorial_2 from "../../../assets/images/wp_tutorial_image_2.png";
import wp_tutorial_3 from "../../../assets/images/wp_tutorial_image_3.png";
import wp_tutorial_en_1 from "../../../assets/images/wp-en-1.png";
import wp_tutorial_en_2 from "../../../assets/images/wp-en-2.png";
import wp_tutorial_en_3 from "../../../assets/images/wp-en-3.png";
import { HiMiniSignal } from "react-icons/hi2";
import { HiMiniSignalSlash } from "react-icons/hi2";
import { IoIosInformationCircleOutline } from "react-icons/io";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const SmsSettings = () => {
  const context = useContext(AppContext);

  // randevudan önce gönderilecek mesajların kaç saat önce gideceği belirlenir.
  const [reminder, setReminder] = useState(0);
  // doğum günü hatırlatması
  const [reminder_birthday, setReminderBirthday] = useState(true);
  // müşteriye/çalışana randevu hatırlatması gitsin/gitmesin
  const [reminder_customer, setReminderCustomer] = useState(true);
  const [reminder_staff, setReminderStaff] = useState(true);
  // randevu tarihine 1den fazla gün var ise bir gün öncesinden mesaj gitsin/gitmesin
  const [reminder_day_ago_customer, setReminderDayAgoCustomer] = useState(true);
  const [reminder_day_ago_staff, setReminderDayAgoStaff] = useState(true);
  // randevu oluşturulduğu esnada sms gitsin/gitmesin
  const [on_appointment_customer, setOnAppointmentCustomer] = useState(true);
  const [on_appointment_staff, setOnAppointmentStaff] = useState(true);
  // dışarıdan yeni bir randevu talebi/isteği geldiğinde sms gitsin/gitmesin
  const [on_request_customer, setOnRequestCustomer] = useState(true);
  const [on_request_staff, setOnRequestStaff] = useState(true);
  // randevu isteği onaylandığında sms gitsin/gitmesin
  const [approve_appointment_customer, setApproveAppointmentCustomer] =
    useState(true);
  const [approve_appointment_staff, setApproveAppointmentStaff] =
    useState(true);
  // randevu isteği reddedildiğinde sms gitsin/gitmesin
  const [decline_appointment_customer, setDeclineAppointmentCustomer] =
    useState(true);
  const [decline_appointment_staff, setDeclineAppointmentStaff] =
    useState(true);
  // aktif bir randevu iptal edildiğinde sms gitsin /gitmesin
  const [cancel_appointment_customer, setCancelAppointmentCustomer] =
    useState(true);
  const [cancel_appointment_staff, setCancelAppointmentStaff] = useState(true);
  // müşteri kaydedildiğinde randevu linki gitsin/gitmesin
  const [on_customer_create, setOnCustomerCreate] = useState(true);
  // randevu sonrası gitsin/gitmesin
  const [after_appointment, setAfterAppointment] = useState(true);
  //Randevu Güncelleme Durumu
  const [update_appointment_customer, setUpdateAppointmentCustomer] =
    useState(true);
  const [update_appointment_staff, setUpdateAppointmentStaff] = useState(true);
  //SMS'den Randevu Katılımı İçin Link Gönderimi
  const [
    is_link_included_in_reminder_customer,
    setIsLinkIncludedInReminderCustomer,
  ] = useState(true);
  // Randevuya gelemeyen müşteriye 60. günde otomatik SMS hatırlatma
  const [reminder_noncomer_customer, setReminderNoncomerCustomer] =
    useState(true);
  // Randevu Sürükle Ve Bırak
  const [
    drag_and_drop_appointment_customer,
    setDragAndDropAppointmentCustomer,
  ] = useState(true);
  // Sms Kısa Kod
  const [sms_short_code, setSmsShortcode] = useState(true);
  // Online link seçeneği
  const [online_link, setOnlineLink] = useState(context.state.online_link);

  const [QRcodeInfo, setQRCodeInfo] = useState({
    code: null,
    registrationId: null,
  });
  const [qr_code_dialog, setQRCodeDialog] = useState(false);

  const [show_QRcode, setShowQRCode] = useState(false);

  const [wp_balance, setWPBalance] = useState(null);

  const [timer, setTimer] = useState(null);

  const [open_select, setOpenSelect] = useState(false);

  const [wp_code, setWPCode] = useState(null);

  const [wp_code_dialog, setWPCodeDialog] = useState(false);

  const [wp_loader, setWPLoader] = useState(false);

  const [wp_is_connected, setWPIsConnected] = useState(null);

  const [wp_is_connected_message, setWPIsConnectedMessage] = useState(null);

  const [login_check_register, setLoginCheckRegister] = useState(false);

  const [wp_phone, setWPPhone] = useState(null);

  const [wp_send_phone, setWPSendPhone] = useState("");

  const [wp_phone_dialCode, setWPPhoneDialCode] = useState(null);

  const [code_repeat, setCodeRepeat] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const [scalgeImage, setScaleImage] = useState(false);

  const [scaleImageNumber, setScaleImageNumber] = useState(null);

  const states = {
    reminder: reminder,
    reminder_birthday: reminder_birthday,
    reminder_customer: reminder_customer,
    reminder_staff: reminder_staff,
    reminder_day_ago_customer: reminder_day_ago_customer,
    reminder_day_ago_staff: reminder_day_ago_staff,
    on_appointment_customer: on_appointment_customer,
    on_appointment_staff: on_appointment_staff,
    on_request_customer: on_request_customer,
    on_request_staff: on_request_staff,
    approve_appointment_customer: approve_appointment_customer,
    approve_appointment_staff: approve_appointment_staff,
    decline_appointment_customer: decline_appointment_customer,
    decline_appointment_staff: decline_appointment_staff,
    cancel_appointment_customer: cancel_appointment_customer,
    cancel_appointment_staff: cancel_appointment_staff,
    on_customer_create: on_customer_create,
    after_appointment: after_appointment,
    update_appointment_customer: update_appointment_customer,
    update_appointment_staff: update_appointment_staff,
    is_link_included_in_reminder_customer:
      is_link_included_in_reminder_customer,
    reminder_noncomer_customer: reminder_noncomer_customer,
    drag_and_drop_appointment_customer: drag_and_drop_appointment_customer,
    sms_short_code: sms_short_code,
    online_link: online_link,
  };

  const setStates = {
    reminder: setReminder,
    reminder_birthday: setReminderBirthday,
    reminder_customer: setReminderCustomer,
    reminder_staff: setReminderStaff,
    reminder_day_ago_customer: setReminderDayAgoCustomer,
    reminder_day_ago_staff: setReminderDayAgoStaff,
    on_appointment_customer: setOnAppointmentCustomer,
    on_appointment_staff: setOnAppointmentStaff,
    on_request_customer: setOnRequestCustomer,
    on_request_staff: setOnRequestStaff,
    approve_appointment_customer: setApproveAppointmentCustomer,
    approve_appointment_staff: setApproveAppointmentStaff,
    decline_appointment_customer: setDeclineAppointmentCustomer,
    decline_appointment_staff: setDeclineAppointmentStaff,
    cancel_appointment_customer: setCancelAppointmentCustomer,
    cancel_appointment_staff: setCancelAppointmentStaff,
    on_customer_create: setOnCustomerCreate,
    after_appointment: setAfterAppointment,
    update_appointment_customer: setUpdateAppointmentCustomer,
    update_appointment_staff: setUpdateAppointmentStaff,
    is_link_included_in_reminder_customer: setIsLinkIncludedInReminderCustomer,
    reminder_noncomer_customer: setReminderNoncomerCustomer,
    drag_and_drop_appointment_customer: setDragAndDropAppointmentCustomer,
    sms_short_code: setSmsShortcode,
    online_link: setOnlineLink,
  };

  const setState = (key, value) => {
    if (setStates[key]) {
      setStates[key](value);
    } else {
      console.warn(`No state updater found for key: ${key}`);
    }
  };

  const configTimer = () => {
    let timer = 30;
    let countdownNumberEl = document.getElementById("countdown-number");

    // Check if countdownNumberEl is not null before accessing its properties
    if (countdownNumberEl) {
      countdownNumberEl.textContent = timer;

      var timerInterval = setInterval(
        function () {
          if (timer > 0) {
            timer = timer - 1;
          } else {
            setShowQRCode(false);
            setCodeRepeat(true);
            clearInterval(timerInterval);
          }

          // Check if countdownNumberEl is still not null before updating its content
          if (countdownNumberEl) {
            countdownNumberEl.textContent = timer;
          }
        }.bind(this),
        1000
      );
      setTimer(timerInterval);
    } else {
      console.error("Element with ID 'countdown-number' not found.");
    }
  };

  // wp kredisinin alındığı endpoint
  const getBalance = async () => {
    Axios.get(`${context.api_endpoint}/company/balance`)
      .then((data) => {
        setWPBalance(
          data.data.data.wp_credit ? data.data.data.wp_credit : null
        );
      })
      .catch((err) => console.log(err));
  };

  const getWpMessageSettings = async () => {
    try {
      const response = await Axios.get(
        `${context.api_endpoint}/company/get/wp/message/settings`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const checkWpLoginStatus = async (deviceKey) => {
    try {
      const response = await Axios.post(
        `${context.api_endpoint}/salon/wp/login/check/active/${deviceKey}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleWpDetails = (response) => {
    const { data } = response;

    if (response.status === 200) {
      if (data.device_key !== "") {
        setQRCodeInfo((prevState) => ({
          ...prevState.QRcodeInfo,
          registrationId: data.device_key,
        }));

        checkWpLoginStatus(data.device_key)
          .then((response) => {
            setWPIsConnected(response.is_connect);
            setWPIsConnectedMessage(response.message);
            setWPPhone(response.data.device_number);

            if (response.status === 200) {
              setWPIsConnected(response.is_connect);
              setWPIsConnectedMessage(response.message);
              setWPPhone(response.data.device_number);
            }
            // toast.success(context.t(`sms.foundWpDevice`));
          })
          .catch((err) => {
            setTimeout(() => {
              setWPIsConnected(false);
              setWPIsConnectedMessage("");
            }, 250);
            // toast.warn("Cihaz aktif değil lütfen güncelleyiniz!");
          })
          .finally(() => setOpenSelect(false));
      }
    }

    if (response.status === 200) {
      const { id, company_id, ...restData } = data;
      Object.entries(restData).forEach(([key, value]) => {
        if (setStates[key]) {
          setState(key, value); // Update the specific state
        } else {
          console.warn(`No state updater found for key: ${key}`);
        }
      });
      console.log(restData);
      // setState((prevState) => ({
      //   ...prevState
      //   ...restData,
      // }));
    }
  };

  const getWpDetails = async () => {
    try {
      const response = await getWpMessageSettings();
      handleWpDetails(response);
    } catch (error) {
      console.error(error);
    }
  };

  const saveSmsSettings = async () => {
    await Axios.put(
      `${context.api_endpoint}/company/update/wp/message/settings`,
      {
        ...states,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`['sms/wp/settings'].updateToast`));
          getWpDetails();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(
            context.t(`["sms/settings"].saveSMSSettingsErrorToast`),
            {
              autoClose: 3000,
            }
          );
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const loginRegis = async () => {
    QRcodeInfo.registrationId !== null &&
      (await Axios.post(
        `${context.api_endpoint}/salon/wp/login/check/${QRcodeInfo.registrationId}`,
        {
          phone: context.state.company_branch_first.staffs[0].detail.phone,
        }
      )
        .then(async (res) => {
          if (res.data.is_connect) {
          }
          setQRCodeDialog(false);
          setWPIsConnected(true);
          setWPCodeDialog(false);

          toast.success(context.t(`["sms/wp/settings"].loginWPDeviceSuccessToast`));
        })
        .catch((err) => {
          console.log("error:", err);
        })
        .finally(() => {
          setOpenSelect(false);
          getWpDetails();
        }));
  };

  const logoutWhatsApp = () => {
    setWPLoader(true);
    Axios.post(
      `${context.api_endpoint}/salon/wp/logout/${QRcodeInfo.registrationId}`
    )
      .then(() => {
        getWpDetails();
        toast.success(context.t(`["sms/wp/settings"].logoutWPSuccessToast`));
        setTimeout(() => {
          getBalance();
        }, 250);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setWPIsConnected(null);
        setWPIsConnectedMessage(null);
        setWPPhone(null);
        setWPLoader(false);
      });
  };

  const loginWhatsappWithQr = async () => {
    setWPLoader(true);
    setOpenSelect(false);
    await Axios.post(`${context.api_endpoint}/salon/wp/login/qr`, {
      phone:
        (context.state.dialCode ? context.state.dialCode.substring(1) : "90") +
        context.state.company_branch_first.staffs[0].detail.phone,
    })
      .then((res) => {
        if (res.status === 200) {
          setQRCodeDialog({
            code: res.data.qr,
            registrationId: res.data.regId,
          });
          setQRCodeDialog(true);
          setShowQRCode(true);
          setTimeout(() => {
            configTimer();
          }, 250);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setWPLoader(false));
  };
  const loginWhatsappWithCode = async () => {
    setWPLoader(true);
    setOpenSelect(false);
    await Axios.post(`${context.api_endpoint}/salon/wp/login/code`, {
      phone: wp_send_phone,
    })
      .then((res) => {
        if (res.status === 200) {
          setWPCode(res.data.code);
          setWPCodeDialog(true);
          setQRCodeInfo({
            ...QRcodeInfo,
            registrationId: res.data.regId,
          });
          setTimeout(() => {
            configTimer();
          }, 250);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setWPLoader(false));
  };

  const SettingsBoxBuilderWithNoPermission = ({
    children,
    boxProps,
    optionList,
  }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                border: "1px solid rgba(255,193,0)",
                padding: "10px",
                backgroundColor: "rgba(255,193,0, 0.1)",
                borderRadius: "5px",
                color: "rgb(255,154,0)",
              }}
            >
              {context.t(`['sms/wp/settings'].padiContent`)}
            </p>
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  const SettingsBoxBuilder = ({ children, boxProps, optionList }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            {optionList?.map((item) => (
              <FormControlLabel
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                label={<FCLabel>{item.label}</FCLabel>}
                control={
                  <Switch
                    color="primary"
                    checked={states[item.key]}
                    onChange={(e) => setState([item.key], !states[item.key])}
                  />
                }
              />
            ))}
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  const QRCodeDialog = () => {
    return (
      <Dialog
        open={qr_code_dialog}
        // open = { true}
        maxWidth={"sm"}
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            maxWidth: "90vw", // Maksimum genişlik: ekran genişliğinin %90'ı
            maxHeight: "90vh", // Maksimum yükseklik: ekran yüksekliğinin %90'ı
            // width: "1000px", // Genişliği otomatik olarak ayarla
            height: "580px", // Yüksekliği otomatik olarak ayarla
            padding: "10px",
            backgroundColor: "#f7f7f7",
          },
        }}
        closeHandler={() => {
          setQRCodeDialog(false);
          clearInterval(timer);
        }}
      >
        {" "}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
          onClick={() => setQRCodeDialog(false)}
        >
          <Button textColor="dark" icon={"close"} />
        </div>
        <DialogContentText style={{ height: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "50%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {show_QRcode ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 256,
                    width: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={QRcodeInfo.code}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "30px",
                }}
              >
                <Button
                  title="Tekrar Gönder"
                  icon="replay"
                  backgroundColor="primary"
                  textColor="white"
                  onClick={() => {
                    loginWhatsappWithQr();
                    setLoginCheckRegister(true);
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h3>{context.t(`sms.qrPopupTitle`)}</h3>
                <p>{context.t(`sms.qrPopupContext`)}</p>

                {show_QRcode && (
                  <div style={{ marginTop: "40px" }}>
                    <div id="countdown">
                      <div id="countdown-number"></div>
                      <svg id="countdown-svg">
                        <circle
                          id="countdown-circle"
                          r="18"
                          cx="20"
                          cy="20"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "50%",
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "#4682B4",
                    color: "white",
                  }}
                >
                  1
                </div>
                <HoverableImage
                  onClick={() => {
                    setScaleImage(true);
                    setScaleImageNumber(1);
                  }}
                  style={{ objectFit: "cover" }}
                  src={
                    context.state.default_locale === "tr"
                      ? wp_tutorial_1
                      : wp_tutorial_en_1
                  }
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <p style={{ height: "40px", fontSize: "12px", color: "black" }}>
                  {" "}
                  {context.t(`sms.wpQrTutorial1`)}{" "}
                </p>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "#4682B4",
                    color: "white",
                  }}
                >
                  2
                </div>

                <HoverableImage
                  onClick={() => {
                    setScaleImage(true);
                    setScaleImageNumber(2);
                  }}
                  style={{ objectFit: "cover" }}
                  src={
                    context.state.default_locale === "tr"
                      ? wp_tutorial_2
                      : wp_tutorial_en_2
                  }
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <p style={{ height: "40px", fontSize: "12px", color: "black" }}>
                  {context.t(`sms.wpQrTutorial2`)}
                </p>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    backgroundColor: "#4682B4",
                    color: "white",
                  }}
                >
                  3
                </div>
                <HoverableImage
                  onClick={() => {
                    setScaleImage(true);
                    setScaleImageNumber(3);
                  }}
                  style={{ objectFit: "cover" }}
                  src={
                    context.state.default_locale === "tr"
                      ? wp_tutorial_3
                      : wp_tutorial_en_3
                  }
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <p style={{ height: "40px", fontSize: "12px", color: "black" }}>
                  {context.t(`sms.wpQrTutorial3`)}
                </p>
              </div>
            </div>
          </div>
        </DialogContentText>
      </Dialog>
    );
  };

  const phone_handleOnChange = (value, data, event, formattedValue) => {
    setWPPhoneDialCode(data.dialCode);
    setWPSendPhone(value);
  };

  const CodeDialog = () => {
    return (
      <Dialog
        open={wp_code_dialog}
        onClose={() => {
          setWPCodeDialog(false);
          setWPCode(null);
          setLoginCheckRegister(false);
          clearInterval(timer);
        }}
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            maxWidth: "90vw", // Maksimum genişlik: ekran genişliğinin %90'ı
            maxHeight: "90vh", // Maksimum yükseklik: ekran yüksekliğinin %90'ı
            width: "420px",
            height: "272px",
            padding: "10px",
            position: "relative",
          },
        }}
      >
        {" "}
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
          }}
          onClick={() => {
            setWPCodeDialog(false);
            setWPCode(null);
            setLoginCheckRegister(false);
            setWPSendPhone("");
            clearInterval(timer);
          }}
        >
          <Button textColor="dark" icon={"close"} />
        </div>
        <DialogContent
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px",
          }}
        >
          {wp_code ? (
            <div style={{ marginTop: "8px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p style={{ fontSize: "12px" }}>
                  {wp_send_phone} {context.t(`sms.codeForThisPhone`)}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <h1
                  style={{
                    width: "100%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  {wp_code ? wp_code : ""}
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {code_repeat ? (
                  <button
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      cursor: "pointer",
                      backgroundColor: "#D3E5E1",
                      marginTop: "25px",
                    }}
                    onClick={() => {
                      loginWhatsappWithCode();
                      setLoginCheckRegister(true);
                      setCodeRepeat(false);
                    }}
                  >
                    <ReplayIcon />
                  </button>
                ) : (
                  <div>
                    <div id="countdown">
                      <div id="countdown-number"></div>
                      <svg id="countdown-svg">
                        <circle
                          id="countdown-circle"
                          r="18"
                          cx="20"
                          cy="20"
                        ></circle>
                      </svg>
                    </div>
                  </div>
                )}
              </div>
              <Button
                style={{ marginTop: "45px" }}
                fullWidth
                textColor="white"
                backgroundColor="primary"
                title={context.t(`component.staffOrderDialog.closeButtonTitle`)}
                onClick={() => {
                  setWPCodeDialog(false);
                  setWPCode(null);
                  setLoginCheckRegister(false);
                  setWPSendPhone("");
                  clearInterval(timer);
                }}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "revert",
                    fontSize: "14px",
                    marginTop: "7px",
                  }}
                >
                  {context.t(`sms.withPhoneNumberTitle`)}
                </p>{" "}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginTop: "15px",
                  }}
                >
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <PhoneInput
                      country={"tr"}
                      countryCodeEditable={false}
                      inputStyle={{
                        width: "100%", // Set a default width
                      }}
                      value={wp_send_phone}
                      onChange={phone_handleOnChange}
                      specialLabel={context.t(
                        `['customers/add'].phoneInputLabel`
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "5px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F0FFFF",
                      gap: "8px",
                      wordWrap: "break-word",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <IoIosInformationCircleOutline size={22} />
                      <p
                        style={{
                          color: "#696969",
                          fontSize: "13px",
                          textAlign: "center",
                          margin: "0",
                        }}
                      >
                        {context.t(`["sms/wp/settings"].infoCodeText`)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  fullWidth
                  icon={"send"}
                  disabled={
                    wp_send_phone.slice(
                      wp_phone_dialCode && wp_phone_dialCode.length
                    ).length === 0
                  }
                  textColor="white"
                  backgroundColor="primary"
                  title={context.t(`component.sms_review.sendButtonTitle`)}
                  onClick={() => {
                    loginWhatsappWithCode();
                    setLoginCheckRegister(true);
                  }}
                />
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    );
  };
  const ImageDialog = () => {
    return (
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "38px",
            padding: "0px",
            margin: "0px",
            backgroundColor: "transparent",
            overflow: "hidden",
          },
        }}
        open={scalgeImage}
        onClose={() => setScaleImage(false)}
      >
        <img
          onClick={() => setScaleImage(false)}
          style={{ width: "300px", height: "580px", objectFit: "cover" }}
          src={
            context.state.default_locale === "tr"
              ? scaleImageNumber === 1
                ? wp_tutorial_1
                : scaleImageNumber === 2
                ? wp_tutorial_2
                : wp_tutorial_3
              : scaleImageNumber === 1
              ? wp_tutorial_en_1
              : scaleImageNumber === 2
              ? wp_tutorial_en_2
              : wp_tutorial_en_3
          }
          alt="image"
        />
      </Dialog>
    );
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevState = usePrevious(QRcodeInfo.registrationId);

  useEffect(() => {
    if (QRcodeInfo.registrationId !== prevState) {
      if (login_check_register) {
        loginRegis();
      }
    }
  }, [QRcodeInfo.registrationId]);

  useEffect(() => {
    getWpDetails();
    getBalance();
  }, []);

  const HOCSettingBox = SettingsBoxBuilder;
  const HOCSettingBoxWithNoPermission = SettingsBoxBuilderWithNoPermission;

  return (
    <AuthContainer limited_permission="sms">
      <Grid className="mb-3 pb-2" style={{ borderBottom: "1px solid #ccc" }}>
        <Column className="xs-12 md-auto">
          <StatsCardContainer>
            <Grid
              style={{ height: "100%", minHeight: 73 }}
              className="no-gutters-all"
            >
              <Column className="xs-12">
                <IconAlignContainer
                  className={
                    wp_is_connected
                      ? "fc-grey bg-green-opacity"
                      : "fc-grey bg-red-opacity"
                  }
                >
                  <Cast
                    style={{
                      fontSize: "3em",
                      color: wp_is_connected ? "green" : "red",
                    }}
                  />
                </IconAlignContainer>
              </Column>
              <Column className="xs-12 col-auto">
                <TextAlignContainer>
                  <StatsHeader>{context.t(`sms.deviceStatus`)}</StatsHeader>
                  <StatsValue>
                    {wp_is_connected ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          alignItems: "center",
                          color: "green",
                        }}
                      >
                        {" "}
                        {context.t(`sms.deviceConnected`)}{" "}
                        <HiMiniSignal color="green" />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          gap: "6px",
                          alignItems: "center",
                          color: "red",
                        }}
                      >
                        {" "}
                        {context.t(`sms.deviceNotConnected`)}{" "}
                        <HiMiniSignalSlash color="red" />
                      </div>
                    )}
                  </StatsValue>
                </TextAlignContainer>
              </Column>
            </Grid>
          </StatsCardContainer>
        </Column>

        <Column className="xs-12 md-auto">
          <StatsCardContainer>
            <Grid
              style={{ height: "100%", minHeight: 73 }}
              className="no-gutters-all"
            >
              <Column className="xs-12">
                <IconAlignContainer className="fc-green bg-green-opacity">
                  <WhatsApp
                    style={{
                      fontSize: "3em",
                    }}
                  />
                </IconAlignContainer>
              </Column>
              <Column className="xs-12 col-auto">
                <TextAlignContainer>
                  <StatsHeader>{context.t(`sms.wpPhone`)}</StatsHeader>
                  <StatsValue>
                    {wp_phone ? wp_phone : context.t(`sms.phoneNotConnected`)}
                  </StatsValue>
                </TextAlignContainer>
              </Column>
            </Grid>
          </StatsCardContainer>
        </Column>
        <Column className="xs-12 md-auto">
          <StatsCardContainer>
            <Grid
              style={{ height: "100%", minHeight: 73 }}
              className="no-gutters-all"
            >
              <Column className="xs-12">
                <IconAlignContainer className="fc-green bg-green-opacity">
                  <WhatsApp
                    style={{
                      fontSize: "3em",
                    }}
                  />
                </IconAlignContainer>
              </Column>
              <Column className="xs-12 col-auto">
                <TextAlignContainer>
                  <StatsHeader>{context.t(`sms.wpCredits`)}</StatsHeader>
                  <StatsValue>{wp_balance}</StatsValue>
                </TextAlignContainer>
              </Column>
            </Grid>
          </StatsCardContainer>
        </Column>
        <Column style={{ position: "relative" }} className="xs-12 md-auto">
          <ButtonBox>
            {!wp_is_connected ? (
              <StyledButton
                open={open_select}
                size="large"
                onClick={() => {
                  setOpenSelect(!open_select);
                }}
                backgroundColor="#3189ec"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <PhonelinkSetupIcon />
                  <div style={{ marginLeft: "5px" }}>
                    {context.t(`sms.addDevice`)}
                  </div>
                </div>
              </StyledButton>
            ) : (
              <StyledButton
                open={open_select}
                size="large"
                onClick={() => {
                  logoutWhatsApp();
                }}
                backgroundColor="#F32323"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <PhonelinkEraseIcon />
                  <div style={{ marginLeft: "5px" }}>
                    {context.t(`sms.removeDevice`)}
                  </div>
                </div>
              </StyledButton>
            )}
          </ButtonBox>

          {open_select && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AnimatedDiv>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ArrowDropDownIcon />
                </div>
                <StyledButtons textColor="white">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                    onClick={() => {
                      setWPCodeDialog(true);
                      setOpenSelect(false);
                    }}
                  >
                    <TbDeviceMobileCheck size={18} />
                    <div style={{ marginLeft: "5px" }}>
                      {context.t(`sms.loginWithNo`)}
                    </div>
                  </div>
                </StyledButtons>
                <StyledButtons
                  onClick={() => {
                    loginWhatsappWithQr();
                    setLoginCheckRegister(true);
                  }}
                  textColor="white"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <BsQrCode />
                    <div style={{ marginLeft: "5px" }}>
                      {" "}
                      {context.t(`sms.loginWithQr`)}{" "}
                    </div>
                  </div>
                </StyledButtons>
              </AnimatedDiv>
            </div>
          )}
        </Column>
      </Grid>

      <Grid>
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].reminder.title`),
            description: context.t(`['sms/wp/settings'].reminder.description`),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].customerSwitchTitle`),
              key: "reminder_customer",
            },
            {
              label: context.t(`['sms/wp/settings'].staffSwitchTitle`),
              key: "reminder_staff",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].longTermReminder.title`),
            description: context.t(
              `['sms/wp/settings'].longTermReminder.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].customerSwitchTitle`),
              key: "reminder_day_ago_customer",
            },
            {
              label: context.t(`['sms/wp/settings'].staffSwitchTitle`),
              key: "reminder_day_ago_staff",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].requestInfo.title`),
            description: context.t(
              `['sms/wp/settings'].requestInfo.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].customerSwitchTitle`),
              key: "on_request_customer",
            },
            {
              label: context.t(`['sms/wp/settings'].staffSwitchTitle`),
              key: "on_request_staff",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].whenApproved.title`),
            description: context.t(
              `['sms/wp/settings'].whenApproved.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].customerSwitchTitle`),
              key: "approve_appointment_customer",
            },
            {
              label: context.t(`['sms/wp/settings'].staffSwitchTitle`),
              key: "approve_appointment_staff",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].whenRejected.title`),
            description: context.t(
              `['sms/wp/settings'].whenRejected.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].customerSwitchTitle`),
              key: "decline_appointment_customer",
            },
            {
              label: context.t(`['sms/wp/settings'].staffSwitchTitle`),
              key: "decline_appointment_staff",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].whenCreated.title`),
            description: context.t(
              `['sms/wp/settings'].whenCreated.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].customerSwitchTitle`),
              key: "on_appointment_customer",
            },
            {
              label: context.t(`['sms/wp/settings'].staffSwitchTitle`),
              key: "on_appointment_staff",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].whenCancelled.title`),
            description: context.t(
              `['sms/wp/settings'].whenCancelled.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].customerSwitchTitle`),
              key: "cancel_appointment_customer",
            },
            {
              label: context.t(`['sms/wp/settings'].staffSwitchTitle`),
              key: "cancel_appointment_staff",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].whenBirthday.title`),
            description: context.t(
              `['sms/wp/settings'].whenBirthday.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].onOffSwitchTitle`),
              key: "reminder_birthday",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].afterAppointment.title`),
            description: context.t(
              `['sms/wp/settings'].afterAppointment.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].onOffSwitchTitle`),
              key: "after_appointment",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].dragAndDrop.title`),
            description: context.t(
              `['sms/wp/settings'].dragAndDrop.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].onOffSwitchTitle`),
              key: "drag_and_drop_appointment_customer",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].appointmentUpdate.title`),
            description: context.t(
              `['sms/wp/settings'].appointmentUpdate.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].customerSwitchTitle`),
              key: "update_appointment_customer",
            },
            {
              label: context.t(`['sms/wp/settings'].staffSwitchTitle`),
              key: "update_appointment_staff",
            },
          ]}
        />
        {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
          <>
            {process.env.APP_NAME === "en" ? null : (
              <HOCSettingBox
                boxProps={{
                  title: context.t(
                    `['sms/wp/settings'].reminderNoncomerCustomer.title`
                  ),
                  description: context.t(
                    `['sms/wp/settings'].reminderNoncomerCustomer.description`
                  ),
                }}
                optionList={[
                  {
                    label: context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                    key: "reminder_noncomer_customer",
                  },
                ]}
              />
            )}
          </>
        )) ||
          null}
        {online_link ? (
          <HOCSettingBox
            boxProps={{
              title: context.t(`['sms/wp/settings'].smsAttendedApproval.title`),
              description: context.t(
                `['sms/wp/settings'].smsAttendedApproval.description`
              ),
            }}
            optionList={[
              {
                label: context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                key: "is_link_included_in_reminder_customer",
              },
            ]}
          />
        ) : (
          <HOCSettingBoxWithNoPermission
            boxProps={{
              title: context.t(`['sms/wp/settings'].smsAttendedApproval.title`),
              description: context.t(
                `['sms/wp/settings'].smsAttendedApproval.description`
              ),
            }}
            optionList={[
              {
                label: context.t(`['sms/wp/settings'].onOffSwitchTitle`),
                key: "is_link_included_in_reminder_customer",
              },
            ]}
          />
        )}
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].whenCustomerCreate.title`),
            description: context.t(
              `['sms/wp/settings'].whenCustomerCreate.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].onOffSwitchTitle`),
              key: "on_customer_create",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].smsShortcode.title`),
            description: context.t(
              `['sms/wp/settings'].smsShortcode.description`
            ),
          }}
          optionList={[
            {
              label: context.t(`['sms/wp/settings'].onOffSwitchTitle`),
              key: "sms_short_code",
            },
          ]}
        />
        <HOCSettingBox
          boxProps={{
            title: context.t(`['sms/wp/settings'].reminderHour.title`),
            description: context.t(
              `['sms/wp/settings'].reminderHour.descripton`
            ),
          }}
        >
          <Select
            className="mb-0"
            label={context.t(`['sms/wp/settings'].reminderHour.inputLabel`)}
            items={[
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[0]`),
                value: "0",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[1]`),
                value: "1",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[2]`),
                value: "2",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[3]`),
                value: "3",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[4]`),
                value: "4",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[5]`),
                value: "5",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[6]`),
                value: "6",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[7]`),
                value: "7",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[8]`),
                value: "8",
              },
              {
                label: context.t(`['sms/wp/settings'].reminderHour.options[9]`),
                value: "9",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[10]`
                ),
                value: "10",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[11]`
                ),
                value: "11",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[12]`
                ),
                value: "12",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[13]`
                ),
                value: "13",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[14]`
                ),
                value: "14",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[15]`
                ),
                value: "15",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[16]`
                ),
                value: "16",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[17]`
                ),
                value: "17",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[18]`
                ),
                value: "18",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[19]`
                ),
                value: "19",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[20]`
                ),
                value: "20",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[21]`
                ),
                value: "21",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[22]`
                ),
                value: "22",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[23]`
                ),
                value: "23",
              },
              {
                label: context.t(
                  `['sms/wp/settings'].reminderHour.options[24]`
                ),
                value: "24",
              },
            ]}
            selected={`${reminder}`}
            labelKey="label"
            valueKey="value"
            handler={(reminder) => setReminder(parseInt(reminder))}
          />
        </HOCSettingBox>
        <Column className="xs-12 sm-12">
          <Button
            iconComponent={Update}
            title={context.t(`['sms/wp/settings'].updateButtonTitle`)}
            backgroundColor="primary"
            textColor="white"
            fullWidth
            onClick={() => saveSmsSettings()}
          />
        </Column>
      </Grid>
      <QRCodeDialog />
      <CodeDialog />
      <ImageDialog />
      <Dialog
        open={wp_loader}
        PaperProps={{
          style: {
            backgroundColor: "transparent", // Arka plan rengini şeffaf olarak ayarlar
            boxShadow: "none", // Gölgeyi kaldırır
          },
        }}
      >
        <AlignContainer>
          <img
            src={
              process.env.APP_NAME === "salon"
                ? Loader
                : process.env.APP_NAME === "en"
                ? LoaderEN
                : LoaderSM
            }
            width="100"
            height="100"
            alt="loading"
          />
        </AlignContainer>
      </Dialog>
    </AuthContainer>
  );
};

export default SmsSettings;

const AccountBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SettingsBox = styled.div`
  width: 100%;
  min-height: 147px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  padding: 16px;
  margin: 0 0 16px;
  box-sizing: border-box;

  border-radius: 5px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);

  h3 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 4px;
  }

  p {
    color: #808080;
    font-size: 12.6px;
    line-height: 1.614;
    margin: 0 0 8px;
  }
`;

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsHeader = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #aaa;
  padding-bottom: 10px;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;

const InfoBox = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: auto;
  padding: 0;
  color: #a0a0a0;
  display: flex;
  justify-content: center;
  align-item: center;
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  /* Responsive styles */
  ${(props) =>
    props.open &&
    css`
      @media (max-width: 768px) {
        padding: 12px;
      }
    `}
`;
const AnimatedDiv = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out forwards;
  position: absolute;
  height: 200%;
  width: 70%;
  top: 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  backdrop-filter: blur(5px);

  @media (max-width: 1350px) {
    top: 100px;
  }
  @media (max-width: 1000px) {
    top: 120px;
  }
  @media (max-width: 768px) {
    top: 50px;
  }
`;
const StyledButtons = styled.button`
  background-color: ${(props) =>
    props.open ? "rgb(270,230,140)" : "rgb(270,230,140)"};
  width: 100%;
  color: black;
  padding: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  &:hover {
    opacity: 0.8;
  }

  ${(props) =>
    props.open &&
    css`
      @media (max-width: 768px) {
        padding: 12px;
      }
    `}
`;
const AlignContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const HoverableImage = styled.img`
  width: 70px; /* Örneğin */
  // height: 120px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.2); /* Büyütme oranı buradan ayarlanabilir */
  }
`;
