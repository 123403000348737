/* eslint-disable array-callback-return */
import { useContext } from "react";
import { toast } from "react-toastify";
import AppContext from "../context/store";

const validify = require("validify");
// const CryptoJS = require("crypto-js");

const Validate = (fields, context) => {
  if (fields.length === 0) {
    return false;
  }

  let fieldsArray = fields.map((field) => {
    if (
      ((field.value === "" || field.value === null) &&
        field.type === "email") ||
      ((field.value === "" || field.value === null) &&
        field.type === "password")
    ) {
      toast.error(context.t(`validation.enterField`));
      return false;
    } else {
      if (field.type === "email") {
        if (field.required) {
          if (field.value === "") {
            toast.error(context.t(`validation.wrongEmailOrPassword`));
            return false;
          }
        }
        if (!validify(field.value, "email")) {
          toast.error(`${field.label} must be a valid email`);
          return false;
        }
      } else if (field.type === "password") {
        if (field.required) {
          if (field.value === null) {
            toast.error(context.t(`validation.wrongPassword`));
            return false;
          }
        }
        if (!validify(field.value, "password")) {
          toast.error(`${field.label} must be a valid password`);
          return false;
        }
      }
    }
  });

  return fieldsArray[0] === undefined && fieldsArray[1] === undefined;
};

export default Validate;
