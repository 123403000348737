import React, { useContext, useState } from "react";
import AppContext from "../../../context/store";

import { Column, Grid } from "../../../theme/Grid";
import AuthContainer from "../../../components/AuthContainer";

import "../../../assets/style/component/Form.scss";
import Button from "../../../theme/Button";
import Input from "../../../theme/CustomMUI/Input";

const PasswordSettings = () => {
  const context = useContext(AppContext);

  const [pass, setPass] = useState({
    current_password: null,
    new_password: null,
    confirm_password: null,
  });

  return (
    <AuthContainer authorities={[1]} limited_permission="system">
      <Grid>
        <Column className="xs-12 sm-12">
          <Input
            label={context.t(`settings.currentPassword`)}
            type="password"
            onChange={(e) => {
              setPass({
                ...pass,
                current_password: e.target.value,
              });
            }}
          />
          <Input
            label={context.t(`settings.newPassword`)}
            type="password"
            onChange={(e) => {
              setPass({
                ...pass,
                new_password: e.target.value,
              });
            }}
          />

          <Input
            label={context.t(`settings.newPasswordAgain`)}
            type="password"
            onChange={(e) => {
              setPass({
                ...pass,
                confirm_password: e.target.value,
              });
            }}
          />

          <Button
            icon="update"
            title={context.t(`settings.updateInfo`)}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default PasswordSettings;
