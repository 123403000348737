import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import styled from "styled-components";
import AppContext from "../../context/store";
import Button from "../../theme/Button";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import { useHistory } from "react-router-dom";
import HomeChcekGreen from "../../assets/images/svg/home-check-green.svg";
import NotComeRed from "../../assets/images/svg/not-come-red.svg";
import ApprovedBlue from "../../assets/images/svg/approved-blue.svg";
import ProcessPurple from "../../assets/images/svg/process-purple.svg";
import Quit from "../../assets/images/svg/quit.svg";
import WaitingOrange from "../../assets/images/svg/waiting-orange.svg";
import { Grid, Column } from "../../theme/Grid";
import { TimerOff, Launch, WhatsApp, SmsOutlined } from "@material-ui/icons";
import { FormLabel, TextField, MenuItem } from "@material-ui/core";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import DatePickerInput from "../../theme/CustomMUI/DatePickerInput";
import { toast } from "react-toastify";
import TimeSelect from "../../theme/TimeSelect";
import Input from "../../theme/CustomMUI/Input";
import moment from "moment";
import CSwitch from "../../theme/CustomMUI/CSwitch";
import Select from "../../theme/CustomMUI/Select";
import CalculateMessageCharCount from "../../functions/CalculateMessageCharCount";
import SMSReview from "../../components/Dialogs/SMSReview";
import { phoneNumberDisplay } from "../../functions/PhoneNumberDisplay";
import { Alert } from "@material-ui/lab";

function AppointmentCardDetail({
  _appointment,
  set_Appointment,
  updateAppointmentState,
  setAppointmentDetailModal,
  setCreateAppointmentModal,
  appointmentDetailModal,
  hourDetail,
  setHourDetail,
  weeklyAppointments,
  timeUpgrade,
  setTimeUpgrade,
  selectedStaff,
  getAppointmentsForSelectBox,
  setAppointmentDate,
  appointmentDate,
  shortCode,
  smsTemplates,
}) {
  const context = useContext(AppContext);
  const history = useHistory();
  const [updateAppointDisable, setUpdateAppointDisable] = useState(true);
  const [changeEndHour, setChangeEndHour] = useState(false);
  const [sendSms, setSendSms] = useState(true);
  const [appointmentNote, setAppointmentNote] = useState(null);
  const [appointmentReminderState, setAppointmentReminderState] =
    useState(null);
  const [whatsappSettings, setWhatsappSettings] = useState({
    message: "",
    phone_number: null,
    foreign: null,
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [whatsappConDialog, setWhatsappConDialog] = useState(false);
  const [smsSendDialog, setSmsSendDialog] = useState(false);
  const [totalNumber, setTotalNumber] = useState(null);
  const [totalSmsCredit, setTotalSmsCredit] = useState(null);
  const [openSmsReviewDialog, setOpenSmsReviewDialog] = useState(false);
  const [smsSettings, setSmsSettings] = useState({
    message: "",
    id: [],
  });
  const [cancelAppointmentArray, setCancelAppointmentArray] = useState([]);
  const [cancelAppointmentDialog, setCancelAppointmentDialog] = useState(false);
  const [openPacketApointment, setOpenPacketApointment] = useState(false);
  const [payloadObjectPackage, setPayloadObjectPackage] = useState([]);

  useEffect(() => {
    if (appointmentDetailModal) {
      // setCreateAppointmentModal(false);
      setAppointmentReminderState(_appointment?.reminding);
    } else {
      setWhatsappSettings({
        message: "",
        phone_number: null,
        foreign: null,
      });
      setSmsSettings({
        message: "",
        id: [],
      });
    }
  }, [appointmentDetailModal]);

  //TODO: Update Appointment
  const updateAppoint = async (apoint_id) => {
    const time = parseInt(timeUpgrade);
    const timeHours =
      parseInt(
        Math.floor(
          moment(_appointment.appointmentEndDate)
            .format("HH:mm")
            .split(":")[0] * 60
        ) +
          parseInt(
            moment(_appointment.appointmentEndDate)
              .format("HH:mm")
              .split(":")[1]
          )
      ) -
      parseInt(
        Math.floor(
          moment(_appointment.appointmentStartDate)
            .format("HH:mm")
            .split(":")[0] * 60
        ) +
          parseInt(
            moment(_appointment.appointmentStartDate)
              .format("HH:mm")
              .split(":")[1]
          )
      );

    let timeAppointmentStart = new Date(appointmentDate);
    timeAppointmentStart = `${moment(timeAppointmentStart).format(
      "YYYY-MM-DD"
    )}T${hourDetail.start_hour}:00Z`;

    let timeAppointmentEnd = new Date(appointmentDate);
    timeAppointmentEnd = `${moment(timeAppointmentEnd).format("YYYY-MM-DD")}T${
      hourDetail.end_hour
    }:00Z`;

    if (hourDetail.start_hour > hourDetail.end_hour) {
      toast.warning("Bitiş saati Başlangıç saatinden küçük olamaz!");
    } else {
      try {
        const { data } = await Axios.put(
          `${context.api_endpoint}/company/appointment/update`,
          isNaN(time)
            ? {
                id: apoint_id ? apoint_id : 1,
                appointment_start_date: timeAppointmentStart,
                appointment_end_date: timeAppointmentEnd,
                send_sms: sendSms,
                note: appointmentNote,
              }
            : {
                id: apoint_id ? apoint_id : 1,
                appointment_start_date: timeAppointmentStart,
                send_sms: sendSms,
                appointment_time: time ? time : timeHours,
                note: appointmentNote,
              }
        );

        if (data.status === 201) {
          setAppointmentDetailModal(false);
          setCreateAppointmentModal(false);
          setTimeUpgrade(null);
          setAppointmentNote(null);
          getAppointmentsForSelectBox();
          setAppointmentReminderState(null);
          toast.success(
            context.t(
              `appointments.updateAppointment.updateAppointmentSuccessToast`
            )
          );
        }
      } catch (error) {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        } else {
          toast.warning(error.response.data.message);
        }
      }
    }
  };

  // sms review
  const getSMSReview = async () => {
    try {
      const response = await Axios.post(
        `${context.api_endpoint}/company/message/to/customer/preview`,
        {
          all: false,
          id: smsSettings.id,
          except: [],
          message: smsSettings.message,
          sms_prefer: 1,
        }
      );
      if (response.status === 200) {
        setTotalNumber(response.data.data.total_numbers);
        setTotalSmsCredit(response.data.data.total_sms_credit);
        setOpenSmsReviewDialog(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.message);
      } else {
        toast.warning(error.response.data.message);
      }
    }
  };

  // SMS final send
  const sendSelectiveSms = async () => {
    await Axios.post(`${context.api_endpoint}/company/message/to/customers`, {
      all: false,
      id: smsSettings.id,
      except: [],
      message: smsSettings.message,
      sms_prefer: 1,
      send_date: null,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
        } else {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.message);
        } else {
          toast.warning(error.response.data.message);
        }
      });
  };
  const cancelAppointment = async (appointment_id) => {
    await Axios.post(`${context.api_endpoint}/company/appointment/cancel`, {
      appointment_id,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.deleteToast`));
          getAppointmentsForSelectBox();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  const openReceiptByAppointment = async (appointment_id) => {
    if (
      _appointment.customer_state === 0 ||
      _appointment.customer_state === 4
    ) {
      await Axios.post(
        `${context.api_endpoint}/company/receipt/insert/by/appointment`,
        _appointment.packet !== null
          ? {
              appointment_id,
              period_day_between: payloadObjectPackage,
            }
          : {
              appointment_id,
            }
      )
        .then((response) => {
          if (response.status === 201) {
            toast.success(context.t(`dashboard.openReceiptOnSuccessToast`));
            history.push(`/receipts/detail/${response.data.data.id}`);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }
    if (
      _appointment.customer_state !== 0 &&
      _appointment.customer_state !== 4
    ) {
      toast.warning(
        "Bu randevuya adisyon açılamaz. Adisyon açılabilmesi için Randevu Durumunun 'Geldi' veya 'İşlemde' olması gerekir."
      );
    }
  };

  return (
    <>
      {/* Randevu Kartı Detay */}
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        fullWidth={true}
        maxWidth="md"
        title={
          _appointment && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h2 className="MuiTypography-root MuiTypography-h6">
                  {context.t(`appointments.detail.title`)}
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {process.env.APP_NAME !== "management" && (
                  <div>
                    <Button
                      title={"Randevu işlem kayıtlarına git"}
                      icon="receipt"
                      textColor="primary"
                      fullWidth
                      onClick={() => {
                        context.dispatch({
                          type: "LOG_TYPE",
                          payload: { log_type: 4 },
                        });
                        context.dispatch({
                          type: "LOG_ID",
                          payload: {
                            log_id: Number(_appointment.id),
                          },
                        });
                        history.push("/settings/logs");
                      }}
                    />
                  </div>
                )}
                {_appointment && (
                  <>
                    {_appointment.customer_state === 0 ? (
                      <DetailDiv
                        style={{
                          backgroundColor: "rgba(181, 206, 180, 0.65)",
                        }}
                      >
                        <img src={HomeChcekGreen} alt="home-check-green" />
                        <span
                          style={{
                            color: "#628F5F",
                          }}
                        >
                          Geldi
                        </span>
                      </DetailDiv>
                    ) : (
                      <>
                        {_appointment.customer_state === 1 ? (
                          <DetailDiv
                            style={{
                              backgroundColor: "rgba(220, 145, 148, 0.65)",
                            }}
                          >
                            <img src={NotComeRed} alt="note-come-red" />
                            <span
                              style={{
                                color: "#D4242B",
                              }}
                            >
                              Gelmedi
                            </span>
                          </DetailDiv>
                        ) : (
                          <>
                            {_appointment.customer_state === 3 ? (
                              <DetailDiv
                                style={{
                                  backgroundColor: "rgba(208, 233, 233, 0.65)",
                                }}
                              >
                                <img src={ApprovedBlue} alt="approved-blue" />
                                <span
                                  style={{
                                    color: "#54AEAE",
                                  }}
                                >
                                  Onaylı
                                </span>
                              </DetailDiv>
                            ) : (
                              <>
                                {_appointment.customer_state === 4 ? (
                                  <DetailDiv
                                    style={{
                                      backgroundColor:
                                        "rgba(198, 163, 233, 0.65)",
                                    }}
                                  >
                                    <img
                                      style={{
                                        transform: "scaleX(-1)",
                                      }}
                                      src={ProcessPurple}
                                      alt="process-purple"
                                    />
                                    <span
                                      style={{
                                        color: "#7A45AE",
                                      }}
                                    >
                                      İşlemde
                                    </span>
                                  </DetailDiv>
                                ) : (
                                  <DetailDiv
                                    style={{
                                      backgroundColor:
                                        "rgba(255, 226, 188, 0.65)",
                                    }}
                                  >
                                    <img
                                      src={WaitingOrange}
                                      alt="waiting-orange"
                                    />
                                    <span
                                      style={{
                                        color: "#FFB95E",
                                      }}
                                    >
                                      Yeni
                                    </span>
                                  </DetailDiv>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                <Button
                  title={"Kaydet"}
                  backgroundColor="primary-opacity"
                  disabled={updateAppointDisable}
                  textColor="primary"
                  style={{
                    width: "120px",
                    marginLeft: "15px",
                  }}
                  fullWidth
                  onClick={() => {
                    setChangeEndHour(false);
                    setUpdateAppointDisable(true);
                    updateAppoint(_appointment.id);
                  }}
                />
                <img
                  src={Quit}
                  alt="quit"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "35px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setAppointmentDetailModal(false);
                    setCreateAppointmentModal(false);
                    setAppointmentNote(null);
                    setAppointmentReminderState(null);
                  }}
                />
              </div>
            </div>
          )
        }
        open={appointmentDetailModal}
        closeHandler={() => {
          setAppointmentDetailModal(false);
          setCreateAppointmentModal(false);
          setChangeEndHour(false);
          setAppointmentNote(null);
          setAppointmentReminderState(null);
        }}
        disableBackdropClick={false}
        buttons={[
          {
            show:
              context.state.FLAGS.SALON_RANDEVU |
              context.state.FLAGS.EN_RANDEVU,
            iconComponent: Launch,
            title: `${_appointment?.receipt ? "Adisyona Git" : "Adisyon Aç"}`,
            backgroundColor: "primary-opacity",
            textColor: "primary",

            onClick: () => {
              if (_appointment?.receipt) {
                history.push(`/receipts/detail/${_appointment?.receipt.id}`);
              } else if (
                context.state.company_license.accounting === 1 ||
                context.state.company_license.accounting === 3
              ) {
                if (_appointment.packet !== null) {
                  setOpenPacketApointment(true);
                  setPayloadObjectPackage(
                    _appointment.packet.packet_details.map((m) => ({
                      id: m?.service?.id,
                      time: 7,
                    }))
                  );
                } else {
                  openReceiptByAppointment(_appointment.id);
                }
              } else {
                toast.warning(
                  "Bu özelliği kullanabilmek için Premium paket satın almalısınız."
                );
              }
            },
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            iconComponent: TimerOff,
            title: context.t(`appointments.detail.deleteButtonTitle`),
            backgroundColor: "red-opacity",
            textColor: "red",
            onClick: () => setCancelAppointmentDialog(true),
          },
          {
            show:
              context.state.FLAGS.EN_RANDEVU |
              context.state.FLAGS.SALON_RANDEVU,
            title: "Randevu Geçmişi",
            icon: "event",
            backgroundColor: "purple-opacity",
            textColor: "purple",
            onClick: () =>
              history.push(`/customers/detail/${_appointment.customer.id}`, {
                tab: 2,
              }),
          },
        ].filter((btn) => btn.show & context.app.ID)}
      >
        {_appointment && (
          <>
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <DatePickerInput
                  label={context.t(`appointments.detail.dateInputLabel`)}
                  value={appointmentDate}
                  format="DD MMMM dddd HH:mm"
                  onChange={(selectedDateElement) => {
                    setAppointmentDate(selectedDateElement);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <TimeSelect
                  label={context.t(`['settings/hours'].startHour`)}
                  type="all"
                  value={
                    hourDetail.start_hour !== null ? hourDetail.start_hour : ""
                  }
                  fullWidth={true}
                  size="medium"
                  minuteStep={1}
                  handler={(time) => {
                    setHourDetail((prevState) => ({
                      ...prevState,
                      start_hour: time,
                    }));
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <Input
                  type="number"
                  disabled={changeEndHour}
                  label={context.t(`appointments.detail.appointmentTime`)}
                  placeholder={context.t(
                    `appointments.detail.appointmentTimeExtension`
                  )}
                  value={
                    timeUpgrade !== null
                      ? timeUpgrade
                      : parseInt(
                          Math.floor(
                            moment(_appointment.appointment_end_date)
                              .format("HH:mm")
                              .split(":")[0] * 60
                          ) +
                            parseInt(
                              moment(_appointment.appointment_end_date)
                                .format("HH:mm")
                                .split(":")[1]
                            )
                        ) -
                        parseInt(
                          Math.floor(
                            moment(_appointment.appointment_start_date)
                              .format("HH:mm")
                              .split(":")[0] * 60
                          ) +
                            parseInt(
                              moment(_appointment.appointment_start_date)
                                .format("HH:mm")
                                .split(":")[1]
                            )
                        )
                  }
                  onChange={(e) => {
                    setTimeUpgrade(e.target.value);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                <TimeSelect
                  label={context.t(`['settings/hours'].endHour`)}
                  type="all"
                  disabled={timeUpgrade === null ? false : true}
                  value={
                    hourDetail.end_hour !== null ? hourDetail.end_hour : ""
                  }
                  fullWidth={true}
                  size="medium"
                  minuteStep={1}
                  handler={(time) => {
                    setHourDetail((prevHourDetail) => ({
                      ...prevHourDetail,
                      end_hour: time,
                    }));
                    setChangeEndHour(true);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
            </Grid>

            <Grid className="mb-2">
              <Column className="xs-12 sm-12 md-12 lg-5">
                <CSwitch
                  label={context.t(`appointments.create.sendSmsInputLabel`)}
                  checked={
                    (
                      appointmentReminderState
                        ? appointmentReminderState === 1
                        : _appointment.reminding === 1
                    )
                      ? false
                      : true
                  }
                  color="primary"
                  onChange={(sendSmsElement) => {
                    if (
                      appointmentReminderState
                        ? appointmentReminderState === 1
                        : _appointment.reminding === 1
                    ) {
                      setAppointmentReminderState(2);
                    } else {
                      setAppointmentReminderState(1);
                    }

                    setSendSms(sendSmsElement.target.checked);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>

              <Column
                className="sx-12 sm-12 md-12 lg-7"
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "flex-end",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      borderRadius: "5px",
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "7px",
                      marginRight: "10px",
                      boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.2)",
                    }}
                    onClick={() => {
                      context.state.whatsapp_permission
                        ? setWhatsappConDialog(true)
                        : toast.warn(
                            `Ücretli içerik aktif değil. Lütfen temsilciniz ile iletişime geçiniz.`
                          );
                    }}
                  >
                    <WhatsApp
                      style={{
                        fontSize: "2em",
                        color: "green",
                      }}
                    />
                    <p
                      style={{
                        color: "green",
                        fontWeight: "bolder",
                        margin: "0 7px",
                      }}
                    >
                      WhatsApp
                    </p>
                  </div>
                  {_appointment.customer.phone === "" ||
                  _appointment.customer.phone === null ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        cursor: "pointer",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "7px",
                        boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.2)",
                      }}
                      onClick={() => {
                        setSmsSendDialog(true);
                        setSmsSettings((prevSmsSettings) => ({
                          ...prevSmsSettings,
                          id: [_appointment.customer.id],
                        }));
                      }}
                    >
                      <SmsOutlined
                        style={{ fontSize: "2em", color: "orange" }}
                      />
                      <p
                        style={{
                          color: "orange",
                          fontWeight: "bolder",
                          margin: "0 7px",
                        }}
                      >
                        SMS Gönder
                      </p>
                    </div>
                  )}
                </div>
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              {_appointment.service && (
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  <DetailCard
                    icon="layers"
                    color="purple"
                    label={context.t(`appointments.detail.serviceInputLabel`)}
                    value={_appointment.service?.name ?? "-"}
                    sabValue={
                      _appointment.service?.amount
                        ? `${_appointment.service?.amount} TL`
                        : "0"
                    }
                  />
                </Column>
              )}
              {_appointment.packet && (
                <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                  <DetailCard
                    icon={<LocalMallIcon />}
                    color="pink"
                    label="Uygulanacak Paket"
                    value={_appointment.packet?.name ?? "-"}
                    sabValue={
                      _appointment.packet?.all_amount
                        ? `${_appointment.packet?.all_amount} TL`
                        : "0"
                    }
                  />
                </Column>
              )}
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <DetailCard
                  icon="how_to_reg"
                  color="red"
                  label={context.t(`appointments.detail.staffInputLabel`)}
                  value={
                    weeklyAppointments !== true
                      ? selectedStaff?.full_name ?? "-"
                      : _appointment.staff?.full_name ?? "-"
                  }
                />
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <DetailCard
                  icon="person"
                  color="blue"
                  label={context.t(`appointments.detail.customerInputLabel`)}
                  value={_appointment.customer?.full_name ?? "-"}
                  iconValue={"edit"}
                  click={() => {
                    history.push(
                      `/customers/detail/${_appointment.customer.id}`
                    );
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                {" "}
                <DetailCard
                  icon="call"
                  color="green"
                  label={context.t(
                    `appointments.detail.customerPhoneInputLabel`
                  )}
                  value={
                    context.state.user.permission === 2 &&
                    context.state.limited_permission.customer_info === 0
                      ? phoneNumberDisplay(_appointment.customer.phone)
                      : _appointment.customer
                      ? `0${_appointment.customer.phone}`
                      : "-"
                  }
                />
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <Input
                  label={context.t(`appointments.detail.noteInputLabel`)}
                  placeholder={"Randevu Notu Giriniz"}
                  value={
                    appointmentNote !== null
                      ? appointmentNote
                      : _appointment.note
                  }
                  onChange={(e) => {
                    setAppointmentNote(e.target.value);
                    setUpdateAppointDisable(false);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
                <DetailCard
                  icon="description"
                  color="black"
                  label={context.t(`appointments.detail.customerNote`)}
                  value={
                    _appointment.customer.description !== null &&
                    _appointment.customer.description !== undefined &&
                    _appointment.customer.description !== ""
                      ? _appointment.customer.description
                      : "-"
                  }
                  iconValue={"edit"}
                  click={() => {
                    history.push(
                      `/customers/detail/${_appointment.customer.id}`
                    );
                  }}
                />
              </Column>
            </Grid>

            <FormLabel component="legend">
              {context.t(`appointments.detail.appointmentStatus.title`)}
            </FormLabel>
            <DivStatus className="mt-2 mb-2">
              {_appointment.customer_state === 0 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(181, 206, 180, 0.65)",
                    }}
                  >
                    <img src={HomeChcekGreen} alt="home-check-green" />
                    <span
                      style={{
                        color: "#628F5F",
                      }}
                    >
                      Geldi
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      set_Appointment((prevState) => ({
                        ...prevState,
                        customer_state: 0,
                      }));

                      updateAppointmentState(_appointment.id, 0);
                    }}
                  >
                    <img src={HomeChcekGreen} alt="home-check-green" />
                    <span
                      style={{
                        color: "#628F5F",
                      }}
                    >
                      Geldi
                    </span>
                  </DetailDivOption>
                </Column>
              )}
              {_appointment.customer_state === 1 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(220, 145, 148, 0.65)",
                    }}
                  >
                    <img src={NotComeRed} alt="note-come-red" />
                    <span
                      style={{
                        color: "#D4242B",
                      }}
                    >
                      Gelmedi
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      set_Appointment((prevState) => ({
                        ...prevState,
                        customer_state: 1,
                      }));

                      updateAppointmentState(_appointment.id, 1);
                    }}
                  >
                    <img src={NotComeRed} alt="note-come-red" />
                    <span
                      style={{
                        color: "#D4242B",
                      }}
                    >
                      Gelmedi
                    </span>
                  </DetailDivOption>
                </Column>
              )}
              {_appointment.customer_state === 2 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(255, 226, 188, 0.65)",
                    }}
                  >
                    <img src={WaitingOrange} alt="waiting-orange" />
                    <span
                      style={{
                        color: "#FFB95E",
                      }}
                    >
                      Yeni
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      set_Appointment((prevState) => ({
                        ...prevState,
                        customer_state: 2,
                      }));

                      updateAppointmentState(_appointment.id, 2);
                    }}
                  >
                    <img src={WaitingOrange} alt="waiting-orange" />
                    <span
                      style={{
                        color: "#FFB95E",
                      }}
                    >
                      Yeni
                    </span>
                  </DetailDivOption>
                </Column>
              )}
              {_appointment.customer_state === 3 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(208, 233, 233, 0.65)",
                    }}
                  >
                    <img src={ApprovedBlue} alt="approved-blue" />
                    <span
                      style={{
                        color: "#54AEAE",
                      }}
                    >
                      Onaylı
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      set_Appointment((prevState) => ({
                        ...prevState,
                        customer_state: 3,
                      }));

                      updateAppointmentState(_appointment.id, 3);
                    }}
                  >
                    <img src={ApprovedBlue} alt="approved-blue" />
                    <span
                      style={{
                        color: "#54AEAE",
                      }}
                    >
                      Onaylı
                    </span>
                  </DetailDivOption>
                </Column>
              )}
              {_appointment.customer_state === 4 ? (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      backgroundColor: "rgba(198, 163, 233, 0.65)",
                    }}
                  >
                    <img
                      style={{
                        transform: "scaleX(-1)",
                      }}
                      src={ProcessPurple}
                      alt="process-purple"
                    />
                    <span
                      style={{
                        color: "#7A45AE",
                      }}
                    >
                      İşlemde
                    </span>
                  </DetailDivOption>
                </Column>
              ) : (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DetailDivOption
                    style={{
                      width: "25%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      set_Appointment((prevState) => ({
                        ...prevState,
                        customer_state: 4,
                      }));

                      updateAppointmentState(_appointment.id, 4);
                    }}
                  >
                    <img
                      style={{
                        transform: "scaleX(-1)",
                      }}
                      src={ProcessPurple}
                      alt="process-purple"
                    />
                    <span
                      style={{
                        color: "#7A45AE",
                      }}
                    >
                      İşlemde
                    </span>
                  </DetailDivOption>
                </Column>
              )}
            </DivStatus>
            {/* WhatsApp pop-up */}
          </>
        )}
      </AlertDialog>
      <AlertDialog
        title={
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-8">
              Whatsapp ile iletişime geç
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-2"></Column>
            <Column className="xs-12 sm-12 md-12 lg-2">
              <WhatsApp
                style={{
                  color: "green",
                  fontSize: "2.2em",
                }}
              />
            </Column>
          </Grid>
        }
        open={whatsappConDialog}
        fullWidth
        maxWidth="sm"
        closeHandler={() => setWhatsappConDialog(false)}
        buttons={[
          {
            title: "Gönder",
            icon: "send",
            backgroundColor: "green",
            style: {
              height: "40px",
            },
            textColor: "white",
            onClick: () =>
              _appointment.customer.phone !== ""
                ? window.open(
                    `https://api.whatsapp.com/send?phone=${_appointment.customer.phone}&text=${whatsappSettings.message}`,
                    "_blank"
                  )
                : window.open(
                    `https://api.whatsapp.com/send?phone=${_appointment.customer.secondary_phone}&text=${whatsappSettings.message}`,
                    "_blank"
                  ),
          },
          {
            title: "İptal",
            icon: "close",
            backgroundColor: "white",
            style: {
              border: "1px solid #666666",
              height: "40px",
            },
            textColor: "black",
            onClick: () => setWhatsappConDialog(false),
          },
        ]}
      >
        <Grid>
          <Column className="sx-12 sm-12 md-12 lg-4">
            <Select
              label={context.t(`sms.send.template`)}
              items={[...smsTemplates]}
              selected={selectedTemplate?.name ?? ""}
              labelKey="name"
              valueKey="name"
              returnFullObject
              handler={(template) => {
                setSelectedTemplate(template);
                setWhatsappSettings((prevSettings) => ({
                  ...prevSettings,
                  message: template.content,
                }));
              }}
            />
          </Column>
          <Column className="sx-12 sm-12 md-12 lg-5"></Column>
          <Column className="sx-12 sm-12 md-12 lg-12">
            <Input
              label={context.t(`sms.send.messageInputLabel`)}
              multiline={true}
              rows={10}
              value={whatsappSettings.message}
              onChange={(e) =>
                setWhatsappSettings({
                  ...smsSettings,
                  message: e.target.value,
                })
              }
            />
          </Column>
        </Grid>
      </AlertDialog>
      {/* SMS pop-up */}
      <AlertDialog
        title={"SMS Gönder"}
        open={smsSendDialog}
        fullWidth
        maxWidth="sm"
        closeHandler={() => setSmsSendDialog(false)}
        buttons={[
          {
            title: "Gönder",
            icon: "send",
            backgroundColor: "green",
            style: {
              height: "40px",
            },
            textColor: "white",
            onClick: () => getSMSReview(),
          },
          {
            title: "İptal",
            icon: "close",
            backgroundColor: "white",
            style: {
              border: "1px solid #666666",
              height: "40px",
            },
            textColor: "black",
            onClick: () => setSmsSendDialog(false),
          },
        ]}
      >
        <Grid>
          <Column className="sx-12 sm-12 md-12 lg-4">
            <Select
              label={context.t(`sms.send.template`)}
              items={[...smsTemplates]}
              selected={selectedTemplate?.name ?? ""}
              labelKey="name"
              valueKey="name"
              returnFullObject
              handler={(template) => {
                setSelectedTemplate(template);
                setSmsSettings((prevSmsSettings) => ({
                  ...prevSmsSettings,
                  message: template.content,
                }));
              }}
            />
          </Column>
          <Column className="sx-12 sm-12 md-12 lg-5"></Column>
          <Column
            className="sx-12 sm-12 md-12 lg-3 mb-2"
            style={{ display: "flex", alignItems: "end" }}
          >
            <Button
              className="mb-1 ml-1"
              title={context.t(`sms.send.autoText.shortCode`)}
              size="xs"
              textColor="purple"
              outlined
              disabled={shortCode === ""}
              onClick={() => {
                setSmsSettings((prevSmsSettings) => ({
                  ...prevSmsSettings,
                  message: `${prevSmsSettings.message} SMS iptali için ${shortCode} yazın 4607'a gönderin.`,
                }));
              }}
            />
          </Column>
          <Column className="sx-12 sm-12 md-12 lg-12">
            <Input
              label={context.t(`sms.send.messageInputLabel`)}
              multiline={true}
              rows={10}
              value={smsSettings.message}
              onChange={(e) =>
                setSmsSettings((prevSmsSettings) => ({
                  ...prevSmsSettings,
                  message: e.target.value,
                }))
              }
              helperText={CalculateMessageCharCount(smsSettings.message, "1")}
            />
          </Column>
        </Grid>
      </AlertDialog>
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="sm"
        fullWidth={true}
        title={context.t(`appointments.deleteConfirm.title`)}
        open={cancelAppointmentDialog}
        closeHandler={() => setCancelAppointmentDialog(false)}
        buttons={[
          {
            title: context.t(`appointments.deleteConfirm.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => {
              setCancelAppointmentArray((prevCancelAppointmentArray) => [
                ...prevCancelAppointmentArray,
                _appointment.id,
              ]);
              if (!cancelAppointmentArray.includes(_appointment.id)) {
                cancelAppointment(_appointment.id);
              }
            },
          },
          {
            title: context.t(`appointments.deleteConfirm.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>{context.t(`appointments.deleteConfirm.alertBoldText`)}</b>
          <br />
          {context.t(`appointments.deleteConfirm.alertText`)}
        </Alert>
      </AlertDialog>
      {/*  Select period on packages */}
      <AlertDialog
        style={{ zIndex: "12 !important" }}
        maxWidth="xs"
        fullWidth={true}
        className="bringBack"
        buttonDisabledTimeout
        open={openPacketApointment}
      >
        {/*  Hizmetlerin seans aralığını seçin.*/}
        <Alert severity="warning">
          <p style={{ fontSize: "14px" }}>
            Adisyon açabilmek için paket içerisindeki hizmetlerin seans
            aralığını belirlemeniz gerekmektedir.
          </p>
        </Alert>
        {_appointment?.packet?.packet_details.map((m, i) => (
          <Grid
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            key={i}
          >
            <Column className="sx-12 sm-12 md-12 lg-7">
              <p style={{ fontSize: "10px" }}>Hizmet Adı</p>

              <h4>{m?.service?.name}</h4>
            </Column>
            <Column className="sx-12 sm-12 md-12 lg-5">
              <TextField
                select
                fullWidth
                label="Seans Aralığı (Gün)"
                value={payloadObjectPackage[i]?.time}
                onChange={(e) => {
                  const updatedPayload = [...payloadObjectPackage];
                  updatedPayload[i] = {
                    ...updatedPayload[i],
                    time: Number(e.target.value),
                  };
                  setPayloadObjectPackage(updatedPayload);
                }}
              >
                {Array.from({ length: 50 }, (_, index) => index + 1).map(
                  (number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Column>
          </Grid>
        ))}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Button
            title="Onayla"
            textColor="primary"
            backgroundColor="primary-opacity"
            fullWidth
            onClick={() => {
              context.state.company_license.accounting === 1 ||
              context.state.company_license.accounting === 3
                ? _appointment.packet !== null &&
                  openReceiptByAppointment(_appointment.id)
                : toast.warning(
                    "Bu özelliği kullanabilmek için Premium paket satın almalısınız."
                  );
            }}
          />

          <Button
            onClick={() => setOpenPacketApointment(false)}
            fullWidth
            backgroundColor="red-opacity"
            textColor="red"
            title="Vazgeç"
          />
        </div>
      </AlertDialog>
      <SMSReview
        open={openSmsReviewDialog}
        closeHandler={() => {
          setOpenSmsReviewDialog(false);
        }}
        sendSMS={sendSelectiveSms}
        sms_content={smsSettings.message}
        total_number={totalNumber}
        total_sms_credit={totalSmsCredit}
      />
    </>
  );
}

const DetailDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 120px;
  height: 42px;
  border-radius: 5px;
`;
const DetailDivOption = styled.div`
  width: 170px !important;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #ffffff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149));
  border-radius: 5px;
`;
const DetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
`;
const DetailIcon = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  i {
    font-size: 21px;
  }
`;
const DetailInner = styled.div`
  flex: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;

  h4 {
    color: #808080;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 4px;
  }
  span {
    color: #202020;
    font-size: 14px;
  }
`;
const DetailBoxInner = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7e508e;
    margin-right: 8px;
  }
`;
const DetailBoxInnerCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  background: #ffffff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.09));
`;
const DivStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DetailCard = (props) => (
  <DetailBox>
    <DetailIcon className={`bg-${props.color}-opacity fc-${props.color}`}>
      <i className="material-icons">{props.icon}</i>
    </DetailIcon>
    <DetailInner>
      <h4>{props.label}</h4>
      <span>{props.value}</span>
    </DetailInner>
    <DetailBoxInner>
      <span>{props.sabValue}</span>
    </DetailBoxInner>
    {props.iconValue && (
      <DetailBoxInnerCon>
        <i
          className="material-icons"
          style={{
            fontSize: "18px",
            cursor: "pointer",
          }}
          onClick={props.click ? props.click : () => {}}
        >
          {props.iconValue}
        </i>
      </DetailBoxInnerCon>
    )}
  </DetailBox>
);

export default React.memo(AppointmentCardDetail);
