import React, { useContext, useEffect, useState } from "react";
import { Grid, Icon } from "@material-ui/core";
import { IoLinkOutline } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import { SlCalculator } from "react-icons/sl";
import { TbCalendarCheck } from "react-icons/tb";
import { FaMobileAlt } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { RiMessage2Fill } from "react-icons/ri";
import { FaFileContract } from "react-icons/fa";
import { IoReceipt } from "react-icons/io5";
import { HiMiniIdentification } from "react-icons/hi2";
import { IoNotifications } from "react-icons/io5";
import { TbReportSearch } from "react-icons/tb";
import styled from "styled-components";
import AppContext from "../../../../context/store";

function Index() {
  const context = useContext(AppContext);
  const [selectFeature, setSelecetFeature] = useState(0);

  const featuresData = [
    {
      id: 1,
      title: context.t(`campaigns.aboutApp`)[0].title,
      Icon: <IoLinkOutline color="black" size={60} fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[0].context,
    },
    {
      id: 2,
      title: context.t(`campaigns.aboutApp`)[1].title,
      Icon: <BsPeopleFill size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[1].context,
    },
    {
      id: 3,
      title: context.t(`campaigns.aboutApp`)[2].title,
      Icon: <SlCalculator size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[2].context,
    },
    {
      id: 4,
      title: context.t(`campaigns.aboutApp`)[3].title,
      Icon: <TbCalendarCheck size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[3].context,
    },
    {
      id: 5,
      title: context.t(`campaigns.aboutApp`)[4].title,
      Icon: <FaMobileAlt size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[4].context,
    },
    {
      id: 6,
      title: context.t(`campaigns.aboutApp`)[5].title,
      Icon: <BiSolidReport size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[5].context,
    },
    {
      id: 7,
      title: context.t(`campaigns.aboutApp`)[6].title,
      Icon: <RiMessage2Fill size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[6].context,
    },
    {
      id: 8,
      title: context.t(`campaigns.aboutApp`)[7].title,
      Icon: <FaFileContract size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[7].context,
    },
    {
      id: 9,
      title: context.t(`campaigns.aboutApp`)[8].title,
      Icon: <IoReceipt size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[8].context,
    },
    {
      id: 10,
      title: context.t(`campaigns.aboutApp`)[9].title,
      Icon: <HiMiniIdentification size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[9].context,
    },
    {
      id: 11,
      title: context.t(`campaigns.aboutApp`)[10].title,
      Icon: <IoNotifications size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[10].context,
    },
    {
      id: 12,
      title: context.t(`campaigns.aboutApp`)[11].title,
      Icon: <TbReportSearch size={60} color="black" fontSize="large" />,
      context: context.t(`campaigns.aboutApp`)[11].context,
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        backgroundColor:
          process.env.APP_NAME === "en" ? "#3189ec63" : "#f7c0c3",
        paddingBottom: "30px",
        paddingTop: "30px",
      }}
    >
      <div className="container">
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "28px",
            fontWeight: 600,
            lineHeight: 1.4,
            color: "white",
            marginTop: "30px",
          }}
        >
          {context.t(`campaigns.featureOffApp.head`)}
        </p>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "15px",
            fontWeight: 100,
            lineHeight: 1.1,
            color: "white",
            marginBottom: "30px",
          }}
        >
          {context.t(`campaigns.featureOffApp.title1`)}
        </p>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <div>
              <Grid container>
                <Grid
                  onClick={() => setSelecetFeature(0)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 0
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {<IoLinkOutline color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: " white",
                      }}
                    >
                      {" "}
                      {context.t(`campaigns.featureOffApp.title2`)}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(1)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 1
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {<BsPeopleFill color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title3`)}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(2)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 2
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {<SlCalculator color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title4`)}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(3)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 3
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {
                      <TbCalendarCheck
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title5`)}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(4)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 4
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {<FaMobileAlt color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title6`)}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(5)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 5
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {<BiSolidReport color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title7`)}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(6)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 6
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {
                      <RiMessage2Fill
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title8`)}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(7)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 7
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {
                      <FaFileContract
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title9`)}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(8)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 8
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {<IoReceipt color="white" size={30} fontSize="large" />}
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title10`)}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(9)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 9
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {
                      <HiMiniIdentification
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title11`)}
                    </p>
                  </FavoriteBox>
                </Grid>{" "}
                <Grid
                  onClick={() => setSelecetFeature(10)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 10
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {
                      <IoNotifications
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title12`)}
                    </p>
                  </FavoriteBox>
                </Grid>
                <Grid
                  onClick={() => setSelecetFeature(11)}
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                >
                  <FavoriteBox
                    backgroundColor={
                      selectFeature === 11
                        ? "#d50a58"
                        : process.env.APP_NAME === "salon"
                        ? "#7500FF"
                        : "var(--primary)"
                    }
                  >
                    {
                      <TbReportSearch
                        color="white"
                        size={30}
                        fontSize="large"
                      />
                    }
                    <p
                      style={{
                        lineHeight: "20px",
                        fontWeight: 600,
                        color: "white",
                      }}
                    >
                      {context.t(`campaigns.featureOffApp.title13`)}
                    </p>
                  </FavoriteBox>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                height: "450px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0 5px 8px #a5a5a5ad",
                      borderRadius: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "80%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {featuresData[selectFeature ? selectFeature : 0].Icon}
                      </div>
                      <h2
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                          color: "black",
                        }}
                      >
                        {featuresData[selectFeature ? selectFeature : 0].title}
                      </h2>
                      <p
                        style={{
                          width: "100%",
                          textAlign: "center",
                          color: "rgba(3, 29, 68, 0.5)",
                        }}
                      >
                        {
                          featuresData[selectFeature ? selectFeature : 0]
                            .context
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Index;

const FavoriteBox = styled.div`
  width: 90%;
  height: 100px;
  text-align: center;
  display: flex;
  gap: 5px;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background: ${(props) => props.backgroundColor};
  box-shadow: 0 3px 3px #a5a5a5ad;

  @media only screen and (max-width: 1190px) {
    margin: 5px;
  }
`;
