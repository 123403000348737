import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../context/store";

import { Column, Grid } from "../../../theme/Grid";
import AuthContainer from "../../../components/AuthContainer";

import "../../../assets/style/component/Form.scss";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import moment from "moment";

const WorkHourSettings = () => {
  const context = useContext(AppContext);

  const [hours, setHours] = useState({
    start_hour: null,
    end_hour: null,
    slice: null,
  });

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const getCompanyDetails = () => {
    Axios.get(`${context.api_endpoint}/company/itself`).then(
      ({ data: { data } }) => {
        setHours({
          ...data.hours,
        });
      }
    );
  };

  const updateWorkHours = () => {
    const { start_hour, end_hour } = hours;

    if (validateHours())
      if (
        moment(`1970-01-01 ${end_hour}:00`).isAfter(
          moment(`1970-01-01 ${start_hour}:00`)
        )
      )
        Axios.put(`${context.api_endpoint}/company/update/hours`, {
          ...hours,
        })
          .then((response) => {
            if (response.status === 201) {
              context.dispatch({
                type: "SET_COMPANY_SHIFT",
                payload: { ...hours },
              });
              toast.success(context.t(`['settings/hours'].updateToast`));
              getCompanyDetails();
            }
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.error(e.response.data.message);
            }
          });
      else toast.warning(context.t(`['settings/hours'].compareErrorToast`));
  };

  const validateHours = () => {
    return Validate([
      {
        value: hours.start_hour,
        condition: /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
        field: context.t(`['settings/hours'].startInputLabel`),
      },
      {
        value: hours.end_hour,
        condition: /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
        field: context.t(`['settings/hours'].endInputLabel`),
      },
      {
        value: hours.slice,
        condition: /^.+$/,
        field: context.t(`['settings/hours'].periodBetweenInputLabel`),
      },
    ]);
  };

  return (
    <AuthContainer authorities={[1]} limited_permission="system">
      <Grid>
        <Column className="xs-12 sm-12">
          <MaskedInput
            label={context.t(`['settings/hours'].startInputLabel`)}
            mask="99:99"
            maskChar=""
            type="text"
            value={hours.start_hour !== null ? hours.start_hour : ""}
            onChange={(e) => {
              setHours({
                ...hours,
                start_hour: e.target.value,
              });
            }}
          />

          <MaskedInput
            label={context.t(`['settings/hours'].endInputLabel`)}
            mask="99:99"
            maskChar=""
            type="text"
            value={hours.end_hour !== null ? hours.end_hour : ""}
            onChange={(e) => {
              setHours({
                ...hours,
                end_hour: e.target.value,
              });
            }}
          />

          <MaskedInput
            label={context.t(`['settings/hours'].periodBetweenInputLabel`)}
            mask="999"
            maskChar=""
            type="text"
            value={hours.slice !== null ? hours.slice : ""}
            onChange={(e) => {
              setHours({
                ...hours,
                slice:
                  e.target.value.substring(0, 1) !== "0"
                    ? e.target.value
                    : null,
              });
            }}
          />

          <Button
            icon="update"
            title={context.t(`['settings/hours'].updateButtonTitle`)}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            onClick={() => updateWorkHours()}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default WorkHourSettings;
