import React, { useContext, useState } from "react";
import AppContext from "../../../context/store";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import { Grid, Column, Container } from "../../../theme/Grid";
import Button from "../../../theme/Button";

import { Alert, Rating } from "@material-ui/lab";
import Input from "../../../theme/CustomMUI/Input";
import Axios from "axios";
import { toast } from "react-toastify";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";

import CheckIcon from "@material-ui/icons/Check";
import { Redirect } from "react-router-dom";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import { useParams } from "react-router-dom";

const AppointmentRating = () => {
  const context = useContext(AppContext);
  const params = useParams();

  const [loaded, setLoaded] = useState(false);
  const [ratings, setRatings] = useState({
    company_star: null,
    staff_star: null,
    description: "",
  });
  const [redirectModalStatus, setRedirectModalStatus] = useState(false);
  const [redirectToCompanyPage, setRedirectToCompanyPage] = useState("");

  const insertAppointmentRating = () => {
    // console.log('props.match.params:: ', params);

    /*setState({
      redirectModalStatus: !state.redirectModalStatus,
    });*/

    ratings.company_star && ratings.staff_star
      ? Axios.post(`${context.api_endpoint}/new/customer/rating`, {
          appointment_id:
            (parseInt(params.id) / 924) % 1 === 0
              ? parseInt(params.id) / 924
              : 9999999, //Belirlediğimiz hash 924
          ...ratings,
        })
          .then((response) => {
            if (response.status === 201) {
              toast.success(
                context.t(`businessURL.appointment.appointmentRatingSuccessToast`)
              );
              setRedirectModalStatus(!redirectModalStatus);

              const companyId = response.data.data.company;
              // console.log('FindCompanyID:: ', companyId);
              setTimeout(() => {
                setRedirectToCompanyPage(`/isletme/${companyId}`);
              }, 5000);
            }
          })
          .catch(async (e) => {
            e.response.status === 404 && toast.error(e.response.data.message);
          })
      : toast.warning(context.t(`businessURL.appointment.appointmentRatingWarnToast`));
  };

    return (
      <>
        <Helmet>
          <title>{context.app.NAME} {context.t(`businessURL.appointment.evaluationSurverTitle`)}</title>
        </Helmet>

        {redirectToCompanyPage &&
          redirectToCompanyPage.length > 0 &&
          redirectToCompanyPage.includes("isletme") && (
            <Redirect to={redirectToCompanyPage} />
          )}

        <div className="animated fadeIn">
          <Container>
            <Grid style={{ padding: "32px 0" }}>
              <Column className="xs-12 sm-12">
                <Alert
                  className="mb-2"
                  severity="info"
                  style={{ width: "100%", boxSizing: "border-box" }}
                >
                  <b>{context.t(`businessURL.appointment.evaluationSurverSubtitle`)}</b>
                  <br />
                  {context.t(`businessURL.appointment.evaluationSurverText1`)}{" "}
                  <b>
                  {context.t(`businessURL.appointment.evaluationSurverText2`)}
                  </b>
                </Alert>

                <QuestionBox>
                  <h2>{context.t(`businessURL.appointment.evaluationSurverQuestion1`)}</h2>

                  <RatingWithLabel>
                    <Rating
                      style={{ display: "flex" }}
                      precision={1}
                      size="large"
                      onChange={(e) => {
                        setRatings({
                          ...ratings,
                          company_star: parseFloat(e.target.value)
                        });
                      }}
                    />
                    <span className="label">
                      {ratings.company_star &&
                        `${ratings.company_star.toFixed(2)} / 5.00`}
                    </span>
                  </RatingWithLabel>
                </QuestionBox>

                <QuestionBox>
                  <h2>{context.t(`businessURL.appointment.evaluationSurverQuestion2`)}</h2>
                  <RatingWithLabel>
                    <Rating
                      style={{ display: "flex" }}
                      precision={1}
                      size="large"
                      onChange={(e) => {
                        setRatings({
                          ...ratings,
                          staff_star: parseFloat(e.target.value)
                        });
                      }}
                    />
                    <span className="label">
                      {ratings.staff_star &&
                        `${ratings.staff_star.toFixed(2)} / 5.00`}
                    </span>
                  </RatingWithLabel>
                </QuestionBox>

                <Input
                  label={context.t(`businessURL.appointment.evaluationSurverInputLabel`)}
                  multiline={true}
                  value={ratings.description}
                  rows={5}
                  onChange={(e) => {
                    if (e.target.value.length <= 450) {
                      setRatings({ ...ratings, description: e.target.value });
                    }
                  }}
                  helperText={CalculateMessageCharCount(
                    ratings.description,
                    "2"
                  )}
                />

                <Button
                  title={context.t(`component.sms_review.sendButtonTitle`)}
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth
                  onClick={() => insertAppointmentRating()}
                />
              </Column>
            </Grid>
          </Container>
          <ModalDialog
            open={redirectModalStatus}
            closeHandler={() => setRedirectModalStatus(false)}
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 20,
                    backgroundColor: "#047204",
                    borderRadius: "50%",
                    marginBottom: 35,
                  }}
                >
                  <CheckIcon style={{ color: "#fff" }} fontSize={"large"} />
                </div>
                <div>{context.t(`businessURL.appointment.routeToBusinessURL`)}</div>
              </div>
            }
          />
        </div>
      </>
    );
  
}

export default AppointmentRating;

const QuestionBox = styled.div`
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);

  h2 {
    font-weight: 500;
    font-size: 15px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
  }
`;

const RatingWithLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span.label {
    margin-left: 16px;
    font-weight: 500;
    font-size: 15px;
    padding: 4px;
    border-radius: 5px;
    background: var(--primary-transparent);
    color: var(--primary);
  }

  .MuiRating-icon.MuiRating-iconEmpty {
    color: rgba(0, 0, 0, 0.1) !important;
  }
`;
