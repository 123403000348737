import React, { useContext /*useState*/ } from "react";
import AppContext from "../context/store";
import Button from "../theme/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../assets/style/component/Table.scss";
import styled from "styled-components";
import { useLocation } from "react-router";

const get = require("lodash.get");

const PackageTable = ({
  containerHeight,
  headings,
  disableHeadings,
  rows,
  buttons,
  replacements,
  loaded,
  skeletonCount,
  pagination,
  noDataText,
  showAlwaysPagination,
  checkboxOnChange,
  checkedCheckboxes,
  selectedAll,
  excepteds,
}) => {
  const context = useContext(AppContext);
  const location = useLocation();

  /*
  const setSortStates = () => {
    let _ = {};
    [...Object.keys(headings)].map(key => 
      headings[key].sortable && Object.assign(_, { [key]: headings[key].sortable[0] })
    )
    return _;
  }

  const [SORT_STATES, SET_SORT_STATES] = useState(setSortStates())
  const [ACTIVE_SORTING, SET_ACTIVE_SORTING] = useState(null)
  */

  const isCheckboxChecked = (row) => {
    if (excepteds && excepteds.find((item) => item === row.id)) {
      return false;
    }
    return (
      selectedAll ||
      (checkedCheckboxes.find((item) => item === row.id) ?? false)
    );
  };
  return (
    <div>
      <div style={{ overflowY: "auto", maxHeight: containerHeight ?? "auto" }}>
        <table className="table-container" border={0}>
          {disableHeadings !== true && (
            <thead>
              <tr>
                {checkboxOnChange && <th style={{ width: "0px" }}></th>}
                {[...Object.keys(headings)].map((key, index) =>
                  key === "_" ? (
                    <th className="action-column" scope="row" key={index}>
                      {headings[key].label}
                      {(headings[key].prefix || headings[key].suffix) &&
                        ` (${headings[key].prefix ?? ""}${
                          headings[key].suffix ?? ""
                        })`}
                    </th>
                  ) : (
                    <th
                      scope="row"
                      key={index}
                      style={{
                        display: index !== 0 && index !== 2 ? "flex" : null,
                        justifyContent: index !== 0 ? "center" : null,
                        width: index === 2 ? "140px" : null,
                        ...headings[key].style,
                        // cursor: headings[key].sortable ? 'pointer' : null
                      }}
                      /*onClick={async () => {
                      if (headings[key].sortable) {
                        await SET_SORT_STATES({
                          ...SORT_STATES,
                          [key]: SORT_STATES[key] === headings[key].sortable['0']
                            ? headings[key].sortable['1']
                            : headings[key].sortable['0']
                        })
                        await SET_ACTIVE_SORTING(key);
                        await console.log(SORT_STATES[key])
                      }
                    }}*/
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={
                            headings[key]?.rowStyleType === "primary"
                              ? {
                                  backgroundColor: "#EFF6FE",
                                  color: "#3189ec",
                                  padding: "10px 20px",
                                  width: "100px",
                                  borderRadius: "10px",
                                }
                              : headings[key]?.rowStyleType === "warn"
                              ? {
                                  backgroundColor: "#FFF6ED",
                                  color: "#ff9016",
                                  padding: "10px 20px",
                                  width: "100px",
                                  borderRadius: "10px",
                                }
                              : headings[key]?.rowStyleType === "success"
                              ? {
                                  backgroundColor: "#EDF6F0",
                                  color: "#1d8b3e",
                                  padding: "10px 20px",
                                  width: "130px",
                                  borderRadius: "10px",
                                }
                              : null
                          }
                        >
                          {headings[key].label}
                          {(headings[key].prefix || headings[key].suffix) &&
                            ` (${headings[key].prefix ?? ""}${
                              headings[key].suffix ?? ""
                            })`}
                        </span>

                        {/*headings[key].sortable &&
                          <KeyboardArrowDown style={{
                            transform: `rotate(${SORT_STATES[key] === headings[key].sortable['0'] ? '-180deg' : '0'})`,
                            opacity: ACTIVE_SORTING === key ? 1 : 0,
                            transition: 'all ease 0.4s'
                          }}
                          />
                        */}
                      </span>
                    </th>
                  )
                )}
              </tr>
            </thead>
          )}

          <tbody>
            {loaded === false
              ? [...new Array(skeletonCount ? skeletonCount : 5).keys()].map(
                  (row, index) => (
                    <tr key={index}>
                      {[...Object.keys(headings)].map((key, index) =>
                        key === "_" ? (
                          <td key={index} className="action-column">
                            {buttons.map((bt, index) => (
                              <Button
                                {...bt}
                                disabled={!loaded}
                                key={index}
                                // size="xs"
                                onClick={() => {
                                  bt.onClick
                                    ? bt.onClick(row)
                                    : console.log("Action is not defined!");
                                }}
                              />
                            ))}
                          </td>
                        ) : (
                          <td key={index}>
                            <SkeletonLine
                              className="skeleton"
                              style={
                                buttons === undefined || buttons.length === 0
                                  ? { margin: "7px 0" }
                                  : null
                              }
                            />
                          </td>
                        )
                      )}
                    </tr>
                  )
                )
              : rows.map((row, index) => (
                  <tr key={index}>
                    {typeof checkboxOnChange === "function" && (
                      <td style={{ minWidth: "0px" }}>
                        <input
                          key={row.id}
                          className="check"
                          type="checkbox"
                          checked={isCheckboxChecked(row)}
                          onChange={(e) =>
                            checkboxOnChange(row, e.target.checked)
                          }
                        />
                      </td>
                    )}
                    {[...Object.keys(headings)].map((key, index) =>
                      key === "_" ? (
                        <td key={index} className="action-column">
                          {buttons.map((bt, index) => (
                            <Button
                              key={index}
                              {...bt}
                              // size="xs"
                              disabled={
                                typeof bt.disabled === "function"
                                  ? bt.disabled(row)
                                  : bt.disabled
                              }
                              size="xs"
                              backgroundColor="white"
                              onClick={() => {
                                bt.onClick
                                  ? bt.onClick(row)
                                  : console.log("Action is not defined!");
                              }}
                            />
                          ))}
                        </td>
                      ) : (
                        <td
                          key={index}
                          style={{
                            textAlign: index !== 0 ? "center" : null,
                            ...headings[key].style,
                            height:
                              Object.keys(headings).filter((key) => key === "_")
                                .length === 0
                                ? 52
                                : "auto",
                          }}
                        >
                          <span
                            style={{
                              textAlign: index !== 0 ? "center" : null,
                            }}
                          >
                            {headings[key].with_photo ? (
                              <CirclePhoto
                                src={
                                  get(row, headings[key].with_photo)
                                    ? `${context.api_endpoint.replace(
                                        "api/v1",
                                        ""
                                      )}/${context.state.company_id}/${get(
                                        row,
                                        headings[key].with_photo
                                      )}`
                                    : require("../assets/images/profile_photo.svg")
                                }
                              />
                            ) : null}
                            <span
                              className={
                                headings[key].limited_line
                                  ? `limited-line-${headings[key].limited_line}`
                                  : null
                              }
                              style={
                                headings[key]?.rowStyleType === "primary"
                                  ? {
                                      backgroundColor: "#EFF6FE",
                                      color: "#3189ec",
                                      padding: "10px 22px",
                                      borderRadius: "10px",
                                    }
                                  : headings[key]?.rowStyleType === "warn"
                                  ? {
                                      backgroundColor: "#FFF6ED",
                                      color: "#ff9016",
                                      padding: "10px 22px",
                                      borderRadius: "10px",
                                    }
                                  : headings[key]?.rowStyleType === "success"
                                  ? {
                                      backgroundColor: "#EDF6F0",
                                      color: "#1d8b3e",
                                      padding: "10px 22px",
                                      borderRadius: "10px",
                                    }
                                  : null
                              }
                            >
                              {replacements &&
                              replacements.hasOwnProperty(key) &&
                              replacements[key].hasOwnProperty(
                                get(row, key)
                              ) ? (
                                replacements[key][get(row, key)] ? (
                                  <>
                                    {headings[key].prefix ?? ""}
                                    {replacements[key][get(row, key)]}
                                    {headings[key].suffix ?? ""}
                                  </>
                                ) : (
                                  "-"
                                )
                              ) : get(row, key) ? (
                                <>
                                  {headings[key].prefix ?? ""}
                                  {get(row, key)}
                                  {headings[key].suffix ?? ""}
                                </>
                              ) : (
                                "-"
                              )}
                            </span>
                          </span>
                        </td>
                      )
                    )}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      {loaded === true && rows?.length === 0 && (
        <>
          <NoDataContainer>
            <img
              src={require("../assets/images/undraw/no_data_table.svg")}
              alt="Veri bulunamadı!"
            />
            <span>{noDataText ? noDataText : "Veri bulunamadı!"}</span>
          </NoDataContainer>
          <PaginationContainer>
            <div className="pagination p7">
              <ul>
                <button
                  disabled={pagination?.page < 2}
                  onClick={() => {
                    window.history.pushState(
                      { path: "" },
                      "",
                      "?page=" + pagination.page--
                    );
                    pagination.page && pagination.onChange(pagination.page);
                  }}
                >
                  <li>
                    <ArrowBackIosIcon className="icon-back" fontSize="small" />
                    {/* {context.t(`receipts.previous`)} */}
                  </li>
                </button>
                <button
                  disabled={rows?.length < 10}
                  onClick={() => {
                    window.history.pushState(
                      { path: "" },
                      "",
                      "?page=" + pagination.page++
                    );
                    pagination.page && pagination.onChange(pagination.page);
                  }}
                >
                  <li>
                    {/* {context.t(`receipts.next`)}{" "} */}
                    <ArrowForwardIosIcon fontSize="small" />
                  </li>
                </button>
              </ul>
            </div>
            {/* <Pagination
            count={pagination.total_page}
            onChange={(event, value) => {
              window.history.pushState({ path: "" }, "", "?page=" + value);
              pagination.page !== value && pagination.onChange(value);
            }}
            page={pagination.page ?? 1}
          /> */}
          </PaginationContainer>
        </>
      )}
      {/* Pagination Clear And Count Prev Next Added */}
      {(showAlwaysPagination ||
        (rows?.length > 0 && pagination && pagination.total_page)) && (
        <PaginationContainer>
          <div className="pagination p7">
            <ul>
              <button
                disabled={pagination.page < 2}
                onClick={() => {
                  window.history.pushState(
                    { path: "" },
                    "",
                    "?page=" + pagination.page--
                  );
                  pagination.page && pagination.onChange(pagination.page);
                }}
              >
                <li>
                  <ArrowBackIosIcon className="icon-back" fontSize="small" />
                  {/* {context.t(`receipts.previous`)} */}
                </li>
              </button>
              <button
                disabled={rows?.length < 10}
                onClick={() => {
                  window.history.pushState(
                    { path: "" },
                    "",
                    "?page=" + pagination.page++
                  );
                  pagination.page && pagination.onChange(pagination.page);
                }}
              >
                <li>
                  {/* {context.t(`receipts.next`)}{" "} */}
                  <ArrowForwardIosIcon fontSize="small" />
                </li>
              </button>
            </ul>
          </div>
          {/* <Pagination
            count={pagination.total_page}
            onChange={(event, value) => {
              window.history.pushState({ path: "" }, "", "?page=" + value);
              pagination.page !== value && pagination.onChange(value);
            }}
            page={pagination.page ?? 1}
          /> */}
        </PaginationContainer>
      )}
    </div>
  );
};

export default React.memo(PackageTable);

export const SkeletonLine = styled.div`
  margin: 10px 0;
  height: 24px;
`;

const CirclePhoto = styled.img`
  display: inline-block;
  min-width: 24px !important;
  min-height: 24px !important;
  max-width: 24px !important;
  max-height: 24px !important;
  object-fit: cover;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: 0;
  outline: 0;
  float: left;

  @media only screen and (max-width: 576px) {
    display: none;

    & + span {
      margin: 0 !important;
    }
  }
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  img {
    height: 160px;
    object-fit: contain;
    margin-bottom: 16px;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    color: #a0a0a0;
  }
`;
