import React, { useContext, useEffect, useState } from "react";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Axios from "axios";
import AppContext from "../../context/store";
import Button from "../../theme/Button";
import Input from "../../theme/CustomMUI/Input";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { toast } from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../../assets/images/loader.gif";

const AllServicesDailog = (props) => {
  const context = useContext(AppContext);
  const [expanded, setExpanded] = React.useState(false);
  const [loaded, setLoaded] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [serviceCategories, setServiceCategories] = useState([]);
  const [services, setServices] = useState([]);

  const [selectedServices, setSelectedServices] = useState([]);

  const [selectedAll, setSelectedAll] = useState([]);

  const [searchService, setSearchService] = useState(null);

  const [pagination, setPagination] = useState({
    page: null,
    total_page: null,
  });

  const [categoryList, setCategoryList] = useState([]);
  const [exceptedServices, setExceptedServices] = useState([]);

  const [allServices, setAllServices] = useState([]); // All services without category
  const [listAllServices, setListAllServices] = useState(false); // List all services without category

  const handleChange = (id) => (e, isChecked) => {
    setSearchService(null);
    setExpanded(isChecked ? id : false);

    isChecked && getServiceNames(id);
  };

  const handleCheck = (e, service) => {
    if (!e.target.checked) {
      setSelectedServices([
        ...selectedServices.filter((_service) => _service.id !== service.id),
      ]);

      selectedAll.includes(service.services_categories_id) &&
        setExceptedServices([...exceptedServices, service]);
    } else {
      !selectedAll.includes(service.services_categories_id) &&
        setSelectedServices([...selectedServices, service]);

      selectedAll.includes(service.services_categories_id) &&
        setExceptedServices([
          ...exceptedServices.filter(
            (exceptedService) => exceptedService.id !== service.id
          ),
        ]);
    }
  };

  const handleDelete = (service) => {
    setSelectedServices([
      ...selectedServices.filter((_service) => _service.id !== service.id),
    ]);
  };

  const handleCheckAll = (categoryId, checked) => {
    if (checked) {
      setSelectedAll([...selectedAll, categoryId]);
    } else {
      console.log("here");
      setSelectedAll([...selectedAll.filter((cId) => cId !== categoryId)]);
      setExceptedServices([
        ...exceptedServices.filter(
          (exceptedService) =>
            exceptedService.services_categories_id !== categoryId
        ),
      ]);
    }
    setSelectedServices([
      ...selectedServices.filter(
        (service) => service.services_categories_id !== categoryId
      ),
    ]);
  };

  const getServiceCategories = async () => {
    await Axios.get(
      `${context.api_endpoint}/company/services/category/list/all`,
      {
        params: {
          name: "",
        },
      }
    ).then(async ({ data }) => {
      setServiceCategories([...data.data]);
    });
  };

  const getServiceNames = async (id, key, page = 1) => {
    setLoaded(false);
    await Axios.get(
      `${context.api_endpoint}/company/platform/services/list/all`,
      {
        params: {
          page: page,
          categoryid: id,
          name: key ?? "",
          paginate: true,
        },
      }
    )
      .then(async (data) => {
        if (id !== 0) {
          setServices([...data.data.data.records]);
        } else {
          setAllServices([...data.data.data.records]);
        }
        setLoaded(true);
        setPagination({
          page: data.data.data.page,
          total_page: data.data.data.records.length,
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        }
      });
  };

  const handleClose = (state = false) => {
    setLoaded(false);
    setSearchService(null);
    setExpanded(false);
    setSelectedServices([]);
    setOpenBackdrop(false);
    setSelectedAll([]);
    setExceptedServices([]);
    setAllServices([]);
    setListAllServices(false);
    props.closeHandler(state);
  };

  const insertServices = async () => {
    setOpenBackdrop(true);
    await Axios.post(
      `${context.api_endpoint}/company/services/insert/multiple`,
      {
        services_list: [...selectedServices.map((service) => service.id)],
        category_list: [...selectedAll],
        except: [...exceptedServices.map((service) => service.id)],
      }
    )
      .then((data) => {
        setOpenBackdrop(false);
        toast.success(context.t(`component.allServicesDialog.successToast`));
        handleClose(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        }
        setOpenBackdrop(false);
      });
  };

  useEffect(() => {
    getServiceCategories();
  }, []);

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -20,
      top: 10,
    },
  }))(Badge);

  return (
    <AlertDialog
      open={props.open}
      disableBackdropClick={false}
      fullWidth={true}
      maxWidth="lg"
      closeHandler={handleClose}
    >
      <Grid container>
        <Backdrop open={openBackdrop} style={{ zIndex: "9999999999" }}>
          <img src={Loader} width="100" height="100" alt="loading" />
        </Backdrop>
        <Grid item xs={12} sm={12} md={6}>
          <h1 style={{ textAlign: "center" }}>
            {context.t(`component.allServicesDialog.serviceCategoryTitle`)}
          </h1>
          <CategoryContainer>
            {!listAllServices ? (
              serviceCategories.map((category) => {
                return (
                  <Accordion
                    expanded={expanded === category.id}
                    onChange={handleChange(category.id)}
                    key={category.id}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header"
                    >
                      <CategoryTitleContainer>
                        <div className="category">
                          <StyledBadge
                            badgeContent={
                              selectedServices.filter(
                                (service) =>
                                  service.services_categories_id === category.id
                              ).length
                            }
                            color="primary"
                          >
                            <Typography>
                              {category.name}{" "}
                              {selectedAll.find((id) => id === category.id) ? (
                                <span
                                  style={{ fontSize: "14px", color: "blue" }}
                                >
                                  (Hepsi Seçildi)
                                </span>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </StyledBadge>

                          {expanded === category.id && (
                            <Button
                              title={
                                selectedAll.find(
                                  (categoryId) => categoryId === category.id
                                )
                                  ? "Tümünü Sil"
                                  : "Hepsini Seç"
                              }
                              textColor="primary"
                              onFocus={(e) => {
                                e.stopPropagation();
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCheckAll(
                                  category.id,
                                  selectedAll.find(
                                    (categoryId) => categoryId === category.id
                                  )
                                    ? false
                                    : true
                                );
                              }}
                            />
                          )}
                        </div>
                        {expanded === category.id && (
                          <Input
                            style={{ alignSelf: "stretch" }}
                            label={"Hizmet Adı"}
                            placeholder={"Aramak istediğiniz hizmeti giriniz"}
                            value={searchService ?? ""}
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            onChange={(e) => {
                              setSearchService(e.target.value);
                              getServiceNames(category.id, e.target.value);
                            }}
                          />
                        )}
                      </CategoryTitleContainer>
                    </AccordionSummary>
                    <AccordionDetails>
                      {!loaded ? (
                        <div>
                          {context.t(`component.allServicesDialog.loading`)}
                        </div>
                      ) : (
                        <div style={{ width: "100%" }}>
                          {services.length > 0 ? (
                            services.map((service) => {
                              return (
                                <div
                                  key={service.id}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <input
                                    key={service.id}
                                    id={service.id}
                                    type="checkbox"
                                    checked={
                                      (selectedServices.find(
                                        (_service) => _service.id === service.id
                                      ) ??
                                        false) ||
                                      ((selectedAll.find(
                                        (categoryId) =>
                                          categoryId ===
                                          service.services_categories_id
                                      ) ??
                                        false) &&
                                        (!exceptedServices.find(
                                          (exceptedService) =>
                                            exceptedService.id === service.id
                                        ) ??
                                          false))
                                    }
                                    onChange={(e) => {
                                      handleCheck(e, service);
                                    }}
                                  />
                                  <label htmlFor={service.id}>
                                    {service.name}
                                  </label>
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              {context.t(
                                `component.allServicesDialog.notFoundService`
                              )}
                            </div>
                          )}
                          {pagination.page !== null && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {/* <Pagination
                                page={pagination.page}
                                count={pagination.total_page}
                                onChange={(event, value) => {
                                  setPagination({
                                    ...pagination,
                                    page: value,
                                  });
                                  getServiceNames(
                                    category.id,
                                    searchService,
                                    value
                                  );
                                }}
                              /> */}
                              <div className="pagination p7">
                                <ul>
                                  <button
                                    disabled={pagination.page < 2}
                                    style={{ width: "40px", height: "40px" }}
                                    onClick={() => {
                                      setPagination({
                                        ...pagination,
                                        page: pagination.page--,
                                      });
                                      getServiceNames(
                                        category.id,
                                        searchService,
                                        pagination.page
                                      );
                                    }}
                                  >
                                    <li>
                                      <ArrowBackIosIcon
                                        className="icon-back"
                                        fontSize="small"
                                      />
                                    </li>
                                  </button>
                                  <button
                                    disabled={services.length < 10}
                                    style={{ width: "40px", height: "40px" }}
                                    onClick={() => {
                                      setPagination({
                                        ...pagination,
                                        page: pagination.page++,
                                      });
                                      getServiceNames(
                                        category.id,
                                        searchService,
                                        pagination.page
                                      );
                                    }}
                                  >
                                    <li>
                                      <ArrowForwardIosIcon fontSize="small" />
                                    </li>
                                  </button>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : (
              // Bütün Hizmetler
              <CategoryContainer style={{ minHeight: "400px" }}>
                <Accordion
                  expanded={true}
                  onChange={handleChange(0)}
                  key="allServices"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                  >
                    <CategoryTitleContainer>
                      <div className="category">
                        <StyledBadge
                          badgeContent={selectedServices.length}
                          color="primary"
                        >
                          <Typography>Bütün Hizmetler</Typography>
                        </StyledBadge>
                      </div>
                      {true && (
                        <Input
                          style={{ alignSelf: "stretch" }}
                          label={"Hizmet Adı"}
                          placeholder={"Aramak istediğiniz hizmeti giriniz"}
                          value={searchService ?? ""}
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          onChange={(e) => {
                            setSearchService(e.target.value);
                            getServiceNames(0, e.target.value);
                          }}
                        />
                      )}
                    </CategoryTitleContainer>
                  </AccordionSummary>
                  <AccordionDetails style={{ minHeight: "350px" }}>
                    {!loaded ? (
                      <div>
                        {context.t(`component.allServicesDialog.loading`)}
                      </div>
                    ) : (
                      <div style={{ width: "100%" }}>
                        {allServices.length > 0 ? (
                          allServices.map((service) => (
                            <div
                              key={service.id}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <input
                                key={service.name}
                                id={service.id}
                                type="checkbox"
                                checked={
                                  (selectedServices.find(
                                    (_service) => _service.id === service.id
                                  ) ??
                                    false) ||
                                  (selectedAll.find(
                                    (categoryId) =>
                                      categoryId ===
                                      service.services_categories_id
                                  ) &&
                                    !exceptedServices.find(
                                      (exceptedService) =>
                                        exceptedService.id === service.id
                                    ))
                                }
                                onChange={(e) => {
                                  handleCheck(e, service);
                                }}
                              />
                              <label htmlFor={service.id}>{service.name}</label>
                            </div>
                          ))
                        ) : (
                          <div>
                            {context.t(
                              `component.allServicesDialog.notFoundService`
                            )}
                          </div>
                        )}
                        {pagination.page !== null && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <Pagination
                            page={pagination.page}
                            count={pagination.total_page}
                            onChange={(event, value) => {
                              setPagination({
                                ...pagination,
                                page: value,
                              });
                              getServiceNames(0, searchService, value);
                            }}
                          /> */}
                            <div className="pagination p7">
                              <ul>
                                <button
                                  disabled={pagination.page < 2}
                                  style={{ width: "40px", height: "40px" }}
                                  onClick={() => {
                                    setPagination({
                                      ...pagination,
                                      page: pagination.page--,
                                    });
                                    getServiceNames(
                                      0,
                                      searchService,
                                      pagination.page
                                    );
                                  }}
                                >
                                  <li>
                                    <ArrowBackIosIcon
                                      className="icon-back"
                                      fontSize="small"
                                    />
                                  </li>
                                </button>
                                <button
                                  disabled={allServices.length < 10}
                                  style={{ width: "40px", height: "40px" }}
                                  onClick={() => {
                                    setPagination({
                                      ...pagination,
                                      page: pagination.page++,
                                    });
                                    getServiceNames(
                                      0,
                                      searchService,
                                      pagination.page
                                    );
                                  }}
                                >
                                  <li>
                                    <ArrowForwardIosIcon fontSize="small" />
                                  </li>
                                </button>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              </CategoryContainer>
            )}
          </CategoryContainer>
          <Button
            title={!listAllServices ? "Bütün Hizmetler" : "Kategoriler"}
            className="mt-1"
            textColor="white"
            backgroundColor="primary"
            onClick={() => {
              setExpanded(null);
              getServiceNames(!listAllServices ? 0 : null);
              setListAllServices(!listAllServices);
              setSearchService("");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <h1 style={{ textAlign: "center" }}>
            {context.t(`component.allServicesDialog.selectedServicesTitle`)}
          </h1>
          <ServiceContainer>
            {selectedServices.length > 0 ? (
              selectedServices.map((service, index) => (
                <div key={service.id + "" + index} className="service">
                  <p className="title">{service.name}</p>
                  <IconButton
                    aria-label="delete"
                    color="default"
                    size="small"
                    onClick={() => {
                      handleDelete(service);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
              ))
            ) : (
              <p style={{ width: "100%", textAlign: "center" }}>
                {context.t(`component.allServicesDialog.noSelectedService`)}
              </p>
            )}
          </ServiceContainer>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="mt-3">
          <Button
            title={context.t(`component.allServicesDialog.transferButtonTitle`)}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            disabled={
              selectedServices.length > 0 || selectedAll.length > 0
                ? false
                : true
            }
            onClick={() => {
              insertServices();
            }}
          />
        </Grid>
      </Grid>
    </AlertDialog>
  );
};

export default AllServicesDailog;

const CategoryContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const ServiceContainer = styled.div`
  max-height: 600px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;

  .service {
    margin: 0.25rem;
    background: #3189ec;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 576px) {
      width: 100%;
      justify-content: space-between;
      align-items: stretch;
    }

    .title {
      margin: 0;
      margin-left: 0.5rem;
    }
  }
`;

const CategoryTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .category {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selected-all-btn {
      fonst-size: 16px;
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
`;
