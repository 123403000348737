import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";
import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Dialog from "@material-ui/core/Dialog";
import { Button as Btn } from "@material-ui/core";
import styled from "styled-components";
import Axios from "axios";
import {
  Typography,
  Grid as Gr,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import StatsCard from "../../../components/Cards/StatsCard";
import CalculateLicense from "../../../functions/CalculateLicense";
import SinglePlan from "../../../components/SinglePlan";

import * as MUI from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  ShoppingCart,
  Send,
  CreditCard,
  AccountBalance,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AuthContainer from "../../../components/AuthContainer";
import { indexOf } from "lodash";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import EventNoteIcon from "@material-ui/icons/EventNote";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SmsIcon from "@material-ui/icons/Sms";
import AddIcon from "@material-ui/icons/Add";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import WalletTable from "../../../components/WalletTable";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CloseIcon from "@material-ui/icons/Close";
import { AiOutlineBarcode } from "react-icons/ai";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Tooltip } from "@material-ui/core";

import { GrStatusInfo } from "react-icons/gr";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const WalletIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const PACKAGE_LIST = {
    1: context.t(`wallet.packages.platinium`),
    2: context.t(`wallet.packages.gold`),
    3: context.t(`wallet.packages.diamond`),
  };
  

  const [balanceModal, setBalanceModal] = useState({
    active: false,
    url: null,
    data: null,
  });
  const [companyLicense, setCompanyLicense] = useState({
    ...context.state.company_license,
  });
  const [balance, setBalance] = useState(0);

  const [smsPackages, setSmsPackages] = useState([]);
  const [wpPackages, setWpPackages] = useState([]);
  const [extraLicense, setExtraLicense] = useState([]);
  const [licensePackages, setLicensePackages] = useState([]);
  const [license, setLicense] = useState(null);

  const [timer, setTimer] = useState(10);

  const [router, setRouter] = useState(1);

  const [paymentModal, setPaymentModal] = useState({
    active: false,
    type: null,
    data: {},
  });

  const [remainingAppointment, setRemainingAppointment] = useState(null);

  const [openPaymentTypeDialog, setOpenPaymentTypeDialog] = useState(false);

  const [selectData, setSelectData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isDropdownPlatiniumOpen, setIsDropdownPlatiniumOpen] = useState(false);
  const [isDropdownDiamondOpen, setIsDropdownDiamondOpen] = useState(false);
  const [defaultColorGold, setDefaultColorGold] = useState(true);
  const [defaultColorPlatinium, setDefaultColorPlatinium] = useState(true);
  const [defaultColorDiamond, setDefaultColorDiamond] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [checkAccounting, setCheckAccounting] = useState(0);

  const getExtraLicence = () => {
    Axios.get(`${context.api_endpoint}/company/show/license/extras`, {})
      .then((data) => setExtraLicense(data.data.data))
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    loadData();
    getExtraLicence();
  }, []);

  const toggleDropdownPlatinium = () => {
    setIsDropdownPlatiniumOpen(!isDropdownPlatiniumOpen);
  };

  const toggleDropdownDiamond = () => {
    setIsDropdownDiamondOpen(!isDropdownDiamondOpen);
  };

  const packageChangeAlertBuilder = () => {
    if (
      paymentModal.data?.subscription_id &&
      paymentModal.data?.subscription_id !==
        context.state.company_license.accounting
    ) {
      const remaining_day = CalculateLicense(
        context.state.company_license
      ).getRemainingDaysFromNow();

      switch (paymentModal.data.subscription_id) {
        // Platinium Confirm Alert
        case 1:
          return (
            <Alert severity="info" className="mt-2">
              <b>
                {context.t(`wallet.check.packageChangeAlert`, {
                  active_package:
                    context.state.company_license.accounting === 1
                      ? "Platinium"
                      : context.state.company_license.accounting === 2
                      ? "Gold"
                      : context.state.company_license.accounting === 3
                      ? "Diamond"
                      : "Silver",
                  new_package: selectData.name,
                  remaining_day,
                  formula: Math.round((remaining_day * 1.3) / 2.63),
                })}
              </b>
            </Alert>
          );
        // Gold Confirm Alert
        case 2:
          return (
            <Alert severity="info" className="mt-2">
              <b>
                {context.t(`wallet.check.packageChangeAlert`, {
                  active_package:
                    context.state.company_license.accounting === 1
                      ? "Platinium"
                      : context.state.company_license.accounting === 2
                      ? "Gold"
                      : context.state.company_license.accounting === 3
                      ? "Diamond"
                      : "Silver",
                  new_package: selectData.name,
                  remaining_day,
                  formula: Math.round((remaining_day * 2.63) / 1.3),
                })}
              </b>
            </Alert>
          );

        default:
          break;
      }
    }

    return false;
  };

  const balanceAdd = () => {
    Axios.post(`${context.api_endpoint}/company/balance/request`, {
      amount: balanceModal.data,
    })
      .then(({ data: { data } }) => {
        if (data.ResultCode === "Success") {
          setBalanceModal({
            ...balanceModal,
            active: false, // Olur da iframe açarsak diye true'ya çekilecek
            url: data.Data,
          });

          window.open(
            `${data.Data}`,
            "",
            "width=560,height=768,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes"
          );
        } else {
          toast.warning(context.t(`wallet.balanceAddWarnToast`));
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const buySingleItem = () => {
    if (paymentModal.data?.credit_card_amount)
      Axios.post(
        `${context.api_endpoint}/company/buy/${paymentModal.type}`,
        paymentModal.type === "license"
          ? {
              license_packet_id: paymentModal.data.id,
              accounting: paymentModal.data.subscription_id,
            }
          : paymentModal.type === "extralicense"
          ? { extra_license_packet_id: paymentModal.data?.id, months: 1 }
          : { id: paymentModal.data?.id }
      )
        .then(({ status, data: { data } }) => {
          status === 201 && toast.success(data);
          loadData();
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  const loadData = () => {
    getBalance();

    getLicensePackages();
    getSmsPackages();
    getWhatsAppPackages();

    if (context.state.company_license.appointment_limitation > 0) {
      getRemainigAppoinment();
    }
  };

  // SMS Kredisi Bilgisi
  const getBalance = () => {
    Axios.get(`${context.api_endpoint}/company/balance`).then(
      ({
        data: {
          data: { balance, license },
        },
      }) => {
        setBalance(balance);
        setLicense(license);

        context.dispatch({
          type: "SET_COMPANY_LICENSE",
          payload: { company_license: license },
        });
      }
    );
  };

  const getRemainigAppoinment = () => {
    Axios.get(`${context.api_endpoint}/company/remaining/appointment`).then(
      ({ data }) => {
        setRemainingAppointment(data.data.remaining_appointment);
      }
    );
  };

  // Lisans Paketleri
  const getLicensePackages = () => {
    Axios.get(`${context.api_endpoint}/company/show/license/packets`).then(
      ({ data: { data } }) => {
        setLicensePackages(
          [...data]?.sort((a, b) =>
            a.credit_card_amount > b.credit_card_amount ? 1 : -1
          )
        );
        setCheckAccounting(data.length === 7 ? 1 : 0);
      }
    );
  };

  // SMS Paketleri
  const getSmsPackages = () => {
    Axios.get(`${context.api_endpoint}/company/show/sms/packets`).then(
      ({ data: { data } }) => {
        setSmsPackages([...data]);
      }
    );
  };

  // WhatsApp Paketleri
  const getWhatsAppPackages = () => {
    Axios.get(`${context.api_endpoint}/company/show/wp/packets`).then(
      ({ data: { data } }) => {
        setWpPackages([...data]);
      }
    );
  };

  return (
    <AuthContainer limited_permission="wallet">
      <Grid>
        <Column className="xs-12 sm-12 md-12 xl-12">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <div
                className="sms_btn_hover"
                onClick={() => setRouter(1)}
                style={{
                  cursor: "pointer",
                  padding: "8px",
                  borderBottom: router === 1 ? "1px solid #3189ec" : "",
                  opacity: router === 1 ? "1" : "0.8",
                  backgroundColor: router === 1 ? "#F0F6FB" : "",
                  borderRadius: "5px",
                  fontSize: "16px",
                }}
              >
                {context.t(`wallet.licenses`)}
              </div>
              <div
                className="sms_btn_hover"
                onClick={() => setRouter(2)}
                style={{
                  cursor: "pointer",
                  padding: "8px",
                  marginLeft: "12px",
                  borderBottom: router === 2 ? "1px solid #3189ec" : "",
                  opacity: router === 2 ? "1" : "0.8",
                  backgroundColor: router === 2 ? "#F0F6FB" : "",
                  borderRadius: "5px",
                  fontSize: "16px",
                }}
              >
                {context.t(`wallet.message_packages`)}
              </div>
              <div
                className="sms_btn_hover"
                onClick={() => setRouter(3)}
                style={{
                  cursor: "pointer",
                  padding: "8px",
                  marginLeft: "12px",
                  borderBottom: router === 3 ? "1px solid #3189ec" : "",
                  opacity: router === 3 ? "1" : "0.8",
                  backgroundColor: router === 3 ? "#F0F6FB" : "",
                  borderRadius: "5px",
                  fontSize: "16px",
                }}
              >
                {context.t(`wallet.extra_license`)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "25px",
              }}
            >
              {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
                <div style={{ display: "flex", gap: "2px", marginTop: "5px" }}>
                  <AssignmentIcon style={{ marginTop: "3px" }} />
                  <p
                    style={{
                      opacity: "0.8",
                      fontSize: "16px",
                      marginTop: "3px",
                    }}
                  >
                    {context.t(`wallet.licenseDetail`)}
                  </p>
                  :
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      marginTop: "3px",
                    }}
                  >
                    {context.state.company_license.accounting === 2 &&
                    context.state.company_license.appointment_limitation > 0
                      ? context.t(`wallet.packages.silver`)
                      : PACKAGE_LIST[context.state.company_license.accounting]}
                  </p>
                </div>
              )) ||
                null}

              {context.current_flag & context.state.FLAGS.SALON_RANDEVU &&
                context.state.company_license.accounting === 2 &&
                context.state.company_license.appointment_limitation > 0 && (
                  <div
                    style={{ display: "flex", gap: "2px", marginTop: "5px" }}
                  >
                    <EventNoteIcon style={{ marginTop: "3px" }} />
                    <p
                      style={{
                        opacity: "0.8",
                        fontSize: "16px",
                        marginTop: "3px",
                      }}
                    >
                      {context.t(`wallet.appointmentRemaining`)}
                    </p>
                    <p style={{ fontSize: "16px", marginTop: "3px" }}>
                      {context.t(`wallet.count`).replace("%s", remainingAppointment ? remainingAppointment : "0")}
                    </p>
                  </div>
                )}
              <div style={{ display: "flex", gap: "2px", marginTop: "5px" }}>
                <AccountBalanceWalletIcon style={{ marginTop: "3px" }} />
                <p
                  style={{
                    opacity: "0.8",
                    fontSize: "16px",
                    marginTop: "3px",
                  }}
                >
                  {" "}
                  {context.t(`wallet.cardTitle`)}
                </p>{" "}
                :
                <p
                  style={{
                    fontSize: "bold",
                    fontSize: "17px",
                    marginTop: "3px",
                  }}
                >
                  {balance.toFixed(2)}
                  {process.env.APP_NAME === "management" ? "$" : "₺"}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="sms_btn_hover"
                  style={{
                    display: "flex",
                    minWidth: "150px",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px",
                    borderRadius: "50px",
                    color: "#04113B",
                    backgroundColor: "#98ADE8",
                    border: "none",
                    gap: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  <div>
                    {licensePackages[checkAccounting + 0]?.amount}
                    {process.env.APP_NAME === "management" ? "$" : "₺"}
                  </div>
                  <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {context.t(`wallet.load_balance`)}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Column>
        <Column className="xs-12 sm-12 md-12 xl-12">
          {router === 1 ? (
            <PackageSection style={{ marginTop: "40px" }}>
              <Container>
                <Gr container spacing={2}>
                  <Gr item xs={12} sm={6} md={3}>
                    <Paper elevation={3}>
                      <Typography
                        variant="h5"
                        style={{
                          height: "80px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        align="center"
                      >
                        {context.t(`wallet.packagesName.silver`)}
                      </Typography>
                      <List>
                        {context.t(`licenseDetails.silver`).map((m, index) => (
                          <ListItem key={index} dense>
                            <CheckIcon color="action" fontSize="small" />

                            <ListItemText
                              style={{
                                marginLeft: "6px",
                              }}
                              primary={m}
                              dense
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                    {license &&
                      license.accounting === 2 &&
                      license.appointment_limitation > 0 && (
                        <Paper style={{ marginTop: "10px" }}>
                          <div>
                            {licensePackages.length > 0 && (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "20px",
                                  height: "150px",
                                }}
                              >
                                <h3
                                  style={{ textAlign: "center" }}
                                >{`${licensePackages[0].name.replace(
                                  "Paket ",
                                  ""
                                )}`}</h3>

                                <Btn
                                  className="platinium-diamond-button"
                                  style={{
                                    backgroundColor: "white",
                                    borderRadius: "50px",
                                    width: "200px",
                                    padding: "8px 30px",
                                    color: "rgb(82, 150, 233)",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                                  }}
                                  onClick={() => {
                                    setTimer(10);
                                    setPaymentModal({
                                      active: true,
                                      type: "license",
                                      data: licensePackages[0],
                                    });
                                  }}
                                >
                                  <ShoppingCart
                                    style={{ marginRight: "5px" }}
                                    color="white"
                                  />{" "}
                                  {context.t(`wallet.buy`)}
                                </Btn>
                              </div>
                            )}
                          </div>
                        </Paper>
                      )}
                  </Gr>
                  <Gr item xs={12} sm={6} md={3} style={{ width: "100%" }}>
                    <Paper style={{ backgroundColor: "#ffbf00" }} elevation={3}>
                      <Typography
                        variant="h5"
                        style={{
                          height: "80px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                        align="center"
                      >
                        <div>
                          <div>{context.t(`wallet.packagesName.gold`)}</div>
                          {context.state.company_license.accounting === 2 && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "start",
                              }}
                            >
                              <p
                                style={{
                                  color: "#2F4F4F",
                                  position: "absolute",
                                  top: "54px",
                                }}
                              >
                                {context.t("licenseDetails.currentPackage")}
                              </p>
                            </div>
                          )}
                        </div>
                      </Typography>

                      <Typography
                        style={{
                          marginTop: "10px",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "80%",
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                          }}
                        >
                          <Btn
                            style={{
                              width: "50%",
                              height: "auto",
                              border: "none",

                              backgroundColor:
                                selectData.id == 4 ? " white" : null,
                              opacity: selectData.id == 4 ? "1" : "0.5",
                            }}
                            value={4}
                            onClick={() => {
                              setSelectData(
                                licensePackages[checkAccounting + 0]
                              );
                              setDefaultColorGold(false);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                {context.t(`wallet.monthly_payment`)}
                              </p>
                              <h2
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  color: "#3189ec",
                                }}
                              >
                                {licensePackages[checkAccounting + 0]?.amount}
                                {process.env.APP_NAME === "management"
                                  ? "$"
                                  : "₺"}
                              </h2>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                / {context.t(`wallet.monthly`)}
                              </p>
                            </div>
                          </Btn>
                          <Btn
                            style={{
                              width: "50%",
                              height: "auto",
                              backgroundColor:
                                selectData.id == 3 || defaultColorGold
                                  ? " white"
                                  : selectData.id == 4
                                  ? null
                                  : " white",
                              opacity:
                                selectData.id == 3 || defaultColorGold
                                  ? "1"
                                  : selectData.id == 4
                                  ? "0.5"
                                  : " 1",
                            }}
                            value={3}
                            onClick={() => {
                              setSelectData(
                                licensePackages[checkAccounting + 3]
                              );
                              setDefaultColorGold(false);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                {context.t(`wallet.yearly_payment`)}
                              </p>
                              <h2
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  color: "#3189ec",
                                }}
                              >
                                {(
                                  licensePackages[3 + checkAccounting]?.amount /
                                  12
                                ).toFixed(
                                  process.env.APP_NAME === "management" ? 2 : 0
                                )}
                                {process.env.APP_NAME === "management"
                                  ? "$"
                                  : "₺"}
                              </h2>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                / {context.t(`wallet.monthly`)}
                              </p>
                            </div>
                          </Btn>
                        </div>
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "32px",
                        }}
                      >
                        <Btn
                          className="platinium-diamond-button"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "50px",
                            width: "200px",
                            padding: "8px 30px",
                            color: "#ffbf00",
                            fontSize: "12px",
                            fontWeight: "bold",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                          }}
                          onClick={() => {
                            setTimer(10);
                            setPaymentModal({
                              active: true,
                              type: "license",
                              data:
                                selectData ===
                                  licensePackages[checkAccounting + 3] ||
                                selectData ===
                                  licensePackages[checkAccounting + 0]
                                  ? selectData
                                  : licensePackages[checkAccounting + 3],
                            });
                            setSelectData(
                              selectData ===
                                licensePackages[checkAccounting + 3] ||
                                selectData ===
                                  licensePackages[checkAccounting + 0]
                                ? selectData
                                : licensePackages[checkAccounting + 3]
                            );
                          }}
                        >
                          <ShoppingCart
                            style={{ marginRight: "5px" }}
                            color="white"
                          />{" "}
                          {context.t(`wallet.buy`)}
                        </Btn>
                      </Typography>

                      <List style={{ marginTop: "30px", height: "269px" }}>
                        {context.t(`licenseDetails.gold`).map((m, index) => (
                          <ListItem key={index} dense>
                            <CheckIcon color="action" fontSize="small" />

                            <ListItemText
                              primary={
                                <span
                                  style={{
                                    marginLeft: "6px",
                                  }}
                                >
                                  {m}
                                </span>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Gr>
                  <Gr item xs={12} sm={6} md={3}>
                    <Paper style={{ backgroundColor: "#E6F2FD" }} elevation={3}>
                      <Typography
                        variant="h5"
                        style={{
                          height: "80px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                        align="center"
                      >
                        <div>
                          <div>{context.t(`wallet.packagesName.platinium`)}</div>
                          {context.state.company_license.accounting === 1 && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "start",
                              }}
                            >
                              <p
                                style={{
                                  color: "#2F4F4F",
                                  position: "absolute",
                                  top: "54px",
                                }}
                              >
                                {context.t("licenseDetails.currentPackage")}
                              </p>
                            </div>
                          )}
                        </div>
                      </Typography>
                      <Typography
                        style={{
                          marginTop: "10px",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "80%",
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                          }}
                        >
                          <Btn
                            style={{
                              width: "50%",
                              height: "auto",
                              border: "none",

                              backgroundColor:
                                selectData.id == 2 ? " #F8F7F7" : null,
                              opacity: selectData.id == 2 ? "1" : "0.4",
                            }}
                            value={2}
                            onClick={() => {
                              setSelectData(
                                licensePackages[checkAccounting + 1]
                              );
                              setDefaultColorPlatinium(false);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                {context.t(`wallet.monthly_payment`)}
                              </p>
                              <h2
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  color: "#3189ec",
                                }}
                              >
                                {licensePackages[checkAccounting + 1]?.amount}
                                {process.env.APP_NAME === "management"
                                  ? "$"
                                  : "₺"}
                              </h2>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                / {context.t(`wallet.monthly`)}
                              </p>
                            </div>
                          </Btn>
                          <Btn
                            style={{
                              width: "50%",
                              height: "auto",
                              backgroundColor:
                                selectData.id == 1 || defaultColorPlatinium
                                  ? " #F8F7F7"
                                  : selectData.id == 2
                                  ? null
                                  : " #F8F7F7",
                              opacity:
                                selectData.id == 1 || defaultColorPlatinium
                                  ? "1"
                                  : selectData.id == 2
                                  ? "0.4"
                                  : "1",
                              border: "none",
                            }}
                            value={1}
                            onClick={() => {
                              setSelectData(
                                licensePackages[checkAccounting + 4]
                              );
                              setDefaultColorPlatinium(false);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                {context.t(`wallet.yearly_payment`)}
                              </p>
                              <h2
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  color: "#3189ec",
                                }}
                              >
                                {(
                                  licensePackages[4 + checkAccounting]?.amount /
                                  12
                                ).toFixed(
                                  process.env.APP_NAME === "management" ? 2 : 0
                                )}
                                {process.env.APP_NAME === "management"
                                  ? "$"
                                  : "₺"}
                              </h2>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                / {context.t(`wallet.monthly`)}
                              </p>
                            </div>
                          </Btn>
                        </div>
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "32px",
                        }}
                      >
                        <Btn
                          className="platinium-diamond-button"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "50px",
                            width: "200px",
                            padding: "8px 30px",
                            color: "#5296e9",
                            fontSize: "12px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                          }}
                          onClick={() => {
                            setTimer(10);
                            setPaymentModal({
                              active: true,
                              type: "license",
                              data:
                                selectData ===
                                  licensePackages[checkAccounting + 4] ||
                                selectData ===
                                  licensePackages[checkAccounting + 1]
                                  ? selectData
                                  : licensePackages[checkAccounting + 4],
                            });
                            setSelectData(
                              selectData ===
                                licensePackages[checkAccounting + 4] ||
                                selectData ===
                                  licensePackages[checkAccounting + 1]
                                ? selectData
                                : licensePackages[checkAccounting + 4]
                            );
                          }}
                        >
                          <ShoppingCart
                            style={{ marginRight: "5px" }}
                            color="white"
                          />{" "}
                          {context.t(`wallet.buy`)}
                        </Btn>
                      </Typography>
                      <div>
                        <div
                          className={`list-container ${
                            isDropdownPlatiniumOpen ? "open" : ""
                          }`}
                        >
                          <List style={{ marginTop: "30px" }}>
                            {context
                              .t(`licenseDetails.platinum`)
                              .map((m, index) => (
                                <ListItem key={index} dense>
                                  <CheckIcon color="action" fontSize="small" />

                                  <ListItemText
                                    primary={
                                      <span
                                        style={{
                                          marginLeft: "6px",
                                        }}
                                      >
                                        {m}
                                      </span>
                                    }
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {isDropdownPlatiniumOpen ? (
                            <button
                              className="sms_btn_hover"
                              style={{
                                border: "none",
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#E6F2FD",
                              }}
                              onClick={toggleDropdownPlatinium}
                            >
                              <div></div>
                              <p style={{ fontSize: "13px" }}>
                                {context.t(`wallet.see_less`)}
                              </p>
                              <div>
                                <ExpandLessIcon />
                              </div>
                            </button>
                          ) : (
                            <button
                              className="sms_btn_hover"
                              style={{
                                border: "none",
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#E6F2FD",
                              }}
                              onClick={toggleDropdownPlatinium}
                            >
                              <p style={{ fontSize: "13px" }}>
                                {" "}
                                {context.t(`wallet.see_more`)}
                              </p>
                              <div>
                                {" "}
                                <ExpandMoreIcon />
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </Paper>
                  </Gr>
                  <Gr item xs={12} sm={6} md={3}>
                    <Paper style={{ backgroundColor: "#b8d8e7" }} elevation={3}>
                      <Typography
                        variant="h5"
                        style={{
                          height: "80px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                        align="center"
                      >
                        <div>
                          <div>{context.t(`wallet.packagesName.diamond`)}</div>
                          {context.state.company_license.accounting === 3 && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "start",
                              }}
                            >
                              <p
                                style={{
                                  color: "#2F4F4F",
                                  position: "absolute",
                                  top: "54px",
                                }}
                              >
                                {context.t("licenseDetails.currentPackage")}
                              </p>
                            </div>
                          )}
                        </div>
                      </Typography>

                      <Typography
                        style={{
                          marginTop: "10px",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "80%",
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                          }}
                        >
                          <Btn
                            style={{
                              width: "50%",
                              height: "auto",
                              border: "none",

                              backgroundColor:
                                selectData.id == 8 ? "white" : null,
                              opacity: selectData.id == 8 ? "1" : "0.5",
                            }}
                            value={2}
                            onClick={() => {
                              setSelectData(
                                licensePackages[checkAccounting + 2]
                              );
                              setDefaultColorDiamond(false);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                {context.t(`wallet.monthly_payment`)}
                              </p>
                              <h2
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  color: "#3189ec",
                                }}
                              >
                                {licensePackages[checkAccounting + 2]?.amount}
                                {process.env.APP_NAME === "management"
                                  ? "$"
                                  : "₺"}
                              </h2>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                / {context.t(`wallet.monthly`)}
                              </p>
                            </div>
                          </Btn>
                          <Btn
                            style={{
                              width: "50%",
                              height: "auto",
                              backgroundColor:
                                selectData.id == 7 || defaultColorDiamond
                                  ? "white"
                                  : selectData.id == 8
                                  ? null
                                  : " white",
                              opacity:
                                selectData.id == 7 || defaultColorDiamond
                                  ? "1"
                                  : selectData.id == 8
                                  ? "0.5"
                                  : "1",
                              border: "none",
                            }}
                            value={1}
                            onClick={() => {
                              setSelectData(
                                licensePackages[checkAccounting + 5]
                              );
                              setDefaultColorDiamond(false);
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                {context.t(`wallet.yearly_payment`)}
                              </p>
                              <h2
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  color: "#3189ec",
                                }}
                              >
                                {(
                                  licensePackages[5 + checkAccounting]?.amount /
                                  12
                                ).toFixed(
                                  process.env.APP_NAME === "management" ? 2 : 0
                                )}
                                {process.env.APP_NAME === "management"
                                  ? "$"
                                  : "₺"}
                              </h2>
                              <p
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  fontSize: "11px",
                                }}
                              >
                                / {context.t(`wallet.monthly`)}
                              </p>
                            </div>
                          </Btn>
                        </div>
                      </Typography>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "32px",
                        }}
                      >
                        <Btn
                          className="platinium-diamond-button"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "50px",
                            width: "200px",
                            padding: "8px 30px",
                            color: "#5296e9",
                            fontSize: "12px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                          }}
                          onClick={() => {
                            setTimer(10);
                            setPaymentModal({
                              active: true,
                              type: "license",
                              data:
                                selectData ===
                                  licensePackages[checkAccounting + 5] ||
                                selectData ===
                                  licensePackages[checkAccounting + 2]
                                  ? selectData
                                  : licensePackages[checkAccounting + 5],
                            });
                            setSelectData(
                              selectData ===
                                licensePackages[checkAccounting + 5] ||
                                selectData ===
                                  licensePackages[checkAccounting + 2]
                                ? selectData
                                : licensePackages[checkAccounting + 5]
                            );
                          }}
                        >
                          <ShoppingCart
                            style={{ marginRight: "5px" }}
                            color="white"
                          />{" "}
                          {context.t(`wallet.buy`)}
                        </Btn>
                      </Typography>
                      <div>
                        <div
                          className={`list-container ${
                            isDropdownDiamondOpen ? "open" : ""
                          }`}
                        >
                          <List style={{ marginTop: "30px" }}>
                            {context
                              .t(`licenseDetails.diamond`)
                              .map((m, index) => (
                                <ListItem key={index} dense>
                                  <CheckIcon color="action" fontSize="small" />

                                  <ListItemText
                                    primary={
                                      <span
                                        style={{
                                          marginLeft: "6px",
                                        }}
                                      >
                                        {m}
                                      </span>
                                    }
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {isDropdownDiamondOpen ? (
                            <button
                              className="sms_btn_hover"
                              style={{
                                border: "none",
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#b8d8e7",
                              }}
                              onClick={toggleDropdownDiamond}
                            >
                              <div></div>
                              <p style={{ fontSize: "13px" }}>
                                {context.t(`wallet.see_less`)}
                              </p>
                              <div>
                                <ExpandLessIcon />
                              </div>
                            </button>
                          ) : (
                            <button
                              className="sms_btn_hover"
                              style={{
                                border: "none",
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#b8d8e7",
                              }}
                              onClick={toggleDropdownDiamond}
                            >
                              <p style={{ fontSize: "13px" }}>
                                {" "}
                                {context.t(`wallet.see_more`)}
                              </p>
                              <div>
                                {" "}
                                <ExpandMoreIcon />
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </Paper>
                  </Gr>
                </Gr>
              </Container>
              <Column className="xs-12 sm-12 md-12 xl-12">
                <Container
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "3px",
                    marginTop: "10px",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>
                    {" "}
                    {context.t(`wallet.package_prices`)}{" "}
                  </p>

                  <p>
                    <u>{context.t(`wallet.kdv`)}.</u>
                  </p>
                </Container>
              </Column>
              <Column className="xs-12 sm-12 md-12 xl-12">
                <Container style={{ width: "80%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "30px",
                      marginBottom: "50px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {context.t("wallet.allLicenceFeatures")}
                  </div>
                  <WalletTable />
                </Container>
              </Column>
            </PackageSection>
          ) : router === 2 ? (
            <Container>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "40px",
                }}
              >
                {process.env.APP_NAME !== "management" ?? (
                  <div
                    style={{
                      flex: "1",
                      padding: "20px",
                      borderRadius: "10px",
                      border: "1px solid #b8d8e7",
                      height: "400px",
                      width: "100%",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3 style={{ fontSize: "23px" }}>
                        {" "}
                        {context.t(`wallet.sms_package`)}
                      </h3>
                      <MailOutlineIcon
                        style={{
                          fontSize: 25,
                          marginRight: "10px",
                        }}
                      />
                    </div>
                    <PackageSection style={{ marginTop: "20px" }}>
                      {smsPackages?.map((_package) => (
                        <div
                          className="sms_btn_hover"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "320px",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: "17px",
                              fontSize: "16px",
                              color: "#434746",
                            }}
                          >{`${_package.quantity} SMS`}</div>
                          <div style={{ marginBottom: "17px" }}>
                            <button
                              className="sms_btn_hover"
                              style={{
                                width: "110px",
                                padding: "4px",
                                borderRadius: "50px",
                                backgroundColor: "#6d8ce3",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setTimer(0);
                                setPaymentModal({
                                  active: true,
                                  type: "sms",
                                  data: _package,
                                });
                              }}
                            >
                              <div
                                style={{ fontWeight: "bold", padding: "5px" }}
                              >
                                {_package.amount}
                                {process.env.APP_NAME === "management"
                                  ? "$"
                                  : "₺"}
                              </div>
                            </button>
                          </div>
                        </div>
                      ))}
                    </PackageSection>
                  </div>
                )}
                <div style={{ flex: "1" }}></div>
                <div
                  style={{
                    flex: "1",
                    width: "100%",
                    borderRadius: "10px",
                    padding: "20px",
                    border: "1px solid #b8d8e7",
                    height: "400px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3 style={{ fontSize: "23px" }}>
                      {" "}
                      {context.t(`wallet.wp_package`)}
                    </h3>
                    <WhatsAppIcon
                      style={{
                        fontSize: 25,
                        marginRight: "10px",
                      }}
                    />
                  </div>
                  <PackageSection style={{ marginTop: "20px" }}>
                    {wpPackages?.map((_package) => (
                      <div
                        className="sms_btn_hover"
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "332px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "17px",
                            fontSize: "16px",
                            color: "#434746",
                          }}
                        >{`${_package.quantity} WhatsApp`}</div>
                        <div style={{ marginBottom: "17px" }}>
                          <button
                            className="sms_btn_hover"
                            style={{
                              width: "110px",
                              padding: "4px",
                              borderRadius: "50px",
                              backgroundColor: "#00d38b",
                              color: "white",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setTimer(0);
                              setPaymentModal({
                                active: true,
                                type: "wp",
                                data: _package,
                              });
                            }}
                          >
                            <div style={{ fontWeight: "bold", padding: "5px" }}>
                              {_package.amount}
                              {process.env.APP_NAME === "management"
                                ? "$"
                                : "₺"}
                            </div>
                          </button>
                        </div>
                      </div>
                    ))}
                  </PackageSection>
                </div>
                <div style={{ flex: "1" }}></div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  gap: "3px",
                  marginTop: "10px",
                }}
              >
                <p style={{ fontWeight: "bold", marginTop: "25px" }}>
                  {context.t(`wallet.sms_price`)}{" "}
                </p>

                <p style={{ marginTop: "25px" }}>
                  <u>{context.t(`wallet.kdv`)}</u>
                </p>
              </div>
            </Container>
          ) : (
            <Container>
              {extraLicense.map((_package, index) => (
                <div
                  style={{
                    marginTop: "40px",
                    maxWidth: "400px",
                    padding: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #DCDCDC",
                    borderRadius: "20px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    {_package.name === "Whatsapp" && (
                      <WhatsAppIcon
                        style={{
                          fontSize: "23px",
                          color: "#25D366",
                          marginTop: "1px",
                        }}
                      />
                    )}
                    {_package.name === "Barkod" && (
                      <AiOutlineBarcode
                        style={{
                          fontSize: "23px",
                          marginTop: "1px",
                          color: "rgb(49, 137, 236)",
                        }}
                      />
                    )}
                    {_package.name === "OnlineLink" && (
                      <FaExternalLinkAlt
                        style={{
                          fontSize: "20px",
                          color: "#2F13BA",
                        }}
                      />
                    )}
                    <p>
                      {_package.name === "Whatsapp"
                        ? context.t(`wallet.whatsapp_extra_package`)
                        : _package.name === "OnlineLink"
                        ? context.t(`wallet.online_link_extra_package`)
                        : context.t(`wallet.bardcode_extra_package`)}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      key={index}
                      title={
                        _package.name === "OnlineLink" ? (
                          <h3>
                            {context.t(
                              `wallet.online_link_extra_package_tooltip`
                            )}
                          </h3>
                        ) : _package.name === "Barkod" ? (
                          <h3>
                            {context.t(`wallet.bardcode_extra_package_tooltip`)}
                          </h3>
                        ) : (
                          <h3>
                            {context.t(`wallet.whatsapp_extra_package_tooltip`)}
                          </h3>
                        )
                      }
                      arrow
                      enterTouchDelay={0}
                      leaveTouchDelay={4000}
                      placement="top-end"
                    >
                      <Typography
                        onMouseEnter={() => setTooltipVisible(true)}
                        onMouseLeave={() => setTooltipVisible(false)}
                      >
                        <GrStatusInfo style={{ cursor: "pointer" }} />
                      </Typography>
                    </Tooltip>

                    <buuton
                      className="sms_btn_hover"
                      style={{
                        padding: "8px",
                        borderRadius: "20px",
                        backgroundColor:
                          _package.name === "Whatsapp"
                            ? "#25D366"
                            : _package.name === "Barkod"
                            ? "rgb(49, 137, 236)"
                            : "#2F13BA",
                        width: "70px",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setTimer(0);
                        setPaymentModal({
                          active: true,
                          type: "extralicense",
                          data: _package,
                        });
                      }}
                    >
                      {_package.credit_card_amount}
                      {process.env.APP_NAME === "management" ? "$" : "₺"}
                    </buuton>
                  </div>
                </div>
              ))}
            </Container>
          )}
        </Column>
        <AlertDialog
          title={context.t(`wallet.check.title`)}
          open={paymentModal.active}
          textType
          fullWidth
          disableBackdropClick={false}
          closeHandler={() =>
            setPaymentModal({ ...paymentModal, active: false })
          }
          buttons={[
            {
              icon: "check",
              title: context.t(`wallet.check.confirmButtonTitle`),
              backgroundColor: "primary",
              textColor: "white",
              transitionEffect: true,
              timer: timer,
              onClick: () => buySingleItem(),
            },
            {
              icon: "close",
              title: context.t(`wallet.check.discardButtonTitle`),
              textColor: "grey",
            },
          ]}
        >
          {context.t(`wallet.check.alertText`, {
            credit_card_amount: paymentModal.data?.credit_card_amount,
            package_name:
              paymentModal.type === "sms"
                ? `${paymentModal.data.quantity} SMS`
                : paymentModal.type === "wp"
                ? `${paymentModal.data.quantity} WhatsApp`
                : paymentModal.data.name,
          })}
          {packageChangeAlertBuilder()}
        </AlertDialog>
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setModalOpen(false)}
            >
              <CloseIcon />
            </div>
            <Hero TLContainer>
              <HeroInner>
                <span className="caption">{context.t(`wallet.cardTitle`)}</span>
                <span className="wallet-value">
                  {balance.toFixed(2)}
                  {process.env.APP_NAME === "management" ? "$" : "₺"}
                </span>

                <div className="amount-input mb-2">
                  <MaskedInput
                    className="mb-0"
                    label={context.t(`wallet.amountInputLabel`)}
                    maskChar=""
                    mask="999999"
                    autoFocus
                    value={balanceModal.data}
                    onChange={(e) =>
                      setBalanceModal({
                        ...balanceModal,
                        data: e.target.value ? parseInt(e.target.value) : 0,
                      })
                    }
                  />
                </div>

                <Button
                  iconComponent={Send}
                  title={context.t(`wallet.amountButtonTitle`)}
                  textColor="blue"
                  backgroundColor="white"
                  fullWidth
                  onClick={() => {
                    if (balanceModal.data === null || balanceModal.data === 0) {
                      return toast.error(context.t("wallet.balanceErrorToast"));
                    }
                    setOpenPaymentTypeDialog(true);
                  }}
                />
              </HeroInner>
            </Hero>
            <div style={{ width: "80%" }}>
              <Link to="/payment-notify">
                <button
                  className="sms_btn_hover"
                  style={{
                    width: "300px",
                    padding: "8px",
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "10px",
                    border: "none",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                >
                  <NotificationsIcon /> {context.t(`wallet.paymentForm`)}
                </button>
              </Link>
            </div>
          </div>
        </Dialog>
        <AlertDialog
          open={balanceModal.active}
          fullWidth
          maxWidth="md"
          disableBackdropClick={false}
          scroll="body"
          containerStyle={{ padding: 0 }}
          closeHandler={() =>
            setBalanceModal({ ...balanceModal, active: false })
          }
        >
          <div style={{ display: "flex", overflow: "hidden", width: "100%" }}>
            <BalanceIFrame src={balanceModal.url} />
          </div>
        </AlertDialog>
        <AlertDialog
          title={context.t(`wallet.paymentType.title`)}
          open={openPaymentTypeDialog}
          disableBackdropClick={false}
          scroll="body"
          containerStyle={{ padding: 0 }}
          closeHandler={() => setOpenPaymentTypeDialog(false)}
        >
          <Grid className="mt-1">
            <Column className="xs-12 sm-12 md-12 mb-2">
              <Button
                iconComponent={CreditCard}
                title={context.t(`wallet.paymentType.creditCard`)}
                textColor="primary"
                backgroundColor="primary-opacity"
                fullWidth
                onClick={() => {
                  setOpenPaymentTypeDialog(false);
                  balanceAdd();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12">
              <Button
                iconComponent={AccountBalance}
                title={context.t(`wallet.paymentType.eft`)}
                textColor="secondary"
                backgroundColor="secondary-opacity"
                fullWidth
                onClick={() => history.push("/payment-notify")}
              />
            </Column>
          </Grid>
        </AlertDialog>
      </Grid>
    </AuthContainer>
  );
};

export default WalletIndex;

const Hero = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px;

  box-sizing: border-box;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);

  ${(props) =>
    props.TLContainer &&
    `
  background: rgb(77,111,149);
  background: linear-gradient(40deg, rgba(77,111,149,1) 0%, rgba(131,189,222,1) 100%);
  color: #FFF;
  `}

  ${(props) =>
    props.SMSContainer &&
    `
    box-shadow: none;
    background: #FAFAFA;
    color: #505050;
  `}
`;

const HeroInner = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  span.caption {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
  }

  span.wallet-value {
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 40px;
    letter-spacing: -0.25px;
    margin-bottom: 16px;
  }

  div.amount-input {
    width: 100%;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(20px);

    * {
      color: #fff;
    }
  }
`;

const PackageSection = styled.div`
  margin-bottom: 56px;

  h1 {
    font-size: 20px;
  }
`;

const BalanceIFrame = styled.iframe`
  flex: 1;
  border: none;
  height: -webkit-fill-available;
`;