import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/store";
import { Column, Grid } from "../../theme/Grid";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function WpUndefinedAlert() {
  const context = useContext(AppContext);
  return (
    <Grid>
      <Column className="xs-12 sm-12 mb-2">
        <Alert severity="error">
          <b>{context.t(`sms.undefinedAlerts.wp_heading`)}</b>
          <br />
          {context.t(`sms.undefinedAlerts.wp_content`)}
          <Link to="/settings/wp">
            <span style={{ color: "black", fontWeight: "bold" }}>
              {" "}
              {context.t(`sms.undefinedAlerts.wp_span`)}
            </span>
          </Link>
        </Alert>
      </Column>
    </Grid>
  );
}

export default WpUndefinedAlert;
