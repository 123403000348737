import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import Select from "../../../theme/CustomMUI/Select";
import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";

import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import DisabledAppointmentsGenerator from "../../../functions/DisabledAppointmentsGenerator";

import CustomMUIDatePicker from "../../../theme/CustomMUI/DatePicker";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import moment from "moment";
import Axios from "axios";
import getDayIndex from "../../../functions/GetDayIndex";
import { toast } from "react-toastify";
import Input from "../../../theme/CustomMUI/Input";
import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddAppointment = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  // Input States
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [services, setServices] = useState([]);

  // Selected States
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);

  const [note, setNote] = useState(null);
  const [sendSms, setSendSms] = useState(null);

  //Appointment States
  const [unavailableData, setUnavailableData] = useState({
    single: [], // ["11:00", "11:20"],
    between: [], // [{ start_hour: "10:00", end_hour: "10:20" }, { start_hour: "15:30", end_hour: "16:20" },],
    day_closed: false,
  });
  const [unavailableHours, setUnavailableHours] = useState([]);
  const [availableAppointmentExpanded, setAvailableAppointmentExpanded] = useState(false);

  const [customerAddModal, setCustomerAddModal] = useState(false);

  const [generatedHours, setGeneratedHours] = useState(
    BetweenTimeGenerator({
      ...context.state.company_shift,
    })
  );

  const getRequiredArraysForStaffInsert = () => {
    Axios.get(`${context.api_endpoint}/company/itself`)
      .then(({ data }) => {
        setGeneratedHours([
          ...BetweenTimeGenerator({
            ...data.data.hours,
          }),
        ]);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getRequiredAppointmentArrays = () => {
    Axios.get(`${context.api_endpoint}/company/appointments/index2`)
      .then(({ data }) => {
        // setServices([...data.data.services]);
        setEmployees([...data.data]);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key },
    })
      .then(({ data }) => (arr = [...data.data.records]))
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr;
  };

  const getStaffUnavailableHours = () => {
    Axios.get(
      `${context.api_endpoint}/company/appointments/staff/available`,
      {
        params: {
          staff_id: parseInt(selectedEmployee.id),
          date: moment(selectedDate).format("YYYY-MM-DD"),
          day_index: getDayIndex(
            moment(selectedDate).format("YYYY-MM-DD")
          ),
        },
      }
    )
      .then(({ data }) => {
        setUnavailableData({
          ...data.data,
          single: data.data.single !== null ? data.data.single : [],
          between:data.data.between ?? [],
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .then(() => {
        setAvailableAppointmentExpanded(true);
        setUnavailableHours(...DisabledAppointmentsGenerator(unavailableData))
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const insertAppointment = () => {
    let appointment_date = new Date(selectedDate);
    let appointment_time = selectedTime.split(":");
    appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${
      appointment_time[0]
    }:${appointment_time[1]}:00Z`;

    Axios.post(`${context.api_endpoint}/company/appointment/insert`, {
      staff_id: selectedEmployee.id,
      customer_id: selectedCustomer.id,
      service_id: selectedService.id,
      send_sms: selectedCustomer
        ? selectedCustomer.send_sms === false
          ? false
          : sendSms
        : sendSms,
      note,
      appointment_date,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['appointments/add'].insertToast`));
          history.push("/appointments");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getRequiredAppointmentArrays();
    getRequiredArraysForStaffInsert();
  }, []);

  const helperText = (sttr, len = 0) => {
    return sttr.replace("%s", len);
  };

    return (
      <AuthContainer limited_permission="appointment">
        <Grid>
          <Column className="xs-12" style={{ width: "342px" }}>
            <CustomMUIDatePicker
              autoOk
              openTo="date"
              variant="static"
              disableToolbar={true}
              showTodayButton={true}
              minDate={new Date()}
              value={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                setAvailableAppointmentExpanded(false);
                setSelectedTime(null);
              }}
            />
          </Column>

          <Column className="col-auto">
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-auto">
                <Select
                  label={context.t(`['appointments/add'].staffInputLabel`)}
                  items={employees}
                  labelKey="full_name"
                  valueKey="id"
                  selected={
                    selectedEmployee !== null
                      ? `${selectedEmployee.id}`
                      : ""
                  }
                  returnFullObject
                  handler={(selected_employee) => {
                    setServices([
                      ...selectedEmployee.services.map((service) => {
                        return service.service !== null ? { ...service.service } : undefined;
                      }),
                    ].filter((item) => item !== undefined));
                    setSelectedEmployee(selected_employee);
                    setAvailableAppointmentExpanded(false);
                    setSelectedTime(null);
                  }}
                />
              </Column>

              <Column className="xs-12 sm-12 md-auto">
                <Select
                  label={context.t(
                    `['appointments/add'].serviceInputLabel`
                  )}
                  items={services}
                  labelKey="name"
                  valueKey="id"
                  selected={
                    selectedService !== null
                      ? `${selectedService.id}`
                      : ""
                  }
                  returnFullObject
                  handler={(selected_service) => {
                    setSelectedService(selected_service);
                    setAvailableAppointmentExpanded(false);
                    setSelectedTime(null);
                  }}
                />
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              <Column className="xs-8 sm-10 md-6">
                <AutocompleteInput
                  label={context.t(
                    `['appointments/add'].customerInputLabel`
                  )}
                  placeholder={context.t(
                    `['appointments/add'].customerInputPlaceholder`
                  )}
                  labelKey="full_name"
                  valueKey="id"
                  selected={
                    selectedCustomer !== null
                      ? selectedCustomer
                      : ""
                  }
                  returnFullObject
                  selectedHandler={(selected_customer) => {
                    setSelectedCustomer(selected_customer);
                    setSendSms(selected_customer.send_sms);
                  }}
                  asyncDataService={async (keyword) =>
                    searchCustomerByKey(keyword)
                  }
                />
              </Column>
              <Column className="xs-4 sm-2 md-6">
                <Button
                  icon="add"
                  title={context.t(
                    `['appointments/add'].createNewCustomerButtonTitle`
                  )}
                  backgroundColor="primary-opacity"
                  textColor="primary"
                  fullWidth={true}
                  size="lg"
                  onClick={() => {
                    setCustomerAddModal(true);
                  }}
                />
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-6">
                <CSwitch
                  label={context.t(
                    `['appointments/add'].sendSmsInputLabel`
                  )}
                  checked={sendSms}
                  color="primary"
                  disabled={
                    selectedCustomer &&
                    selectedCustomer.send_sms === false
                  }
                  onChange={(checked) => {
                    setSendSms(checked.target.checked);
                  }}
                />
                {/* <Select
                  disabled={
                    selectedCustomer &&
                    selectedCustomer.send_sms === false
                  }
                  label={context.t(
                    `['appointments/add'].sendSmsInputLabel`
                  )}
                  items={[
                    {
                      label: context.t(`['appointments/add'].sendSmsTrue`),
                      value: true,
                    },
                    {
                      label: context.t(
                        `['appointments/add'].sendSmsFalse`
                      ),
                      value: false,
                    },
                  ]}
                  selected={`${send_sms}`}
                  labelKey="label"
                  valueKey="value"
                  handler={(send_sms) => setSendSms(send_sms === "true")}
                /> */}
              </Column>

              <Column className="xs-12 sm-12 md-6">
                <Input
                  label={context.t(`['appointments/add'].noteInputLabel`)}
                  multiline
                  rows={1}
                  helperText={context.t(
                    `['appointments/add'].noteInputHint`
                  )}
                  onChange={(e) => setNote(e.target.value.length <= 255 ? e.target.value : null)}
                />
              </Column>
            </Grid>

            <Button
              icon="event"
              title={context.t(
                `['appointments/add'].availableHoursButtonTitle`
              )}
              disabled={
                !(
                  selectedCustomer !== null &&
                  selectedEmployee !== null &&
                  selectedService !== null &&
                  selectedDate !== null
                )
              }
              onClick={() => {
                getStaffUnavailableHours();
              }}
            />

            {availableAppointmentExpanded === true && (
              <>
                <Grid className="mt-4 no-gutters-all">
                  {generatedHours.map(
                    (item, index) => {
                      const time = item.split(":");
                      const x = new Date(selectedDate).setHours(
                        time[0],
                        time[1],
                        0,
                        0
                      );
                      return (
                        <div
                          key={index}
                          className="col xs-6 sm-6 md-6 lg-4 xl-2 mb-2 p-horizontal-1"
                        >
                          <Button
                            iconSize={20}
                            icon={
                              unavailableHours.includes(item) ||
                              x < new Date()
                                ? "close"
                                : "alarm_add"
                            }
                            title={item}
                            fullWidth={true}
                            textColor={
                              unavailableHours.includes(item) ||
                              x < new Date()
                                ? "red"
                                : "green"
                            }
                            disabled={
                              unavailableHours.includes(item) ||
                              x < new Date()
                            }
                            outlined={selectedTime === item}
                            onClick={() => setSelectedTime(item)}
                          />
                        </div>
                      );
                    }
                  )}
                </Grid>

                <Button
                  className="mt-2"
                  icon="check"
                  title={context.t(
                    `['appointments/add'].confirmButtonTitle`
                  )}
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth={true}
                  disabled={
                    !(
                      selectedCustomer !== null &&
                      selectedEmployee !== null &&
                      selectedService !== null &&
                      selectedDate !== null &&
                      selectedTime !== null
                    )
                  }
                  onClick={() => insertAppointment()}
                />
              </>
            )}
          </Column>
          <CustomerAddDialog
            open={customerAddModal}
            createdCustomerHandler={async (data) => {
              await setSelectedCustomer({...data, full_name: `${data.name} ${data.surname}`});
            }}
            closeHandler={() => setCustomerAddModal(false)}
          />
        </Grid>
      </AuthContainer>
    );
}

export default AddAppointment;
